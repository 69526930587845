import React from "react";
import { RiListSettingsLine } from "react-icons/ri";
import { TbCurrentLocationOff, TbTemperature } from "react-icons/tb";
import { VscCompassActive } from "react-icons/vsc";
import { NavLink, Outlet } from "react-router-dom";
import { IoBatteryCharging } from "react-icons/io5";
import { MdOutlineCompassCalibration } from "react-icons/md";
import { SiBmcsoftware } from "react-icons/si";

// const socket = io(process.env.REACT_APP_SOCKET_URL);

const ParameterSettings = () => {
  // const [statusData, setStatusData] = useState({});
  // const [infoData, setInfoData] = useState({});

  // useEffect(() => {
  //   socket.on("status-data", (data) => {
  //     setStatusData(data?.statusData);
  //   });
  // }, [statusData]);

  // useEffect(() => {
  //   socket.on("info-data", (data) => {
  //     setInfoData(data?.infoData);
  //   });
  // }, [statusData]);

  // console.log("infoData", infoData);
  // console.log("statusData", statusData);

  let activeStyle = {
    borderBottom: "2px solid #FA6164",
    color: "#FA6164",
    borderRadius: "0",
  };

  return (
    <div className="px-5">
      {/* Inside setting start  */}
      <div className="mt-3 border-1 rounded-lg shadow-md p-3">
        <ul className="flex justify-center md:justify-start gap-1 text-primary font-bold ">
          <NavLink
            to={"active-balance"}
            className="p-[8px]"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            <li className="text-xs flex flex-col md:flex-row justify-center items-center gap-1 text-center md:text-left">
              <VscCompassActive className="text-md" />
              <span>Active Balance</span>
            </li>
          </NavLink>

          <NavLink
            to={"current-protection"}
            className="p-[8px]"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            <li className="text-xs flex flex-col md:flex-row justify-center items-center gap-1 text-center md:text-left">
              <TbCurrentLocationOff className="text-md" />
              <span>Current Protection</span>
            </li>
          </NavLink>

          <NavLink
            to={"temp-protection"}
            className="p-[8px]"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            <li className="text-xs flex flex-col md:flex-row justify-center items-center gap-1 text-center md:text-left">
              <TbTemperature className="text-md" />
              <span>Temp. Protection</span>
            </li>
          </NavLink>

          <NavLink
            to={"battery-system"}
            className="p-[8px]"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            <li className="text-xs flex flex-col md:flex-row justify-center items-center gap-1 text-center md:text-left">
              <IoBatteryCharging className="text-md" />
              <span>Battery System</span>
            </li>
          </NavLink>

          <NavLink
            to={"system-calibration"}
            className="p-[8px]"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            <li className="text-xs flex flex-col md:flex-row justify-center items-center gap-1 text-center md:text-left">
              <MdOutlineCompassCalibration className="text-md" />
              <span>System Calibration</span>
            </li>
          </NavLink>

          <NavLink
            to={"system-software"}
            className="p-[8px]"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            <li className="text-xs flex flex-col md:flex-row justify-center items-center gap-1 text-center md:text-left">
              <SiBmcsoftware className="text-md" />
              <span>System Software</span>
            </li>
          </NavLink>

          <NavLink
            to={"parameter-setting"}
            className="p-[8px]"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            <li className="text-xs flex flex-col md:flex-row justify-center items-center gap-1 text-center md:text-left">
              <RiListSettingsLine className="text-md" />
              <span>Parameter Setting</span>
            </li>
          </NavLink>
        </ul>
      </div>
      {/* Inside setting end  */}

      {/* Inside setting content  */}
      <div>
        <Outlet />
      </div>
      {/* Inside setting end  */}
    </div>

    // <>
    //     <div className='grid grid-cols-1 md:grid-cols-3 gap-2'>
    //         {/* Row Number 1 And Left Column */}
    //         <div className='md:col-span-2 order-2 md:order-1'>

    //             <div className='grid grid-cols-1 md:grid-cols-2 gap-2 mt-3 '>

    //                 {/* Item no 1 */}

    //                 <div className='flex justify-center items-center border shadow-lg rounded-lg p-1.5'>
    //                     <div>
    //                         <div className='mb-4'>
    //                             <label htmlFor="text" className='mr-5 font-bold'>Rated Cap(AH):</label>
    //                             <input type="text" placeholder="Type here" className="input input-sm input-bordered input-primary max-w-xs w-24" />
    //                         </div>
    //                         <div>
    //                             <label htmlFor="text" className='mr-1 font-bold'>Rated CellVolt(V):</label>
    //                             <input type="text" placeholder="Type here" className="input input-sm input-bordered input-primary max-w-xs w-24" />
    //                         </div>
    //                     </div>
    //                     <div className='ml-3'>
    //                         <button className="btn btn-active btn-primary text-white btn-xs">Set</button>
    //                     </div>
    //                 </div>

    //                 {/* Item no 2 */}

    //                 <div className='flex justify-center items-center border shadow-lg rounded-lg p-1.5'>
    //                     <div>
    //                         <div className='mb-4'>
    //                             <label htmlFor="text" className='mr-4 font-bold'>Balance Start Volt(V):</label>
    //                             <input type="text" placeholder="Type here" className="input input-sm input-bordered input-primary max-w-xs w-24" />
    //                         </div>
    //                         <div>
    //                             <label htmlFor="text" className='mr-5 font-bold'>Bal start diff Volt(V):</label>
    //                             <input type="text" placeholder="Type here" className="input input-sm input-bordered input-primary max-w-xs w-24" />
    //                         </div>
    //                     </div>
    //                     <div className='ml-2'>
    //                         <button className="btn btn-active btn-primary text-white btn-xs">Set</button>
    //                     </div>
    //                 </div>

    //                 {/* Item no 2 */}

    //                 <div className='flex justify-center items-center border shadow-lg rounded-lg p-1.5'>
    //                     <div>
    //                         <div className='mb-4'>
    //                             <label htmlFor="text" className='mr-5 font-bold'>Rated Cap(AH):</label>
    //                             <input type="text" placeholder="Type here" className="input input-sm input-bordered input-primary max-w-xs w-24" />
    //                         </div>
    //                         <div>
    //                             <label htmlFor="text" className='mr-1 font-bold'>Rated CellVolt(V):</label>
    //                             <input type="text" placeholder="Type here" className="input input-sm input-bordered input-primary max-w-xs w-24" />
    //                         </div>
    //                     </div>
    //                     <div className='ml-3'>
    //                         <button className="btn btn-active btn-primary text-white btn-xs">Set</button>
    //                     </div>
    //                 </div>

    //                 {/* Item no 2 */}

    //                 <div className='flex justify-center items-center border shadow-lg rounded-lg p-1.5'>
    //                     <div>
    //                         <div className='mb-4'>
    //                             <label htmlFor="text" className='mr-3 font-bold'>Balance Start Volt(V):</label>
    //                             <input type="text" placeholder="Type here" className="input input-sm input-bordered input-primary max-w-xs w-24" />
    //                         </div>
    //                         <div>
    //                             <label htmlFor="text" className='mr-5 font-bold'>Bal start diff Volt(V):</label>
    //                             <input type="text" placeholder="Type here" className="input input-sm input-bordered input-primary max-w-xs w-24" />
    //                         </div>
    //                     </div>
    //                     <div className='ml-2'>
    //                         <button className="btn btn-active btn-primary text-white btn-xs">Set</button>
    //                     </div>
    //                 </div>

    //                 <div className='md:col-span-2'>

    //                     <div className='grid grid-cols-1 md:grid-cols-2 gap-2 border p-3 rounded-lg shadow-lg'>
    //                         <div className='flex justify-center items-center'>
    //                             <label htmlFor="text" className='mr-5 font-bold'>No. of acquisition board:</label>
    //                             <input type="text" placeholder="Type here" className="input input-sm input-bordered input-primary max-w-xs w-24" />
    //                         </div>
    //                         <div className='flex justify-center items-center'>
    //                             <div>
    //                                 <div className='mb-4'>
    //                                     <label htmlFor="text" className='mr-5 font-bold'>B 1~3 C No.:</label>
    //                                     <input type="text" placeholder="A" className="input input-sm input-bordered input-primary max-w-xs w-11 mr-1.5" />
    //                                     <input type="text" placeholder="B" className="input input-sm input-bordered input-primary max-w-xs w-11 mr-1.5" />
    //                                     <input type="text" placeholder="C" className="input input-sm input-bordered input-primary max-w-xs w-11 mr-1.5" />
    //                                 </div>
    //                                 <div className='mb-2'>
    //                                     <label htmlFor="text" className='mr- font-bold'>B 1~3 NTC No.:</label>
    //                                     <input type="text" placeholder="A" className="input input-sm input-bordered input-primary max-w-xs w-11 mr-1.5" />
    //                                     <input type="text" placeholder="B" className="input input-sm input-bordered input-primary max-w-xs w-11 mr-1.5" />
    //                                     <input type="text" placeholder="C" className="input input-sm input-bordered input-primary max-w-xs w-11 mr-1.5" />
    //                                 </div>
    //                             </div>
    //                             <div className='ml-1'>
    //                                 <button className="btn btn-active btn-primary text-white btn-xs">Set</button>
    //                             </div>
    //                         </div>
    //                     </div>

    //                 </div>

    //             </div>
    //         </div>

    //         {/* Right column */}

    //         <div className='border mt-3 p-3 rounded-lg shadow-lg order-1 md:order-2'>
    //             <div className='flex flex-col justify-center items-center'>
    //                 <div className='mb-3'>
    //                     <label htmlFor="text" className='mr-10 font-bold'>Sleep Time(S):</label>
    //                     <input type="text" placeholder="Type here" className="input input-sm input-bordered input-primary max-w-xs w-24" />
    //                 </div>
    //                 <div className='mb-3'>
    //                     <label htmlFor="text" className='mr-5 font-bold'>Current Wave(A):</label>
    //                     <input type="text" placeholder="Type here" className="input input-sm input-bordered input-primary max-w-xs w-24" />
    //                 </div>
    //             </div>
    //             <div className='flex justify-between items-center mb-3'>
    //                 <div>
    //                     <input type="text" placeholder="Y" className="input input-sm input-bordered input-primary max-w-xs w-12 mr-1" />
    //                     <label htmlFor="text" className='mr-5 font-bold'>Y</label>
    //                 </div>
    //                 <div>
    //                     <input type="text" placeholder="M" className="input input-sm input-bordered input-primary max-w-xs w-12 mr-1" />
    //                     <label htmlFor="text" className='mr-5 font-bold'>M</label>
    //                 </div>
    //                 <div>
    //                     <input type="text" placeholder="D" className="input input-sm input-bordered input-primary max-w-xs w-12 mr-1" />
    //                     <label htmlFor="text" className='mr-5 font-bold'>D</label>
    //                 </div>
    //                 <button className="btn btn-active btn-primary text-white btn-xs">Set</button>
    //             </div>
    //             <div className='mb-2'>
    //                 <label htmlFor="text" className='font-bold'>Batter Type:</label>
    //                 <select className="select select-success w-full select-sm max-w-xs mt-2">
    //                     <option disabled selected>Battery Type</option>
    //                     <option>Tenary Lithium</option>
    //                     <option>Lithium Iron</option>
    //                     <option>Lithium Titanet</option>
    //                 </select>
    //             </div>
    //             <div>
    //                 <label htmlFor="text" className='font-bold'>Batter Operation Mode:</label>
    //                 <select className="select select-success w-full select-sm max-w-xs mt-2">
    //                     <option disabled selected>Battery Operation Mode</option>
    //                     <option>Short Press Power on/off</option>
    //                     <option>Long  Press Power on/off</option>
    //                 </select>
    //             </div>
    //         </div>
    //     </div>

    //     <div className='mt-3 border shadow-lg rounded-lg p-4 order-3'>
    //         <div className='grid grid-cols-1 md:grid-cols-2'>
    //             <div>
    //                 <div className='mb-3 flex items-center'>
    //                     <div>
    //                         <label htmlFor="text" className='mr-2 font-bold'>Firewall Index No:</label>
    //                         <input type="text" placeholder="Firewall Index No:" className="input input-sm input-bordered input-primary max-w-xs w-56" />
    //                     </div>
    //                     <div className='ml-3'>
    //                         <button className="btn btn-active btn-primary text-white btn-xs">Set</button>
    //                     </div>
    //                 </div>
    //                 <div className='mb-2 flex items-center'>
    //                     <div>
    //                         <label htmlFor="text" className='mr-10 font-bold'>Battery Code:</label>
    //                         <input type="text" placeholder="Battery Code" className="input input-sm input-bordered input-primary max-w-xs w-56" />
    //                     </div>
    //                     <div className='ml-3'>
    //                         <button className="btn btn-active btn-primary text-white btn-xs">Set</button>
    //                     </div>
    //                 </div>
    //             </div>

    //             <div>
    //                 <div className='mb-3 flex items-center'>
    //                     <div>
    //                         <label htmlFor="text" className='mr-14 font-bold'>IP:</label>
    //                         <input type="text" placeholder="Battery Code" className="input input-sm input-bordered input-primary max-w-xs w-56" />
    //                     </div>
    //                     <div className='ml-3'>
    //                         <button className="btn btn-active btn-primary text-white btn-xs">Set</button>
    //                     </div>
    //                 </div>
    //                 <div className='mb-3 flex items-center'>
    //                     <div>
    //                         <label htmlFor="text" className='mr-10 font-bold'>SOC:</label>
    //                         <input type="text" placeholder="Battery Code" className="input input-sm input-bordered input-primary max-w-xs w-56" />
    //                     </div>
    //                     <div className='ml-3'>
    //                         <button className="btn btn-active btn-primary text-white btn-xs">Set</button>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // </>
  );
};

export default ParameterSettings;
