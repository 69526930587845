import React from 'react';
import { MdTimelapse } from 'react-icons/md';

const DevicePending = () => {
    return (
        <div className='flex items-center gap-1 text-sm rounded-full px-1 bg-orange-100 text-orange-500'>
            <MdTimelapse />
            <span> Pending</span>
        </div>
    );
};

export default DevicePending;