import React from 'react';
import { BsFillBellFill } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';

const Notify = () => {
    // Active route styles 
    let activeStyle = {
        borderBottom: '2px solid #C93A68',
    };

    return (
        <>
            <NavLink to='/dashboard/notifications' className="hover:border-b-2 hover:border-primary rounded-none px-2" style={({ isActive }) => isActive ? activeStyle : undefined}>
                <div className="avatar indicator mt-3 cursor-pointer">
                    <span className="indicator-item bg-accent text-xs rounded-full px-1 shadow-md text-white">13</span>
                    <div className="rounded-lg">
                        <BsFillBellFill size={25} className='w-full h-full text-primary' />
                    </div>
                </div>
            </NavLink>
        </>
    );
};

export default Notify;