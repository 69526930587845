import React, { memo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import DashboardNavbar from "../../components/Navbar/DashboardNavbar";
import logo from "../../assets/logo-png.png";
import { IoIosArrowBack } from "react-icons/io";
import { useContext } from "react";
import { BmsContext } from "../../App";
import AdminMenuItems from "./MenuItems/AdminMenuItems";
import UserMenuItems from "./MenuItems/UserMenuItems";
import { useEffect } from "react";
import Loading from "../../components/Shared/Loading";
import useUserProfile from "../../hooks/useUserProfile";

const Dashboard = () => {
  // const [admin, adminLoading] = useAdmin(user);

  const { sidebarContext } = useContext(BmsContext);
  const { sidebarHide, setSidebarHide } = sidebarContext;

  const { userInfo, isUserLoading } = useUserProfile();

  const [drawar, setDrawer] = useState(false);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === "/dashboard/parameter-settings") {
      navigate("/dashboard/parameter-settings/active-balance");
    }

    // if (pathname === '/dashboard') {
    //     console.log("dashboard");
    //     if (userInfo?.role === 'super-admin') {
    //         navigate("/dashboard/admin-dashboard");
    //     } else {
    //         navigate('/dashboard/battery-bms');
    //         // window.location.reload();
    //     }
    // }
  }, [pathname, navigate]);

  if (isUserLoading) {
    return <Loading />;
  }

  // detect drawer toggle
  const handleDrawerToggle = () => {
    setDrawer(!drawar);
  };

  return (
    <div>
      <div className="drawer drawer-mobile">
        <input
          id="my-drawer-2"
          onClick={handleDrawerToggle}
          type="checkbox"
          className="drawer-toggle"
        />

        <div className="drawer-content bg-gray-100">
          {/* Page content here  */}
          <DashboardNavbar />
          <Outlet />
        </div>

        {/* Sidebar content here */}
        <div className="drawer-side">
          {/* circle drawer for mobile  */}
          <label
            htmlFor="my-drawer-2"
            className={`drawer-button absolute left-[203px] top-6 z-50 ${
              drawar ? "" : "hidden"
            }`}
          >
            <IoIosArrowBack
              size={22}
              className="bg-white rounded-full p-0.5 shadow-xl"
            />
          </label>

          {/* Circle drawer for desktop  */}
          {sidebarHide === false && (
            <label
              htmlFor="my-drawer-2"
              onClick={() => setSidebarHide(!sidebarHide)}
              className={`drawer-button absolute left-[203px] top-6 z-50 hidden lg:block`}
            >
              <IoIosArrowBack
                size={22}
                className="bg-white rounded-full p-0.5 shadow-xl"
              />
            </label>
          )}

          <label htmlFor="my-drawer-2" className="drawer-overlay"></label>

          <ul
            className={`p-4 overflow-y-auto text-base-content bg-gradient-to-b from-[#040327] to-[#0D7FBF] shadow-md w-[215px] -ml-[1px] ${
              sidebarHide ? "lg:-ml-[600px]" : ""
            }`}
          >
            <div className="w-full h-20 pr-4 mb-10">
              <a href="/">
                <img src={logo} alt="logo" className="h-full" />
              </a>
            </div>

            {/* Menu Items for Regular User  */}
            {userInfo?.role === "user" && <UserMenuItems />}

            {/* Menu Items for Admin  */}
            {userInfo?.role === "super-admin" && <AdminMenuItems />}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default memo(Dashboard);
