import axios from "axios";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import LoadingButton from "../../../../components/Shared/LoadingButton";
import { BmsContext } from "../../../../App";

const DeviceEditForAdmin = ({ setIsDeviceEdit, editDevice, refetch }) => {
  const { _id, deviceId, deviceName, deviceStatus, deviceOwner } = editDevice;
  const [updateLoading, setUpdateLoading] = useState(false);

  const { userInfo } = useContext(BmsContext);
  const authToken = localStorage.getItem('accessToken');

  const handleDeviceUpdateForAdmin = (e) => {
    e.preventDefault();
    setUpdateLoading(true);
    const deviceId = e.target.deviceId.value;
    const deviceName = e.target.deviceName.value;
    const deviceOwner = e.target.deviceOwner.value;
    const deviceStatus = e.target.deviceStatus.value;

    (async () => {
      const payload = { deviceId, deviceName, deviceOwner, deviceStatus };
      const url = `${process.env.REACT_APP_API_BASE_URL}api/v1/device/user-devices/${_id}?phone=${userInfo?.phone}`;

      const res = await axios.patch(
        url,
        payload,
        {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (res.data.success) {
        toast.success("Device Updated Successfully", { position: "top-left" });
        refetch();
      }
      setIsDeviceEdit(false);
      setUpdateLoading(false);
    })();
  };

  return (
    <div className="relative flex justify-center pt-4">
      <div className="w-full md:w-[800px] absolute z-20 rounded-xl bg-white ease-in-out">
        <div className="h-auto md:h-[80vh] card px-4 py-2 flex items-center justify-center">
          <button
            onClick={() => setIsDeviceEdit(false)}
            className=" bg-red-100 border border-red-500 p-1 rounded-full w-8 h-8 text-red-500 absolute right-2 top-2"
          >
            ✕
          </button>
          <div className="px-10 py-8 rounded-md">
            <form
              onSubmit={(e) => {
                handleDeviceUpdateForAdmin(e);
              }}
            >
              <div className="grid  md:grid-cols-2 gap-x-4 w-full justify-center items-center">
                <div className="my-3">
                  <label>Device Name</label>
                  <input
                    type="text"
                    name="deviceName"
                    defaultValue={deviceName}
                    required
                    placeholder="Device Name"
                    className="input input-bordered border-primary w-full"
                  />
                </div>
                <div className="my-3">
                  <label>Device ID</label>
                  <input
                    type="text"
                    name="deviceId"
                    defaultValue={deviceId}
                    placeholder="Device ID"
                    className="input input-bordered border-primary w-full"
                  />
                </div>
                <div className="my-3">
                  <label>Device Owner</label>
                  <input
                    type="text"
                    name="deviceOwner"
                    defaultValue={deviceOwner}
                    placeholder="Device ID"
                    className="input input-bordered border-primary w-full"
                  />
                </div>
                <div className="my-3">
                  <label>Device Status</label>
                  <select
                    name="deviceStatus"
                    className="select select-primary w-full"
                  >
                    <option selected={deviceStatus === "Pending"}>
                      Pending
                    </option>
                    <option selected={deviceStatus === "Verified"}>
                      Verified
                    </option>
                  </select>
                </div>
              </div>
              <div className="flex justify-center mt-4">
                <div className="w-full md:w-72">
                  {!updateLoading ? (
                    <button type="submit" className="btn btn-primary w-full">
                      Update
                    </button>
                  ) : (
                    <LoadingButton name={"Updating"} />
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceEditForAdmin;
