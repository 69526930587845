import React, { useContext } from "react";
import { RiAlarmWarningLine, RiBatteryShareLine } from "react-icons/ri";
import {
  TbBatteryCharging as TbDischarging,
  TbBatteryCharging2,
} from "react-icons/tb";
import { BmsContext } from "../../../App";

const Controlls = () => {
  const { controllContext } = useContext(BmsContext);
  const {
    charge,
    disCharge,
    balance,
    emergency,
    handleCharge,
    handleDisCharge,
    handleBalance,
    handleEmergency,
  } = controllContext;

  return (
    <div className="m-4">
      <div className="grid grid-cols-2 gap-3 bg-white px-3 py-3 rounded-lg shadow-lg">
        <div className="bg-teal-50 border border-teal-800 px-1.5 py-2 rounded flex justify-center items-center gap-2 shadow text-sm md:text-lg">
          <TbBatteryCharging2
            size={40}
            className="bg-teal-800 text-white rounded-full p-1 shadow-lg"
          />
          <div>
            <p className="font-bold text-sm text-teal-800 mb-0.5">Charge:</p>
            <input
              onChange={() => handleCharge()}
              name="charge"
              type="checkbox"
              className="toggle toggle-primary toggle-sm md:toggle-md"
              checked={charge}
            />
          </div>
        </div>

        <div className="bg-orange-50 border border-secondary px-1.5 py-2 rounded flex justify-center items-center gap-2 shadow text-sm md:text-lg">
          <TbDischarging
            size={40}
            className="bg-secondary text-white rounded-full p-1 shadow-lg"
          />
          <div>
            <p className="font-bold text-sm text-secondary mb-0.5">
              Discharge:
            </p>
            <input
              onChange={() => handleDisCharge()}
              name="disCharge"
              type="checkbox"
              className="toggle toggle-primary toggle-sm md:toggle-md"
              checked={disCharge}
            />
          </div>
        </div>

        <div className="bg-blue-50 border border-blue-900 px-1.5 py-2 rounded flex justify-center items-center gap-2 shadow text-sm md:text-lg">
          <RiBatteryShareLine
            size={40}
            className="bg-[#01475E] text-white rounded-full p-1 shadow-lg"
          />
          <div>
            <p className="font-bold text-sm text-[#01475E] mb-0.5">Balance:</p>
            <input
              onChange={() => handleBalance()}
              name="balance"
              type="checkbox"
              className="toggle toggle-primary toggle-sm md:toggle-md"
              checked={balance}
            />
          </div>
        </div>

        <div className="bg-red-50 border border-accent px-1.5 py-2 rounded flex justify-center items-center gap-2 shadow text-sm md:text-lg">
          <RiAlarmWarningLine
            size={40}
            className="bg-accent text-white rounded-full p-1 shadow-lg"
          />
          <div>
            <p className="font-bold text-sm text-accent mb-0.5">Emergency:</p>
            <input
              onChange={() => handleEmergency()}
              name="emergency"
              type="checkbox"
              className="toggle toggle-primary toggle-sm md:toggle-md"
              checked={emergency}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Controlls;
