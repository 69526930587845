import React from 'react';

const GPSInfo = ({ gpsData, updatedTime }) => {
    const gps = gpsData?.gpsValues;
    return (
        <div className="grid grid-cols-1 md:grid-cols-3 my-4 shadow-md border p-4 gap-2 bg-gradient-to-r from-[#040327] to-[#0D7FBF] rounded-xl">

            <div className="px-4 py-2 rounded-md text-white bg-white/30 shadow-md">
                <h5>Time: <span className='font-medium'>{updatedTime}</span></h5>
            </div>

            <div className="px-4 py-2 rounded-md text-white bg-white/30 shadow-md">
                <h5>GPS Sattelite No: <span className='font-medium'>{gps?.numberOfGPSSattelite}</span></h5>
            </div>

            <div className="px-4 py-2 rounded-md text-white bg-white/30 shadow-md">
                <h5>Latitute: <span className='font-medium'>{gps?.latitute}</span></h5>
            </div>

            <div className="px-4 py-2 rounded-md text-white bg-white/30 shadow-md">
                <h5>Longitude: <span className='font-medium'>{gps?.longitude}</span></h5>
            </div>

            <div className="px-4 py-2 rounded-md text-white bg-white/30 shadow-md">
                <h5>Altitude: <span className='font-medium'>{gps?.altitude}</span></h5>
            </div>

            <div className="px-4 py-2 rounded-md text-white bg-white/30 shadow-md">
                <h5>Course: <span className='font-medium'>{gps?.course}</span></h5>
            </div>

            <div className="px-4 py-2 rounded-md text-white bg-white/30 shadow-md">
                <h5>Speed: <span className='font-medium'>{gps?.speed}</span></h5>
            </div>

        </div>
    );
};

export default GPSInfo;