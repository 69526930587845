import React from "react";

const SettingModal = () => {
  return (
    <div>
      <input type="checkbox" id="setting-modal" className="modal-toggle" />
      <div className="backdrop-blur-sm modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <h3 className="font-bold text-lg">
            Congratulations random Internet user!
          </h3>
          <div className="modal-action">
            <label htmlFor="setting-modal" className="btn">
              It's Okay!
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingModal;
