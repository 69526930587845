import React from "react";

const MyReportDetails = (props) => {
  const { currentDate, startDate, endDate, allBMSReportData, tableRef } = props;
  return (
    <div ref={tableRef} className="bg-white rounded-md">
      <div className="mb-4">
        <div className="flex justify-center gap-4">
          <div className="p-4">
            <h2 className="text-center text-3xl font-bold uppercase">
              Sytiqhub Private Limited
            </h2>
            <h3 className="text-center text-xl font-bold">BMS Report</h3>
          </div>
          <div className="text-lg text-center md:text-right rounded-lg w-3/7 flex flex-col justify-center p-3">
            <h4 className="font-medium text-center">
              Date Range: <br /> <span className="font-bold">{startDate}</span>{" "}
              to <span className="font-bold">{endDate}</span>
            </h4>
            <h4 className="font-medium">
              Generated Date: <span className="font-bold">{currentDate}</span>
            </h4>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full mx-auto mb-4">
          <thead>
            <tr>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                Time
              </th>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                Volt
              </th>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                Amp
              </th>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                SOC
              </th>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                Cell-1
              </th>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                Cell-2
              </th>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                Cell-3
              </th>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                Cell-4
              </th>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                Cell-5
              </th>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                Cell-6
              </th>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                Cell-7
              </th>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                Cell-8
              </th>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                Cell-9
              </th>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                Cell-10
              </th>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                Cell-11
              </th>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                Cell-12
              </th>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                Cell-13
              </th>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                Cell-14
              </th>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                Cell-15
              </th>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                Cell-16
              </th>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                Cell-17
              </th>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                Cell-18
              </th>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                Cell-19
              </th>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                Temp-1
              </th>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                Temp-2
              </th>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                Temp-3
              </th>
              <th className="text-center border font-medium text-md p-1 bg-slate-300">
                Temp-4
              </th>
            </tr>
          </thead>
          <tbody className="">
            {allBMSReportData?.data?.map((item, index) => (
              <tr key={index} className="text-center">
                <td className="text-sm text-slate-700 bg-violet-300/50 border">
                  {item?.timestamp?.slice(0, 19)}
                </td>{" "}
                <td className="text-sm text-slate-700 bg-green-200/80 border">
                  {item?.bmsData?.F0?.volt}
                </td>
                <td className="text-sm text-slate-700 bg-pink-200/80 border">
                  {item?.bmsData?.F0?.amp}
                </td>{" "}
                <td className="text-sm text-slate-700 bg-yellow-200/50 border">
                  {item?.bmsData?.F0?.soc}
                </td>{" "}
                <td className="text-sm text-slate-700 bg-indigo-200/80 border">
                  {item?.bmsData?.F3[0]}
                </td>{" "}
                <td className="text-sm text-slate-700 bg-indigo-200/80 border">
                  {item?.bmsData?.F3[1]}
                </td>{" "}
                <td className="text-sm text-slate-700 bg-indigo-200/80 border">
                  {item?.bmsData?.F3[2]}
                </td>{" "}
                <td className="text-sm text-slate-700 bg-indigo-200/80 border">
                  {item?.bmsData?.F3[3]}
                </td>{" "}
                <td className="text-sm text-slate-700 bg-indigo-200/80 border">
                  {item?.bmsData?.F3[4]}
                </td>{" "}
                <td className="text-sm text-slate-700 bg-indigo-200/80 border">
                  {item?.bmsData?.F3[5]}
                </td>{" "}
                <td className="text-sm text-slate-700 bg-indigo-200/80 border">
                  {item?.bmsData?.F3[6]}
                </td>{" "}
                <td className="text-sm text-slate-700 bg-indigo-200/80 border">
                  {item?.bmsData?.F3[7]}
                </td>{" "}
                <td className="text-sm text-slate-700 bg-indigo-200/80 border">
                  {item?.bmsData?.F3[8]}
                </td>{" "}
                <td className="text-sm text-slate-700 bg-indigo-200/80 border">
                  {item?.bmsData?.F3[9]}
                </td>{" "}
                <td className="text-sm text-slate-700 bg-indigo-200/80 border">
                  {item?.bmsData?.F3[10]}
                </td>{" "}
                <td className="text-sm text-slate-700 bg-indigo-200/80 border">
                  {item?.bmsData?.F3[11]}
                </td>{" "}
                <td className="text-sm text-slate-700 bg-indigo-200/80 border">
                  {item?.bmsData?.F3[12]}
                </td>{" "}
                <td className="text-sm text-slate-700 bg-indigo-200/80 border">
                  {item?.bmsData?.F3[13]}
                </td>{" "}
                <td className="text-sm text-slate-700 bg-indigo-200/80 border">
                  {item?.bmsData?.F3[14]}
                </td>{" "}
                <td className="text-sm text-slate-700 bg-indigo-200/80 border">
                  {item?.bmsData?.F3[15]}
                </td>{" "}
                <td className="text-sm text-slate-700 bg-indigo-200/80 border">
                  {item?.bmsData?.F3[16]}
                </td>{" "}
                <td className="text-sm text-slate-700 bg-indigo-200/80 border">
                  {item?.bmsData?.F3[17]}
                </td>{" "}
                <td className="text-sm text-slate-700 bg-indigo-200/80 border">
                  {item?.bmsData?.F3[18]}
                </td>{" "}
                <td className="text-sm text-slate-700 bg-blue-200/100 border">
                  {item?.bmsData?.F4[0]}
                </td>{" "}
                <td className="text-sm text-slate-700 bg-blue-200/100 border">
                  {item?.bmsData?.F4[1]}
                </td>{" "}
                <td className="text-sm text-slate-700 bg-blue-200/100 border">
                  {item?.bmsData?.F4[2]}
                </td>{" "}
                <td className="text-sm text-slate-700 bg-blue-200/100 border">
                  {item?.bmsData?.F4[3]}
                </td>{" "}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MyReportDetails;
