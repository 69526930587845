import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Tooltip } from "react-leaflet";
import { useQuery } from "react-query";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

const AdminDashboard = () => {
  const [devices, setDevices] = useState({});
  const [users, setUsers] = useState([]);

  const authToken = localStorage.getItem('accessToken');

  // getting total users ---------------------------
  const urlUsers = `${process.env.REACT_APP_AUTH_API_BASE_URL}/users/user-list`;
  const { data: usersObject, isLoading, refetch } = useQuery('users', () => fetch(urlUsers, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  })
    .then(res => res.json()));
  // -------------------------------------------

  // getting total devices  ---------------------------------
  const urlDevices = `${process.env.REACT_APP_API_BASE_URL}api/v1/device/user-devices`;

  const { data: devicesObject, isLoading: deviceLoading, refetch: deviceRefetch } = useQuery(
    "devices",
    () => fetch(urlDevices, {
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    }).then((res) => res.json())
  );
  // --------------------------------------------------------

  useEffect(() => {
    if (usersObject) {
      setUsers(usersObject?.data);
    }
    if (devicesObject) {
      setDevices(devicesObject?.data);
    }
  }, [usersObject, devicesObject]);
  // -------------------------------------------

  // Graph Data
  const data = [
    {
      day: "16:20",
      users: 3.8,
      devices: 2.4,
    },
    {
      day: "16:20",
      users: 3.7,
      devices: 2.3,
    },
    {
      day: "16:20",
      users: 3.4,
      devices: 2.4,
    },
    {
      day: "16:20",
      users: 3.2,
      devices: 2.1,
    },
    {
      day: "16:20",
      users: 3.9,
      devices: 2.4,
    },
    {
      day: "16:20",
      users: 3.4,
      devices: 2.5,
    },
    {
      day: "16:20",
      users: 3.8,
      devices: 0,
    },
    {
      day: "16:20",
      users: 3.2,
      devices: -1,
    },
    {
      day: "16:20",
      users: 3.8,
      devices: 2.4,
    },
    {
      day: "16:20",
      users: 3.7,
      devices: 2.4,
    },
    {
      day: "16:20",
      users: 3.8,
      devices: 2.2,
    },
  ];
  return (
    <div className="m-4">
      {/* Info grid -------------------------------------------------------------------------- */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Total User And Total Devices section ----------------------------------------- */}

        <div
          className="text-center text-slate-700 rounded-md shadow-lg py-8 px-2"
          style={{ backgroundColor: "rgba(13, 127, 191, 0.15)" }}
        >
          <h2 className="text-lg md:text-2xl font-bold uppercase">
            Total Users
          </h2>
          <h3 className="text-lg md:text-4xl text-primary font-bold uppercase">
            {users?.totalUsers}
          </h3>
        </div>

        <div
          className="text-center text-slate-700 rounded-md shadow-lg py-8 px-2"
          style={{ backgroundColor: "rgba(44, 64, 149, 0.1) " }}
        >
          <h2 className="text-lg md:text-2xl font-bold uppercase">
            Total Devices
          </h2>
          <h3 className="text-lg md:text-4xl text-primary font-bold uppercase">
            {devices?.totalDevices}
          </h3>
        </div>
        {/* ------------------------------------------------------------------------------- */}

      </div>
      {/* ------------------------------------------------------------------------------------ */}


      {/* user vs devices vs day graph ------------------------------------------------- */}
      <div
        className="rounded-md shadow-lg py-4 px-2 mt-4"
        style={{ backgroundColor: "rgba(56, 146, 210, 0.1)" }}
      >
        {/* Dashboard Chart start  */}
        <div className="w-full min-h-40 md:h-96 pt-1.5 rounded-lg">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={data}
              margin={{
                top: 10,
                right: 0,
                left: 0,
                bottom: 10,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="day" />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip />
              <Legend />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="users"
                stroke="#2C4095"
                activeDot={{ r: 8 }}
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="devices"
                stroke="#FA6164"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        {/* Dashboard Chart end  */}
      </div>
      {/* ------------------------------------------------------------------------------ */}

      {/* Devices listings ---------------------------------------------------------- */}
      {/* <div className='mt-10 bg-slate-200 shadow-lg py-4 rounded-md'>
                <div>
                    <h3 className='text-center text-2xl font-medium text-slate-700 mb-3'>Search Devices</h3>
                    <div className="form-control w-full px-4 md:px-[10rem]">
                        <div className="input-group">
                            <input type="text" placeholder="Search…" className="input input-bordered w-full" />
                            <button className="btn btn-square">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg>
                            </button>
                        </div>
                    </div>
                </div>



                <div className='my-6 px-4 grid grid-cols-1 md:grid-cols-3 gap-4'>
                    <div className="card bg-slate-100 rounded-md cursor-pointer shadow-lg">
                        <div className="card-body py-2 px-4">
                            <h2 className="card-title">DEVICE NAME: Omar Khan</h2>
                            <p><span className='font-medium'>DEVICE ID:</span> 343243454545</p>
                            <p><span className='font-medium'>DEVICE OWNER:</span> omar@gmail.com</p>
                        </div>
                    </div>
                </div>


            </div> */}
      {/* ------------------------------------------------------------------------------------- */}
    </div>
  );
};

export default React.memo(AdminDashboard);
