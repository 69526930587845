import React, { useEffect, useState, useRef } from "react";
import { io } from "socket.io-client";
import LoadingContent from "../../../components/Shared/LoadingContent";
import {
  useJsApiLoader,
  GoogleMap,
  OverlayView,
  MarkerF,
} from "@react-google-maps/api";
import GetVehicleHistory from "./GetVehicleHistory";
import GPSInfo from "./GPSInfo";
import Geofence from "./Geofence";
import GeoFenceMap from "./GeoFenceMap";
import libraries from "../../../constant/jsAPILoaderLibrary";
import ArrowIcon from "../../../assets/arrow-icon.png";
import PolyLineDetails from "./PolyLineDetails";
import MainGPSandDetails from "./MainGPSandDetails";

const socket = io(process.env.REACT_APP_SOCKET_URL);

const LiveLocation = () => {
  const [gpsData, setGpsData] = useState(null);
  const [updatedTime, setUpdatedTime] = useState("");
  const [tabName, setTabName] = useState("history-track");

  const [gpsHistory, setGpsHistory] = useState(null);
  const [geofenceMarker, setGeofenceMarker] = useState([]);
  const [geofenceCoords, setGeofenceCoords] = useState([]);

  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);
  const [selectedMarkerData, setSelectedMarkerData] = useState(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  });

  const containerStyle = {
    width: "100%",
    height: "500px",
  };

  const markerRef = useRef(null);

  useEffect(() => {
    const handleGpsData = (data) => {
      if (data.gpsData !== gpsData) {
        setGpsData(data.gpsData);
        setUpdatedTime(data.time);

        if (markerRef.current) {
          markerRef.current.setPosition({
            lat: parseFloat(data.gpsData?.gpsValues?.latitute),
            lng: parseFloat(data.gpsData?.gpsValues?.longitude),
          });
        }
      }
    };

    socket.on("mqtt-gps", handleGpsData);

    return () => {
      socket.off("mqtt-gps", handleGpsData);
    };
  }, [gpsData]);

  const defaultCoordinate = {
    lat: 16.544845,
    lng: 81.245645,
  };

  // Extract latitude and longitude values from gpsData
  const latitude = parseFloat(gpsData?.gpsValues?.latitute);
  const longitude = parseFloat(gpsData?.gpsValues?.longitude);

  // Check if both latitude and longitude are valid numbers
  const isValidCenter = !isNaN(latitude) && !isNaN(longitude);

  // Create the center object with valid coordinates
  const currentCoordinate = isValidCenter
    ? { lat: latitude, lng: longitude }
    : defaultCoordinate;

  let coordinates = [];

  if (gpsHistory?.deviceLocations.length > 0) {
    for (const gps of gpsHistory?.deviceLocations) {
      const lat = Number(gps?.gpsData?.gpsValues?.latitute);
      const lng = Number(gps?.gpsData?.gpsValues?.longitude);
      const coord = { lat: lat, lng: lng };
      coordinates.push(coord);
    }
  }

  // Function to handle marker click and open InfoWindow
  const handleMarkerClick = (markerData) => {
    setSelectedMarkerData(markerData);
    setIsInfoWindowOpen(true);
  };

  // Function to handle InfoWindow close
  const handleInfoWindowClose = () => {
    setIsInfoWindowOpen(false);
  };

  const headingInDegrees = parseInt(gpsData?.gpsValues?.course) || 0;

  function calculateDistance(lat1, lon1, lat2, lon2) {
    const toRadians = (degrees) => (degrees * Math.PI) / 180;
    const earthRadius = 6371; // Radius of the Earth in kilometers

    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c;

    return distance;
  }

  const calculateTotalDistance = () => {
    if (!coordinates || coordinates.length < 2) {
      return 0; // Not enough points to calculate distance
    }

    let totalDistance = 0;

    for (let i = 0; i < coordinates.length - 1; i++) {
      const { lat: lat1, lng: lon1 } = coordinates[i];
      const { lat: lat2, lng: lon2 } = coordinates[i + 1];

      if (isNaN(lat1) || isNaN(lon1) || isNaN(lat2) || isNaN(lon2)) {
        // Skip invalid coordinates
        continue;
      }

      const distance = calculateDistance(lat1, lon1, lat2, lon2);
      totalDistance += distance;
    }

    return totalDistance.toFixed(2); // Return total distance rounded to 2 decimal places
  };

  // Function to create a custom arrow icon for each coordinate
  const renderArrowMarker = (coordinate, index) => (
    <OverlayView
      key={index}
      position={coordinate}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <img
        src={ArrowIcon}
        alt="Arrow"
        style={{
          width: "5px", // Set the width and height of the arrow icon as per your requirement
          height: "5px",
          transform: `rotate(${headingInDegrees}deg)`, // Rotate the arrow to match the heading direction
          alignSelf: "center",
        }}
      />
    </OverlayView>
  );

  return (
    <div className="p-3 ">
      <div className="md:flex flex-wrap md:flex-nowrap gap-4">
        <div className="w-full md:w-3/6 min-h-96 shadow-xl rounded-md">
          <div className="grid grid-cols-2 bg-gradient-to-r from-[#040327] to-[#0D7FBF] rounded-md w-full p-1">
            <div>
              <button
                onClick={() => setTabName("history-track")}
                className={`w-full text-white py-1 rounded ${
                  tabName === "history-track" ? "bg-white/30" : ""
                }`}
              >
                History Track
              </button>
            </div>
            <div>
              <button
                onClick={() => setTabName("geofence")}
                className={`w-full text-white py-1 rounded ${
                  tabName === "geofence" ? "bg-white/30" : ""
                }`}
              >
                Geofence
              </button>
            </div>
          </div>

          <div className="mt-8 w-full p-2">
            {tabName === "geofence" && (
              <Geofence
                isLoaded={isLoaded}
                setGeofenceMarker={setGeofenceMarker}
                geofenceCoords={geofenceCoords}
                currentCoordinate={currentCoordinate}
              />
            )}
            {tabName === "history-track" && (
              <GetVehicleHistory
                calculateTotalDistance={calculateTotalDistance}
                coordinates={coordinates}
                gpsHistory={gpsHistory}
                setGpsHistory={setGpsHistory}
              />
            )}
          </div>
        </div>

        <div className="w-full">
          {isLoaded && (
            <div>
              {currentCoordinate || gpsHistory?.deviceLocations ? (
                <div className="p-3 bg-gray-100 rounded-lg shadow-lg border">
                  {tabName === "history-track" && (
                    <div>
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={currentCoordinate}
                        zoom={19}
                        heading={headingInDegrees}
                        options={{
                          rotateControl: false,
                          fullscreenControl: false,
                          heading: headingInDegrees,
                        }}
                      >
                        {gpsHistory?.deviceLocations ? (
                          <PolyLineDetails
                            coordinates={coordinates}
                            markerRef={markerRef}
                            renderArrowMarker={renderArrowMarker}
                          />
                        ) : (
                          <MarkerF
                            onLoad={(marker) => (markerRef.current = marker)}
                            icon={{
                              url: "/motorcycle.svg",
                              scaledSize: new window.google.maps.Size(40, 40),
                              anchor: new window.google.maps.Point(20, 20),
                              rotation: gpsData?.gpsValues?.course || 0,
                            }}
                            animation={window.google.maps.Animation.DROP}
                            position={currentCoordinate}
                            onClick={() =>
                              handleMarkerClick(gpsData?.gpsValues)
                            }
                          />
                        )}
                      </GoogleMap>
                    </div>
                  )}

                  {tabName === "geofence" && (
                    <div>
                      <GeoFenceMap
                        isLoaded={isLoaded}
                        geofenceMarker={geofenceMarker}
                        setGeofenceCoords={setGeofenceCoords}
                        geofenceCoords={geofenceCoords}
                        gpsData={gpsData}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="pt-10">
                  <LoadingContent />
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Socket Data --------------------------------------- */}
      <div>
        <GPSInfo gpsData={gpsData} updatedTime={updatedTime} />
      </div>
    </div>
  );
};

export default LiveLocation;
