import React, { useContext, useState, useEffect } from "react";
import { BmsContext } from "../../../../App";

const BatterySystem = () => {
  const { statusDataE3ToE6 } = useContext(BmsContext);

  const [settings, setSettings] = useState({
    cellCount: statusDataE3ToE6?.E3?.cellCount || "",
    cellType: statusDataE3ToE6?.E3?.cellType || "",
    packCapacity: statusDataE3ToE6?.E3?.packCapacity || "",
    balanceTrigVolt: statusDataE3ToE6?.E3?.balanceTrigVolt || "",
    balanceSwitch: statusDataE3ToE6?.E3?.balanceSwitch || "",
    balanceDiffVolt: statusDataE3ToE6?.E3?.balanceDiffVolt || "",
    sleepTime: statusDataE3ToE6?.E3?.sleepTime || "",
    batteryIDCode: statusDataE3ToE6?.E3?.batteryIDCode || "",
    batteryAddress: statusDataE3ToE6?.E3?.batteryAddress || "",
    UARTAddress: statusDataE3ToE6?.E3?.UARTAddress || "",
    CANAddress: statusDataE3ToE6?.E3?.CANAddress || "",
    RS485Address: statusDataE3ToE6?.E3?.RS485Address || "",
    manufactureDate: statusDataE3ToE6?.E3?.manufactureDate || "",
  });

  useEffect(() => {
    setSettings({
      cellCount: statusDataE3ToE6?.E3?.cellCount || "",
      cellType: statusDataE3ToE6?.E3?.cellType || "",
      packCapacity: statusDataE3ToE6?.E3?.packCapacity || "",
      balanceTrigVolt: statusDataE3ToE6?.E3?.balanceTrigVolt || "",
      balanceSwitch: statusDataE3ToE6?.E3?.balanceSwitch || "",
      balanceDiffVolt: statusDataE3ToE6?.E3?.balanceDiffVolt || "",
      sleepTime: statusDataE3ToE6?.E3?.sleepTime || "",
      batteryIDCode: statusDataE3ToE6?.E3?.batteryIDCode || "",
      batteryAddress: statusDataE3ToE6?.E3?.batteryAddress || "",
      UARTAddress: statusDataE3ToE6?.E3?.UARTAddress || "",
      CANAddress: statusDataE3ToE6?.E3?.CANAddress || "",
      RS485Address: statusDataE3ToE6?.E3?.RS485Address || "",
      manufactureDate: statusDataE3ToE6?.E3?.manufactureDate || "",
    });
  }, [statusDataE3ToE6]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your submit logic here
    console.log(settings);
  };

  return (
    <div className="overflow-x-auto mt-3 mb-5 px-1">
      <form onSubmit={handleSubmit}>
        <table className="table table-compact w-full">
          <thead className="text-white">
            <tr>
              <th className="bg-primary text-xs md:text-base">Project</th>
              <th className="bg-primary text-xs md:text-base">Machine</th>
              <th className="bg-primary text-xs md:text-base">Setting</th>
            </tr>
          </thead>
          <tbody>
            {/* Row rendering */}
            {Object.keys(settings).map((key, index) => (
              <tr key={index}>
                <td className="w-48 md:w-80">
                  <h3 className="md:text-base font-medium whitespace-normal">
                    {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                  </h3>
                </td>
                <td>
                  <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                    {statusDataE3ToE6?.E3?.[key]}
                  </div>
                </td>
                <td className="w-48 md:w-64">
                  <input
                    type="number"
                    name={key}
                    value={settings[key]}
                    onChange={handleChange}
                    placeholder="Type here"
                    className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-end pr-2">
          <div className="w-40 mt-4">
            <button
              type="submit"
              className="btn btn-primary btn-xs md:btn-sm w-40"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BatterySystem;
