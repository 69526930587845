
import { FaUserFriends } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { MdDashboard } from "react-icons/md";
import { RiDeviceFill } from "react-icons/ri";
import { TbReport } from "react-icons/tb";

const AdminMenuItems = () => {

  // Active route styles
  let activeStyle = {
    color: "#2C4095",
    backgroundColor: "white",
  };


  return (
    <>
      <li className="border-b border-slate-600 text-white font-bold">
        <NavLink
          to="admin-dashboard"
          className="pt-1.5 pb-2 my-1.5 hover:text-primary hover:border-secondary flex items-center gap-2 hover:bg-white rounded px-2 hover:shadow"
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          <MdDashboard />
          Dashboard
        </NavLink>
      </li>
      <li className="border-b border-slate-600 text-white font-bold">
        <NavLink
          to="users"
          className="pt-1.5 pb-2 my-1.5 hover:text-primary hover:border-secondary flex items-center gap-2 hover:bg-white rounded px-2 hover:shadow"
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          <FaUserFriends />
          All Users
        </NavLink>
      </li>
      <li className="border-b border-slate-600 text-white font-bold">
        <NavLink
          to="all-devices"
          className="pt-1.5 pb-2 my-1.5 hover:text-primary hover:border-secondary flex items-center gap-2 hover:bg-white rounded px-2 hover:shadow"
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          <RiDeviceFill />
          All Devices
        </NavLink>
      </li>
      <li className="border-b border-slate-600 text-white font-bold">
        <NavLink
          to="my-report"
          className="pt-1.5 pb-2 my-1.5 hover:text-primary hover:border-secondary flex items-center gap-2 hover:bg-white rounded px-2 hover:shadow"
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          <TbReport />
          My Reports
        </NavLink>
      </li>
    </>
  );
};

export default AdminMenuItems;
