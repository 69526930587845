import React, { useState, useContext } from "react";
import { BmsContext } from "../../../../App";

const TempProtection = () => {
  const { statusData } = useContext(BmsContext);
  const [settings, setSettings] = useState({
    chargeOTP: statusData?.E2?.chargeOTP || "",
    chargeOTPR: statusData?.E2?.chargeOTPR || "",
    dischargeOTP: statusData?.E2?.dischargeOTP || "",
    dischargeOTPR: statusData?.E2?.dischargeOTPR || "",
    chargeUTP: statusData?.E2?.chargeUTP || "",
    chargeUTPR: statusData?.E2?.chargeUTPR || "",
    dischargeUTP: statusData?.E2?.dischargeUTP || "",
    dischargeUTPR: statusData?.E2?.dischargeUTPR || "",
    MOSOTP: statusData?.E2?.MOSOTP || "",
    MOSOTR: statusData?.E2?.MOSOTR || "",
    tempProtect: statusData?.E2?.tempProtect || "",
    tempRecovery: statusData?.E2?.tempRecovery || "",
    tempDiffProtect: statusData?.E2?.tempDiffProtect || "",
  });

  const handleChange = (e) => {
    setSettings({
      ...settings,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:");
  };

  return (
    <div className="overflow-x-auto mt-3 mb-5 px-1">
      <table className="table table-compact w-full">
        <thead className="text-white">
          <tr>
            <th className="bg-primary text-xs md:text-base">Project</th>
            <th className="bg-primary text-xs md:text-base">Machine</th>
            <th className="bg-primary text-xs md:text-base">Setting</th>
          </tr>
        </thead>
        <tbody>
          {/* row 1 --------------------------------------------------*/}
          <tr>
            <td className="w-48 md:w-96">
              <h3 className="md:text-base font-medium whitespace-normal">
                Charge OTP - (°C)
              </h3>
            </td>
            <td>
              <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                {statusData?.E2?.chargeOTP}
              </div>
            </td>
            <td className="w-48 md:w-64">
              <input
                type="number"
                name="chargeOTP"
                value={settings.chargeOTP}
                onChange={handleChange}
                placeholder="Type here"
                className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
              />
            </td>
          </tr>
          {/*------------------------------------------------------- */}

          {/* row 2 --------------------------------------------------*/}
          <tr>
            <td className="w-48 md:w-96">
              <h3 className="md:text-base font-medium whitespace-normal">
                Charge OTPR - (°C)
              </h3>
            </td>
            <td>
              <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                {statusData?.E2?.chargeOTPR}
              </div>
            </td>
            <td className="w-48 md:w-64">
              <input
                type="number"
                name="chargeOTPR"
                value={settings.chargeOTPR}
                onChange={handleChange}
                placeholder="Type here"
                className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
              />
            </td>
          </tr>
          {/*------------------------------------------------------- */}

          {/* row 3 --------------------------------------------------*/}
          <tr>
            <td className="w-48 md:w-96">
              <h3 className="md:text-base font-medium whitespace-normal">
                Discharge OTP - (°C)
              </h3>
            </td>
            <td>
              <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                {statusData?.E2?.dischargeOTP}
              </div>
            </td>
            <td className="w-48 md:w-64">
              <input
                type="number"
                name="dischargeOTP"
                value={settings.dischargeOTP}
                onChange={handleChange}
                placeholder="Type here"
                className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
              />
            </td>
          </tr>
          {/*------------------------------------------------------- */}

          {/* row 4 --------------------------------------------------*/}
          <tr>
            <td className="w-48 md:w-96">
              <h3 className="md:text-base font-medium whitespace-normal">
                Discharge OTPR - (°C)
              </h3>
            </td>
            <td>
              <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                {statusData?.E2?.dischargeOTPR}
              </div>
            </td>
            <td className="w-48 md:w-64">
              <input
                type="number"
                name="dischargeOTPR"
                value={settings.dischargeOTPR}
                onChange={handleChange}
                placeholder="Type here"
                className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
              />
            </td>
          </tr>
          {/*------------------------------------------------------- */}

          {/* row 5 --------------------------------------------------*/}
          <tr>
            <td className="w-48 md:w-96">
              <h3 className="md:text-base font-medium whitespace-normal">
                Charge UTP - (°C)
              </h3>
            </td>
            <td>
              <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                {statusData?.E2?.chargeUTP}
              </div>
            </td>
            <td className="w-48 md:w-64">
              <input
                type="number"
                name="chargeUTP"
                value={settings.chargeUTP}
                onChange={handleChange}
                placeholder="Type here"
                className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
              />
            </td>
          </tr>
          {/*------------------------------------------------------- */}

          {/* row 6 --------------------------------------------------*/}
          <tr>
            <td className="w-48 md:w-96">
              <h3 className="md:text-base font-medium whitespace-normal">
                Charge UTPR - (°C)
              </h3>
            </td>
            <td>
              <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                {statusData?.E2?.chargeUTPR}
              </div>
            </td>
            <td className="w-48 md:w-64">
              <input
                type="number"
                name="chargeUTPR"
                value={settings.chargeUTPR}
                onChange={handleChange}
                placeholder="Type here"
                className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
              />
            </td>
          </tr>
          {/*------------------------------------------------------- */}

          {/* row 7 --------------------------------------------------*/}
          <tr>
            <td className="w-48 md:w-96">
              <h3 className="md:text-base font-medium whitespace-normal">
                Discharge UTP - (°C)
              </h3>
            </td>
            <td>
              <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                {statusData?.E2?.dischargeUTP}
              </div>
            </td>
            <td className="w-48 md:w-64">
              <input
                type="number"
                name="dischargeUTP"
                value={settings.dischargeUTP}
                onChange={handleChange}
                placeholder="Type here"
                className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
              />
            </td>
          </tr>
          {/*------------------------------------------------------- */}

          {/* row 8 --------------------------------------------------*/}
          <tr>
            <td className="w-48 md:w-96">
              <h3 className="md:text-base font-medium whitespace-normal">
                Discharge UTPR - (°C)
              </h3>
            </td>
            <td>
              <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                {statusData?.E2?.dischargeUTPR}
              </div>
            </td>
            <td className="w-48 md:w-64">
              <input
                type="number"
                name="dischargeUTPR"
                value={settings.dischargeUTPR}
                onChange={handleChange}
                placeholder="Type here"
                className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
              />
            </td>
          </tr>
          {/*------------------------------------------------------- */}

          {/* row 9 --------------------------------------------------*/}
          <tr>
            <td className="w-48 md:w-96">
              <h3 className="md:text-base font-medium whitespace-normal">
                MOS OTP - (°C)
              </h3>
            </td>
            <td>
              <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                {statusData?.E2?.MOSOTP}
              </div>
            </td>
            <td className="w-48 md:w-64">
              <input
                type="number"
                name="MOSOTP"
                value={settings.MOSOTP}
                onChange={handleChange}
                placeholder="Type here"
                className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
              />
            </td>
          </tr>
          {/*------------------------------------------------------- */}

          {/* row 10 ------------------------------------------------*/}
          <tr>
            <td className="w-48 md:w-96">
              <h3 className="md:text-base font-medium whitespace-normal">
                MOS OTR - (°C)
              </h3>
            </td>
            <td>
              <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                {statusData?.E2?.MOSOTR}
              </div>
            </td>
            <td className="w-48 md:w-64">
              <input
                type="number"
                name="MOSOTR"
                value={settings.MOSOTR}
                onChange={handleChange}
                placeholder="Type here"
                className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
              />
            </td>
          </tr>
          {/*------------------------------------------------------- */}

          {/* row 11 ------------------------------------------------*/}
          <tr>
            <td className="w-48 md:w-96">
              <h3 className="md:text-base font-medium whitespace-normal">
                Temp Protect
              </h3>
            </td>
            <td>
              <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                {statusData?.E2?.tempProtect}
              </div>
            </td>
            <td className="w-48 md:w-64">
              <input
                type="number"
                name="tempProtect"
                value={settings.tempProtect}
                onChange={handleChange}
                placeholder="Type here"
                className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
              />
            </td>
          </tr>
          {/* row 12 ------------------------------------------------*/}
          <tr>
            <td className="w-48 md:w-96">
              <h3 className="md:text-base font-medium whitespace-normal">
                Temp recovery
              </h3>
            </td>
            <td>
              <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                {statusData?.E2?.tempRecovery}
              </div>
            </td>
            <td className="w-48 md:w-64">
              <input
                type="number"
                name="tempRecovery"
                value={settings.tempRecovery}
                onChange={handleChange}
                placeholder="Type here"
                className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
              />
            </td>
          </tr>
          {/* row 13 ------------------------------------------------*/}
          <tr>
            <td className="w-48 md:w-96">
              <h3 className="md:text-base font-medium whitespace-normal">
                Temp diff Protect
              </h3>
            </td>
            <td>
              <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                {statusData?.E2?.tempDiffProtect}
              </div>
            </td>
            <td className="w-48 md:w-64">
              <input
                type="number"
                name="tempDiffProtect"
                value={settings.tempDiffProtect}
                onChange={handleChange}
                placeholder="Type here"
                className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
              />
            </td>
          </tr>
          {/*------------------------------------------------------- */}
        </tbody>
      </table>
      <div className="flex justify-end pr-2">
        <div className="w-40 mt-4">
          <button
            type="submit"
            className="btn btn-primary btn-xs md:btn-sm w-40"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default TempProtection;
