import React, { useContext, useState } from "react";
import { BmsContext } from "../../../../App";

const ActiveBalance = () => {
  const { statusData } = useContext(BmsContext);

  const initialFormData = {
    batteryOVP: statusData?.E0?.batteryOVP || "",
    batteryUVP: statusData?.E0?.batteryUVP || "",
    cellOVP: statusData?.E0?.cellOVP || "",
    cellOVR: statusData?.E0?.cellOVR || "",
    cellOVRT: statusData?.E0?.cellOVRT || "",
    cellUVP: statusData?.E0?.cellUVP || "",
    cellUVR: statusData?.E0?.cellUVR || "",
    cellUVRT: statusData?.E0?.cellUVRT || "",
    coreDiffPressure: statusData?.E0?.coreDiffPressure || "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
  };

  return (
    <div className="overflow-x-auto mt-3 mb-5 px-1">
      <form onSubmit={handleSubmit}>
        <table className="table table-compact w-full">
          <thead className="text-white">
            <tr>
              <th className="bg-primary text-xs md:text-base">Project</th>
              <th className="bg-primary text-xs md:text-base">Machine</th>
              <th className="bg-primary text-xs md:text-base">Setting</th>
            </tr>
          </thead>
          <tbody>
            {/* row 1 */}
            <tr>
              <td className="w-48 md:w-80">
                <h3 className="md:text-base font-medium whitespace-normal">
                  Battery OVP - (V)
                </h3>
              </td>
              <td>
                <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                  {statusData?.E0?.batteryOVP}
                </div>
              </td>
              <td className="w-48 md:w-64">
                <input
                  type="number"
                  defaultValue={statusData?.E0?.batteryOVP}
                  name="batteryOVP"
                  placeholder="Type here"
                  className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
                  onChange={handleChange}
                />
              </td>
            </tr>
            {/* row 2 */}
            <tr>
              <td className="w-48 md:w-80">
                <h3 className="md:text-base font-medium whitespace-normal">
                  Battery UVP - (V)
                </h3>
              </td>
              <td>
                <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                  {statusData?.E0?.batteryUVP}
                </div>
              </td>
              <td className="w-48 md:w-64">
                <input
                  type="number"
                  defaultValue={statusData?.E0?.batteryUVP}
                  name="batteryUVP"
                  placeholder="Type here"
                  className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
                  onChange={handleChange}
                />
              </td>
            </tr>
            {/* row 3 */}
            <tr>
              <td className="w-48 md:w-80">
                <h3 className="md:text-base font-medium whitespace-normal">
                  Cell OVP - (V)
                </h3>
              </td>
              <td>
                <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                  {statusData?.E0?.cellOVP}
                </div>
              </td>
              <td className="w-48 md:w-64">
                <input
                  type="number"
                  defaultValue={statusData?.E0?.cellOVP}
                  name="cellOVP"
                  placeholder="Type here"
                  className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
                  onChange={handleChange}
                />
              </td>
            </tr>
            {/* row 4 */}
            <tr>
              <td className="w-48 md:w-80">
                <h3 className="md:text-base font-medium whitespace-normal">
                  Cell OVR - (V)
                </h3>
              </td>
              <td>
                <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                  {statusData?.E0?.cellOVR}
                </div>
              </td>
              <td className="w-48 md:w-64">
                <input
                  type="number"
                  defaultValue={statusData?.E0?.cellOVR}
                  name="cellOVR"
                  placeholder="Type here"
                  className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
                  onChange={handleChange}
                />
              </td>
            </tr>
            {/* row 5 */}
            <tr>
              <td className="w-48 md:w-80">
                <h3 className="md:text-base font-medium whitespace-normal">
                  Cell OVRT - (sec)
                </h3>
              </td>
              <td>
                <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                  {statusData?.E0?.cellOVRT}
                </div>
              </td>
              <td className="w-48 md:w-64">
                <input
                  type="number"
                  defaultValue={statusData?.E0?.cellOVRT}
                  name="cellOVRT"
                  placeholder="Type here"
                  className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
                  onChange={handleChange}
                />
              </td>
            </tr>
            {/* row 6 */}
            <tr>
              <td className="w-48 md:w-80">
                <h3 className="md:text-base font-medium whitespace-normal">
                  Cell UVP - (V)
                </h3>
              </td>
              <td>
                <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                  {statusData?.E0?.cellUVP}
                </div>
              </td>
              <td className="w-48 md:w-64">
                <input
                  type="number"
                  defaultValue={statusData?.E0?.cellUVP}
                  name="cellUVP"
                  placeholder="Type here"
                  className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
                  onChange={handleChange}
                />
              </td>
            </tr>
            {/* row 7 */}
            <tr>
              <td className="w-48 md:w-80">
                <h3 className="md:text-base font-medium whitespace-normal">
                  Cell UVR - (V)
                </h3>
              </td>
              <td>
                <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                  {statusData?.E0?.cellUVR}
                </div>
              </td>
              <td className="w-48 md:w-64">
                <input
                  type="number"
                  defaultValue={statusData?.E0?.cellUVR}
                  name="cellUVR"
                  placeholder="Type here"
                  className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
                  onChange={handleChange}
                />
              </td>
            </tr>
            {/* row 8 */}
            <tr>
              <td className="w-48 md:w-80">
                <h3 className="md:text-base font-medium whitespace-normal">
                  Cell UVRT - (sec)
                </h3>
              </td>
              <td>
                <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                  {statusData?.E0?.cellUVRT}
                </div>
              </td>
              <td className="w-48 md:w-64">
                <input
                  type="number"
                  defaultValue={statusData?.E0?.cellUVRT}
                  name="cellUVRT"
                  placeholder="Type here"
                  className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
                  onChange={handleChange}
                />
              </td>
            </tr>
            {/* row 9 */}
            <tr>
              <td className="w-48 md:w-80">
                <h3 className="md:text-base font-medium whitespace-normal">
                  Core Diff Pressure
                </h3>
              </td>
              <td>
                <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                  {statusData?.E0?.coreDiffPressure}
                </div>
              </td>
              <td className="w-48 md:w-64">
                <input
                  type="number"
                  defaultValue={statusData?.E0?.coreDiffPressure}
                  name="coreDiffPressure"
                  placeholder="Type here"
                  className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
                  onChange={handleChange}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="flex justify-end pr-2">
          <div className="w-40 mt-4">
            <button
              type="submit"
              className="btn btn-primary btn-xs md:btn-sm w-40"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ActiveBalance;
