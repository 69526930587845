import React, { useContext, useEffect, useState } from "react";
import Notify from "../Shared/Notify/Notify";
import ProfileAvatar from "../Shared/ProfileAvatar/ProfileAvatar";
import { MdDashboard } from "react-icons/md";
import { BmsContext } from "../../App";
import useDevicesByPhone from "../../hooks/useDevicesByPhone";
import axios from "axios";
import { toast } from "react-toastify";
import LoadingContent from "../Shared/LoadingContent";
import { useLocation } from "react-router-dom";

const DashboardNavbar = () => {
  const { sidebarContext, userInfo } = useContext(BmsContext);
  const { sidebarHide, setSidebarHide } = sidebarContext;
  const [userDeviceList, isLoading, refetch] = useDevicesByPhone(
    userInfo?.phone
  );

  const [isDeviceLoading, setIsDeviceLoading] = useState(false);
  const { pathname } = useLocation();
  const [isBMSPage, setIsBMSPage] = useState(false);

  useEffect(() => {
    if (pathname === "/dashboard/battery-bms") {
      setIsBMSPage(true);
    } else {
      setIsBMSPage(false);
    }
  }, [pathname]);

  const handleSelectedDevice = (e) => {
    const deviceId = e.target.value;
    setIsDeviceLoading(true);
    const deviceSelected = true;

    // console.log(deviceId);

    (async () => {
      const payload = { deviceSelected };
      const res = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/device/device-switch/${deviceId}?phone=${userInfo?.phone}`,
        payload
      );
      if (res.data.success) {
        toast.success("Device switched!", { position: "top-left" });
        refetch();
      }
      setIsDeviceLoading(false);
    })();

    (async () => {
      await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}api/data?topic=update/${deviceId}`
      );
    })();

    // window.location.reload();
  };

  if (isDeviceLoading) {
    return <LoadingContent />;
  }

  return (
    <nav className="bg-base-10 border-b sticky top-0 bg-gray-100 z-40">
      <div className="navbar mx-auto flex-col md:flex-row px-4">
        {/* navbar left start */}
        {/* Menu for Dashboard in mobile  */}
        <div className="navbar-start w-full md:w-[50%] justify-between md:justify-start">
          <div className="dropdown">
            <label
              htmlFor="my-drawer-2"
              className="btn btn-primary drawer-button lg:hidden"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </label>
          </div>

          <div className="hidden md:flex items-center">
            {sidebarHide && (
              <label
                onClick={() => setSidebarHide(!sidebarHide)}
                className="btn btn-primary drawer-button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h8m-8 6h16"
                  />
                </svg>
              </label>
            )}
            {sidebarHide === false && (
              <MdDashboard size={30} className="text-primary" />
            )}
            <h2 className="text-2xl font-bold text-primary ml-3">Dashboard</h2>
          </div>

          {/* Profile Icon  */}
          <div className="md:hidden flex justify-end items-center gap-3 md:6">
            {/* Swtich Device */}
            {/* {isBMSPage && (
              
            )} */}
            {userDeviceList?.length > 0 && (
              <select
                onChange={(e) => {
                  handleSelectedDevice(e);
                }}
                className="select select-bordered w-min max-w-xs"
              >
                {userDeviceList?.map((device, index) => {
                  let number = device?.deviceId;
                  let maskedNumber = "***" + number.slice(-5);
                  return (
                    <option
                      key={index}
                      value={device.deviceId} // Add a value attribute with a simple string value
                      selected={device.deviceSelected ? "selected" : undefined} // Set the selected attribute conditionally
                    >
                      {device.deviceName + " " + maskedNumber}
                    </option>
                  );
                })}
              </select>
            )}

            <Notify />
            <ProfileAvatar />
          </div>
        </div>
        {/* navbar left end */}

        {/* navbar center start  */}
        <div className="navbar-center hidden lg:flex"></div>
        {/* navbar center end  */}

        {/* navbar right start */}
        <div className="navbar-end hidden md:flex justify-end justify-items-center items-center gap-8">
          {/* Swtich Device  */}
          {/* {isBMSPage && (
            
          )} */}
          {userDeviceList?.length > 0 && (
            <select
              onChange={(e) => {
                handleSelectedDevice(e);
              }}
              className="select select-bordered w-min max-w-xs mb-1"
            >
              {userDeviceList?.map((device, index) => {
                let number = device?.deviceId;
                let maskedNumber = "***" + number.slice(-5);
                return (
                  <option
                    key={index}
                    value={device.deviceId} // Add a value attribute with a simple string value
                    selected={device.deviceSelected ? "selected" : undefined} // Set the selected attribute conditionally
                  >
                    {device.deviceName + " " + maskedNumber}
                  </option>
                );
              })}
            </select>
          )}
          {/* notification  */}
          <Notify />

          {/* Profile Icon  */}
          <ProfileAvatar />
        </div>
        {/* navbar right end */}
      </div>
    </nav>
  );
};

export default DashboardNavbar;
