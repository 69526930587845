import React from "react";
import LoadingContent from "../../../components/Shared/LoadingContent";
import useDevicesByPhone from "../../../hooks/useDevicesByPhone";
import DeviceCard from "./DeviceCard/DeviceCard";
import useUserProfile from "../../../hooks/useUserProfile";

const Devices = () => {
  const { userInfo } = useUserProfile();
  const [userDeviceList, isLoading, refetch] = useDevicesByPhone(
    userInfo?.phone
  );

  // const url = `${process.env.REACT_APP_API_BASE_URL}api/v1/bms/user-detail?email=${userInfo?.phone}`;

  // const { data: userDetail, isLoading: isAPILoading } = useQuery(
  //   "userDevices",
  //   () =>
  //     fetch(url, {
  //       method: "GET",
  //     }).then((res) => res.json())
  // );

  // if (isUserLoading || isAPILoading) {
  //   return <Loading />;
  // }

  // const { permissions } = userDetail?.data;
  // console.log(permissions);

  return (
    <div className="px-4 my-5">
      <div className="grid grid-cols-1 md:grid-cols-3 my-5 gap-4">
        {/* Devices left start -------------------------------------------------------------- */}
        <div className="md:col-span-2 border rounded-md p-3 shadow order-2 md:order-1">
          <h3 className="text-lg font-medium border-b text-center pb-1 text-primary mb-4">
            Device List
          </h3>
          {isLoading && (
            <div className="mt-16">
              <LoadingContent />
            </div>
          )}
          {/* Device List section ---------------------------------- */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Device ------------------------------ */}
            {userDeviceList?.map((device, index) => (
              <DeviceCard device={device} key={index} refetch={refetch} />
            ))}
            {/* ------------------------------------- */}
          </div>
          {/* ------------------------------------------------------------------------------- */}
        </div>
        {/* Devices left end  */}

        {/* Deves Right start ----------------------------------------------------------------- */}
        <div className="border rounded-md shadow p-3 order-1 md:order-2">
          {/* {permissions?.isDeviceAdd ? (
            <AddDevice refetch={refetch} />
          ) : (
            <div className="flex flex-col items-center border p-3 shadow rounded-md">
              <p className="text-6xl">&#128577;</p>
              <p className="mt-2 text-lg">Sorry! you don't have permission.</p>
            </div>
          )} */}
        </div>
        {/* Devices Right end ----------------------------------------------------------------- */}
      </div>
    </div>
  );
};

export default React.memo(Devices);
