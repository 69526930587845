import React, { useEffect, useState } from "react";
import BatteryGauge from "react-battery-gauge";
import "react-circular-progressbar/dist/styles.css";
import { GiElectric } from "react-icons/gi";
import { HiOutlineClock } from "react-icons/hi";
import { MdOutlineUpdate } from "react-icons/md";
import { RiBatteryShareLine } from "react-icons/ri";
import {
  TbBatteryCharging as TbDischarging,
  TbBatteryCharging2,
} from "react-icons/tb";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import BmsDashboardSkeleton from "../../../components/Skeleton/BmsDashboardSkeleton";

const BatteryStatus = ({ socketData, lastUpdatedData, lastUpdatedTime }) => {
  const [timeCurrentTime, setCurrentTime] = useState("");

  console.log("socketData", socketData);

  // from Socket data
  const F0 = socketData?.F0;
  const F1 = socketData?.F1;
  const F2 = socketData?.F2;

  // from API Data
  const F0API = lastUpdatedData?.bmsData?.F0;
  const F1API = lastUpdatedData?.bmsData?.F1;
  const F2API = lastUpdatedData?.bmsData?.F2;

  // console.log(F0API);

  // Parameters value
  const batteryPower = Number(F0?.volt) * Number(F0?.amp);
  const avgCellVolt = (Number(F0?.volt) / Number(F1?.batteryStringNo)).toFixed(
    2
  );
  // Parameters value from API
  const batteryPowerAPI = Number(F0API?.volt) * Number(F0API?.amp);
  const avgCellVoltAPI = (
    Number(F0API?.volt) / Number(F1API?.batteryStringNo)
  ).toFixed(2);

  // cycleCount from Socket
  const cycleCount = F1?.bmsLife;
  // cycle count from API
  const cycleCountAPI = F1API?.bmsLife;

  // Battery Charging Discharging and Balance Status
  const isCharging = Number(F1?.chargeMosState);
  const isDisCharging = Number(F1?.disChargeMosState);
  const isBalance = Number(F1?.cellBalanceState);

  // Current time getting
  useEffect(() => {
    const intervalId = setInterval(() => {
      let time = new Date().toLocaleTimeString("en-US");
      setCurrentTime(`${time}`);
    }, 1000);

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, []); // Empty dependency array to run effect only once on mount

  // Graph Data
  const data = [
    {
      time: "16:20",
      voltage: 3.8,
      current: 2.4,
    },
    {
      time: "16:20",
      voltage: 3.7,
      current: 2.3,
    },
    {
      time: "16:20",
      voltage: 3.4,
      current: 2.4,
    },
    {
      time: "16:20",
      voltage: 3.2,
      current: 2.1,
    },
    {
      time: "16:20",
      voltage: 3.9,
      current: 2.4,
    },
    {
      time: "16:20",
      voltage: 3.4,
      current: 2.5,
    },
    {
      time: "16:20",
      voltage: 3.8,
      current: 0,
    },
    {
      time: "16:20",
      voltage: 3.2,
      current: -1,
    },
    {
      time: "16:20",
      voltage: 3.8,
      current: 2.4,
    },
    {
      time: "16:20",
      voltage: 3.7,
      current: 2.4,
    },
    {
      time: "16:20",
      voltage: 3.8,
      current: 2.2,
    },
  ];

  if (!socketData) {
    return <BmsDashboardSkeleton />;
  }

  const mongoTime = new Date(lastUpdatedData?.createdAt);
  let lastUpdatedTimeFromAPI = mongoTime.toLocaleString();

  const socGauge = F0?.soc ? F0?.soc : F0API?.soc;

  return (
    <>
      {/* Battery indecator start  */}
      <div className="mb-3">
        <div className="flex flex-wrap md:flex-nowrap justify-center md:justify-between gap-2 shadow-lg rounded-lg py-1 px-2.5 bg-gradient-to-r from-[#243351] to-[#0773AF]">
          <div className="py-1 rounded flex justify-center items-center text-white">
            <HiOutlineClock size="35" className="rounded-full p-1" />
            <div className="flex items-center gap-1.5">
              <p className="text-sm">Time:</p>
              <span className="font-bold text-sm">{timeCurrentTime}</span>
            </div>
          </div>

          <div className="py-1 rounded flex justify-center items-center text-white">
            <MdOutlineUpdate size="35" className="rounded-full p-1" />
            <div className="flex items-center gap-1.5">
              <p className="text-sm">LUT:</p>
              <span className="font-bold text-sm">
                {lastUpdatedTime ? lastUpdatedTime : lastUpdatedTimeFromAPI}
              </span>
            </div>
          </div>

          <div className="py-1 rounded flex justify-center items-center text-white">
            <TbBatteryCharging2 size="35" className="rounded-full p-1" />
            <div className="flex items-center gap-1.5">
              <p className="text-sm">Charge:</p>
              <span className="font-bold text-sm">
                {isCharging ? "ON" : "OFF"}
              </span>
            </div>
          </div>

          <div className="py-1 rounded flex justify-center items-center text-white">
            <TbDischarging size="35" className="rounded-full p-1" />
            <div className="flex items-center gap-1.5">
              <p className="text-sm">Discharge:</p>
              <span className="font-bold text-sm">
                {isDisCharging ? "ON" : "OFF"}
              </span>
            </div>
          </div>

          <div className="py-1 rounded flex justify-center items-center text-white">
            <RiBatteryShareLine size="35" className="rounded-full p-1" />
            <div className="flex items-center gap-1.5">
              <p className="text-sm">Balance:</p>
              <span className="font-bold text-sm">
                {isBalance ? "ON" : "OFF"}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* Battery indecator end  */}

      <div className="grid grid-cols-1 md:grid-cols-12 md:gap-3">
        {/* Sum Voltage start =================================            */}
        <div className="col-span-4 h-40">
          <div className="h-full pb-8">
            <h3 className="text-md font-medium text-accent mb-1">
              Sum Voltage
            </h3>
            <div
              className="text-dark rounded-md flex justify-center items-center shadow-lg p-2.5 md:p-0 gap-2 h-full"
              style={{ backgroundColor: "rgba(13, 127, 191, 0.15)" }}
            >
              <span className="border-2 border-accent shadow-lg p-1 text-4xl md:4xl rounded-full text-accent w-[60px] h-[60px] flex justify-center items-center font-bold">
                V
              </span>
              <h3 className="font-bold text-lg md:text-3xl">
                {F0?.volt ? F0?.volt : F0API?.volt} V
              </h3>
            </div>
          </div>
        </div>
        {/* Sum Voltage end ======================================== */}

        {/* Current section start  */}
        <div className="col-span-4 h-40">
          <div className="h-full pb-8">
            <h3 className="text-md font-medium text-primary mb-1">Current</h3>
            <div
              className="text-dark rounded-md flex justify-center items-center shadow-lg p-2.5 md:p-0 gap-2 h-full"
              style={{ backgroundColor: "rgba(44, 64, 149, 0.1) " }}
            >
              <GiElectric
                size={60}
                className="border-2 border-primary shadow-lg p-1 text-3xl md:4xl rounded-full text-primary"
              />
              <h3 className="font-bold text-lg md:text-3xl">
                {F0?.amp ? F0?.amp : F0API?.amp} A
              </h3>
            </div>
          </div>
        </div>
        {/* Current section end  */}

        {/* Battery Status SOC start ====================================  */}
        <div className="col-span-4 h-40">
          <div className="h-full pb-8">
            <h3 className="text-md font-medium text-secondary mb-1">SOC</h3>
            <div
              className="text-accent rounded-md flex justify-center items-center shadow-lg p-2.5 md:p-0 gap-2 h-full"
              style={{ backgroundColor: "rgba(56, 146, 210, 0.1)" }}
            >
              <div className="">
                <BatteryGauge
                  key={1}
                  size={120}
                  value={socGauge}
                  // charging={isCharging}
                  animated={false}
                  customization={{
                    batteryBody: {
                      strokeWidth: 1,
                      cornerRadius: 6,
                      fill: "none",
                      strokeColor: "#0773AF",
                    },
                    batteryCap: {
                      fill: "#0773AF",
                      strokeWidth: 2,
                      strokeColor: "#0773AF",
                      cornerRadius: 2,
                      capToBodyRatio: 0.4,
                    },
                    batteryMeter: {
                      fill: "#0773AF",
                      lowBatteryValue: 20,
                      lowBatteryFill: "red",
                      outerGap: 1,
                      noOfCells: 10, // more than 1, will create cell battery
                      interCellsGap: 1,
                    },
                    readingText: {
                      lightContrastColor: "#111",
                      darkContrastColor: "#fff",
                      lowBatteryColor: "red",
                      fontFamily: "Helvetica",
                      fontSize: 24,
                      showPercentage: true,
                    },
                    chargingFlash: {
                      scale: undefined,
                      fill: "orange",
                      animated: true,
                      animationDuration: 1000,
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Battery Status SOC end ======================================  */}
      </div>

      {/* parameter and graph status start  */}
      <div className="grid grid-cols-1 md:grid-cols-12 gap-3 mt-2">
        <div className="col-span-12 md:col-span-7">
          <h3 className="text-md font-medium text-primary mb-1">Parameters</h3>
          <div className="rounded-lg shadow-lg bg-white text-sm text-center p-3 min-h-40 md:h-72 xl-h-72 flex items-center">
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2 w-full">
              <div className="px-1.5 text-center border border-primary rounded pb-1.5 pt-1 bg-blue-50 text-primary">
                <span>Max Volt:</span>{" "}
                <span className="text-lg font-medium">
                  {F2?.maxCellVoltage
                    ? F2?.maxCellVoltage
                    : F2API?.maxCellVoltage}{" "}
                  V
                </span>
              </div>

              <div className="px-1.5 text-center border border-primary rounded pb-1.5 pt-1 bg-blue-50 text-primary">
                <span>Min Volt:</span>{" "}
                <span className="text-lg font-medium">
                  {F2?.minCellVoltage
                    ? F2?.minCellVoltage
                    : F2API?.minCellVoltage}{" "}
                  V
                </span>
              </div>

              <div className="px-1.5 text-center border border-primary rounded pb-1.5 pt-1 bg-blue-50 text-primary">
                <span>Avg. Cell Volt:</span>{" "}
                <span className="text-lg font-medium">
                  {avgCellVolt ? avgCellVolt : avgCellVoltAPI} V
                </span>
              </div>

              <div className="px-1.5 text-center border border-accent rounded pb-1.5 pt-1 bg-red-50 text-accent">
                <span>Max Temp:</span>{" "}
                <span className="text-lg font-medium">
                  {F2?.maxTempValue ? F2?.maxTempValue : F2API?.maxTempValue} °C
                </span>
              </div>

              <div className="px-1.5 text-center border border-accent rounded pb-1.5 pt-1 bg-red-50 text-accent">
                <span>Min Temp:</span>{" "}
                <span className="text-lg font-medium">
                  {F2?.minTempValue ? F2?.minTempValue : F2API?.minTempValue} °C
                </span>
              </div>

              <div className="px-1.5 text-center border border-accent rounded pb-1.5 pt-1 bg-red-50 text-accent">
                <span>MOS Temp:</span>{" "}
                <span className="text-lg font-medium">-- °C</span>
              </div>

              <div className="px-1.5 text-center border border-secondary rounded pb-1.5 pt-1 bg-green-50 text-secondary">
                <span>Battery Power:</span>{" "}
                <span className="text-lg font-medium">
                  {batteryPower.toFixed(1)
                    ? batteryPower.toFixed(1)
                    : batteryPowerAPI.toFixed(1)}{" "}
                  W
                </span>
              </div>

              <div className="px-1.5 text-center border border-secondary rounded pb-1.5 pt-1 bg-green-50 text-secondary">
                <span>Battery Capacity:</span>{" "}
                <span className="text-lg font-medium">
                  {F0?.soc ? F0?.soc : F0API?.soc}
                </span>
              </div>

              <div className="px-1.5 text-center border border-secondary rounded pb-1.5 pt-1 bg-green-50 text-secondary">
                <span>Remain Capacity:</span>{" "}
                <span className="text-lg font-medium">
                  {F1?.remainCapacity
                    ? F1?.remainCapacity
                    : F1API?.remainCapacity}
                  AH
                </span>
              </div>

              <div className="px-1.5 text-center border border-primary rounded pb-1.5 pt-1 bg-blue-50 text-primary">
                <span>Cycle Count:</span>{" "}
                <span className="text-lg font-medium">
                  {cycleCount ? cycleCount : cycleCountAPI}
                </span>
              </div>

              {/* <div className="px-1.5 text-center border border-primary rounded pb-1.5 pt-1 bg-blue-50 text-primary">
                <span>BMS:</span>{" "}
                <span className="text-lg font-medium">
                  {F1?.bmsLife ? F1?.bmsLife : F1API?.bmsLife}
                </span>
              </div> */}

              <div className="px-1.5 text-center border border-primary rounded pb-1.5 pt-1 bg-blue-50 text-primary">
                <span>Total (OVP) volt:</span>{" "}
                <span className="text-lg font-medium">{}</span>
              </div>

              <div className="px-1.5 text-center border border-accent rounded pb-1.5 pt-1 bg-red-50 text-accent">
                <span>Total (UVP) volt:</span>{" "}
                <span className="text-lg font-medium">{}</span>
              </div>

              <div className="px-1.5 text-center border border-accent rounded pb-1.5 pt-1 bg-red-50 text-accent">
                <span>Balance trig. Volt:</span>{" "}
                <span className="text-lg font-medium">{}</span>
              </div>

              <div className="px-1.5 text-center border border-accent rounded pb-1.5 pt-1 bg-red-50 text-accent">
                <span>Start balance volt:</span>{" "}
                <span className="text-lg font-medium">{}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-12 md:col-span-5 ">
          <h3 className="text-md font-medium text-accent mb-1">
            Current vs Voltage
          </h3>
          {/* Dashboard Chart start  */}
          <div className="w-full min-h-40 md:h-72 xl-h-72 pt-1.5 bg-white rounded-lg shadow-lg">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                key={1}
                data={data}
                margin={{
                  top: 10,
                  right: 0,
                  left: 0,
                  bottom: 10,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" />
                <YAxis yAxisId="left" />
                <YAxis yAxisId="right" orientation="right" />
                <Tooltip />
                <Legend />
                <Line
                  yAxisId="left"
                  type="monotone"
                  dataKey="voltage"
                  stroke="#2C4095"
                  activeDot={{ r: 8 }}
                />
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey="current"
                  stroke="#FA6164"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
          {/* Dashboard Chart end  */}
        </div>
      </div>
      {/* parameter and graph status end  */}
    </>
  );
};

export default BatteryStatus;
