import React, { useContext } from "react";
import { CgProfile } from "react-icons/cg";
import { HiOutlineMail } from "react-icons/hi";
import { FiSmartphone } from "react-icons/fi";
import {
  AiOutlineArrowRight,
  AiOutlineDatabase,
  AiOutlineUser,
} from "react-icons/ai";
import { TbPillOff } from "react-icons/tb";
import { RiBattery2ChargeLine } from "react-icons/ri";
import { GiInfo } from "react-icons/gi";
import { MdExitToApp } from "react-icons/md";
import { Link, Outlet } from "react-router-dom";
import { BmsContext } from "../../../App";
import useDevicesByPhone from "../../../hooks/useDevicesByPhone";
import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import CurrentDeviceCard from "../Devices/DeviceCard/CurrentDeviceCard";
import LoadingContent from "../../../components/Shared/LoadingContent";

const Profile = () => {
  const { userInfo } = useContext(BmsContext);
  const [userDeviceList, isLoading, refetch] = useDevicesByPhone(
    userInfo?.phone
  );
  const [isDeviceLoading, setIsDeviceLoading] = useState(false);
  // const [currentDevice, setCurrentDevice] = useState({});

  const handleSelectedDevice = (e) => {
    const deviceId = e.target.value;
    setIsDeviceLoading(true);
    const deviceSelected = true;

    (async () => {
      const payload = { deviceSelected };
      const res = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/device/device-switch/${deviceId}?phone=${userInfo?.phone}`,
        payload
      );
      if (res.data.success) {
        toast.success("Device switched!", { position: "top-left" });
        refetch();
      }
      setIsDeviceLoading(false);
    })();
  };

  // console.log(userDeviceList);

  const currentDevice = userDeviceList?.find(
    (device) => device.deviceSelected === true
  );

  if (isLoading) {
    return <LoadingContent />;
  }

  return (
    <>
      <div className="px-4 my-5">
        <h3 className="font-bold text-lg">Hello! {userInfo?.fullName}</h3>

        <div className="grid grid-cols-1 md:grid-cols-3 my-5 gap-4">
          {/* Profile left start  */}
          <div className="border rounded-md shadow flex flex-col lg:flex-row gap-2">
            <div className="flex flex-col gap-3 justify-center w-full">
              {/* left top section ------------------ */}
              <div className="flex gap-4 border-b pb-4 shadow-sm p-4">
                <div className="avatar flex justify-center">
                  <div className="w-24 rounded-full border-4 border-primary">
                    <div className="h-full flex justify-center items-center">
                      <AiOutlineUser size={50} className="text-primary" />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="flex items-center gap-1 border-b pb-1">
                    <CgProfile
                      size="25"
                      className="text-primary border p-0.5 rounded-full shadow"
                    />{" "}
                    <p className="text-sm md:text-lg">
                      {userInfo?.fullName ? userInfo?.fullName : "null!"}
                    </p>
                  </div>
                  <div className="flex items-center gap-1 border-b pb-1">
                    <FiSmartphone
                      size="25"
                      className="text-primary border p-0.5 rounded-full shadow"
                    />{" "}
                    <p className="text-sm md:text-lg">
                      {userInfo?.phone ? userInfo?.phone : "null!"}
                    </p>
                  </div>
                  <div className="flex items-center gap-1 border-b pb-1">
                    <HiOutlineMail
                      size="25"
                      className="text-primary border p-0.5 rounded-full shadow"
                    />{" "}
                    <p className="text-sm md:text-lg">{userInfo?.email}</p>
                  </div>
                </div>
              </div>
              {/* ------------------------------- */}

              <div className="p-4">
                <div className="flex flex-col">
                  <Link to={""}>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <CgProfile
                          size={25}
                          className="text-primary border p-0.5 rounded-full shadow"
                        />
                        <h3 className="ml-2 text-sm md:text-lg">
                          Perfect data
                        </h3>
                      </div>
                      <AiOutlineArrowRight
                        size={25}
                        className="text-primary border p-0.5 rounded-full shadow"
                      />
                    </div>
                  </Link>
                </div>
                <div className="flex flex-col">
                  <Link to={""}>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <TbPillOff
                          size={25}
                          className="text-primary border p-0.5 rounded-full shadow"
                        />
                        <h3 className="ml-2 text-sm md:text-lg">
                          Unbind device
                        </h3>
                      </div>
                      <AiOutlineArrowRight
                        size={25}
                        className="text-primary border p-0.5 rounded-full shadow"
                      />
                    </div>
                  </Link>
                </div>
                <div className="flex flex-col">
                  <Link to={""}>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <RiBattery2ChargeLine
                          size={25}
                          className="text-primary border p-0.5 rounded-full shadow"
                        />
                        <h3 className="ml-2 text-sm md:text-lg">
                          Lithium battery instructions
                        </h3>
                      </div>
                      <AiOutlineArrowRight
                        size={25}
                        className="text-primary border p-0.5 rounded-full shadow"
                      />
                    </div>
                  </Link>
                </div>
                <div className="flex flex-col">
                  <Link to={""}>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <AiOutlineDatabase
                          size={25}
                          className="text-primary border p-0.5 rounded-full shadow"
                        />
                        <h3 className="ml-2 text-sm md:text-lg">
                          BMS instructions
                        </h3>
                      </div>
                      <AiOutlineArrowRight
                        size={25}
                        className="text-primary border p-0.5 rounded-full shadow"
                      />
                    </div>
                  </Link>
                </div>
                <div className="flex flex-col">
                  <Link to={""}>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <GiInfo
                          size={25}
                          className="text-primary border p-0.5 rounded-full shadow"
                        />
                        <h3 className="ml-2 text-sm md:text-lg">About us</h3>
                      </div>
                      <AiOutlineArrowRight
                        size={25}
                        className="text-primary border p-0.5 rounded-full shadow"
                      />
                    </div>
                  </Link>
                </div>
                <div className="flex flex-col">
                  <Link to={""}>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <MdExitToApp
                          size={25}
                          className="text-primary border p-0.5 rounded-full shadow"
                        />
                        <h3 className="ml-2 text-sm md:text-lg">
                          Exit account
                        </h3>
                      </div>
                      <AiOutlineArrowRight
                        size={25}
                        className="text-primary border p-0.5 rounded-full shadow"
                      />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* Profile left end  */}

          {/* Profile right start  */}
          <div className="md:col-span-2 border rounded-md p-4 shadow">
            {/* Device selection section -------------------------------------- */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Device ------------------------------ */}
              <CurrentDeviceCard currentDevice={currentDevice} />
              {/* ------------------------------------------ */}

              {isDeviceLoading && <LoadingContent />}

              {!isDeviceLoading && (
                <div className="card bg-slate-100 rounded-md cursor-pointer shadow-lg">
                  <div className="card-body py-2 px-4">
                    <h3 className="card-title text-primary">Switch Device</h3>
                    <select
                      onChange={(e) => {
                        handleSelectedDevice(e);
                      }}
                      className="select select-bordered w-full max-w-xs mb-1"
                    >
                      {userDeviceList?.map((device, index) => {
                        let number = device?.deviceId;
                        let maskedNumber = "***" + number.slice(-5);
                        return (
                          <option
                            key={index}
                            value={device.deviceId}
                            selected={
                              device.deviceSelected ? "selected" : undefined
                            }
                          >
                            {device.deviceName + " " + maskedNumber}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
            </div>
            {/* --------------------------------------------------------------- */}

            <Outlet />
          </div>
          {/* Profile right end  */}
        </div>
      </div>
    </>
  );
};

export default Profile;
