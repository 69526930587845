import axios from "axios";
import React, { useContext } from "react";
import { useState } from "react";
import { AiFillWarning, AiTwotoneDelete, AiTwotoneEdit } from "react-icons/ai";
import { MdOutlineZoomOutMap } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DevicePending from "../../../../components/Shared/DeviceStatus/DevicePending";
import DeviceVerified from "../../../../components/Shared/DeviceStatus/DeviceVerified";
import LoadingButton from "../../../../components/Shared/LoadingButton";
import { BmsContext } from "../../../../App";

const DeviceRow = ({
  device,
  index,
  setIsDeviceEdit,
  setEditDevice,
  refetch,
}) => {
  const { _id, deviceId, deviceName, deviceStatus, deviceOwner } = device;
  const [isDelete, setIsDelete] = useState(false);
  const [deleteLoading, seDeleteLoading] = useState(false);

  const { userInfo } = useContext(BmsContext);
  const authToken = localStorage.getItem('accessToken');

  const navigate = useNavigate();

  const handleEditButton = () => {
    setIsDeviceEdit(true);
    setEditDevice(device);
  };

  // Handling device delete ------------------------------------------------
  const handleDeleteConfirm = (id) => {
    seDeleteLoading(true);
    (async () => {
      const url = `${process.env.REACT_APP_API_BASE_URL}api/v1/device/user-devices/${id}?phone=${userInfo?.phone}`;

      const { data } = await axios.delete(
        url,
        {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (data.success) {
        toast.success("Device Deleted Successfully.", { position: "top-left" });
        refetch();
        setIsDelete(false);
        seDeleteLoading(false);
      }
    })();
  };

  return (
    <>
      <tr className="w-full relative">
        {isDelete && (
          <>
            <th className="bg-red-100"></th>
            <td className="bg-red-100" colSpan="5">
              <div className="flex justify-center items-center gap-x-10">
                <div className="flex justify-center items-center gap-x-3">
                  <AiFillWarning size="32" className="text-error" />
                  <h3 className="text-lg font-medium text-error text-center">
                    Confirm Delete !
                  </h3>
                </div>
                <div className="flex justify-center items-center gap-x-3">
                  {!deleteLoading ? (
                    <button
                      onClick={() => {
                        handleDeleteConfirm(_id);
                      }}
                      className="btn btn-sm btn-error text-white"
                    >
                      Yes
                    </button>
                  ) : (
                    <LoadingButton name="Deleting" className="btn-sm" />
                  )}
                  <button
                    onClick={() => {
                      setIsDelete(false);
                    }}
                    className=" btn btn-sm btn-primary text-white"
                  >
                    No
                  </button>
                </div>
              </div>
            </td>
          </>
        )}

        {!isDelete && (
          <>
            <th>{index + 1}</th>
            <td>
              <div className="flex items-center space-x-3">
                <div>
                  <div className="font-bold">{deviceName}</div>
                </div>
              </div>
            </td>
            <td>{deviceId}</td>
            <td>{deviceOwner}</td>
            <td>
              <div className="flex items-center justify-between gap-2">
                {deviceStatus === "Pending" && <DevicePending />}
                {deviceStatus === "Verified" && <DeviceVerified />}
              </div>
            </td>
            <th>
              <div className="flex gap-2">
                {/* View button  */}
                <button
                  onClick={() => {
                    navigate(`${deviceId}`);
                  }}
                  className="text-indigo-700 bg-indigo-200 border p-1 rounded-full text-xl hover:border-indigo-900"
                >
                  <MdOutlineZoomOutMap />
                </button>
                {/* Edit button  */}
                <button
                  onClick={handleEditButton}
                  className="text-primary bg-blue-100 border p-1 rounded-full text-xl hover:border-blue-900"
                >
                  <AiTwotoneEdit />
                </button>
                {/* Delete Button */}
                <button
                  onClick={() => {
                    setIsDelete(true);
                  }}
                  className="text-red-500 bg-red-100 border p-1 rounded-full text-xl hover:border-red-900"
                >
                  <AiTwotoneDelete />
                </button>
              </div>
            </th>
          </>
        )}
      </tr>
    </>
  );
};

export default DeviceRow;
