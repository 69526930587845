import React from 'react';
import loader from '../../assets/loader.gif'

const Loading = () => {
    return (
        <div className='flex justify-center items-center h-[100vh]'>
            <img src={loader} alt="loading" />
        </div>
    );
};

export default Loading;