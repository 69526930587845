import React from 'react';

const BmsDashboardSkeleton = () => {
    return (
        <>
            <div className="border border-gray-300 shadow rounded-md py-4 px-2.5 w-full mx-auto">
                <div className="animate-pulse">
                    <div className='bg-slate-300 rounded-md grid grid-cols-2 md:grid-cols-5 gap-5 py-4 px-2.5'>
                        <div className='h-4 bg-slate-200 rounded-md'></div>
                        <div className='h-4 bg-slate-200 rounded-md'></div>
                        <div className='h-4 bg-slate-200 rounded-md'></div>
                        <div className='h-4 bg-slate-200 rounded-md'></div>
                        <div className='h-4 bg-slate-200 rounded-md'></div>
                    </div>

                    <div className='grid grid-cols-1 md:grid-cols-3 gap-5 mt-5'>
                        <div className='h-36 bg-slate-300 rounded-md flex justify-center items-center gap-4'>
                            <div className='h-16 w-16 rounded-full bg-slate-200'></div>
                            <div className='h-8 w-24 rounded-md bg-slate-200'></div>
                        </div>
                        <div className='h-36 bg-slate-300 rounded-md flex justify-center items-center gap-4'>
                            <div className='h-16 w-16 rounded-full bg-slate-200'></div>
                            <div className='h-8 w-24 rounded-md bg-slate-200'></div>
                        </div>
                        <div className='h-36 bg-slate-300 rounded-md flex justify-center items-center gap-4'>
                            <div className='h-16 w-40 rounded-md bg-slate-200'></div>
                        </div>
                    </div>

                    <div className='grid grid-cols-1 md:grid-cols-2 mt-5 gap-5'>
                        <div className='h-56 bg-slate-300 rounded-md flex flex-wrap gap-5 justify-center items-center py-2.5'>
                            <div className='h-8 w-36 bg-slate-200 rounded-md'></div>
                            <div className='h-8 w-36 bg-slate-200 rounded-md'></div>
                            <div className='h-8 w-36 bg-slate-200 rounded-md'></div>
                            <div className='h-8 w-36 bg-slate-200 rounded-md'></div>
                            <div className='h-8 w-36 bg-slate-200 rounded-md'></div>
                            <div className='h-8 w-36 bg-slate-200 rounded-md'></div>
                            <div className='h-8 w-36 bg-slate-200 rounded-md'></div>
                            <div className='h-8 w-36 bg-slate-200 rounded-md'></div>
                        </div>
                        <div className='h-56 bg-slate-300 rounded-md px-2.5'>
                            <div className='h-10 border-b border-slate-200'></div>
                            <div className='h-10 border-b border-slate-200'></div>
                            <div className='h-10 border-b border-slate-200'></div>
                            <div className='h-10 border-b border-slate-200'></div>
                            <div className='h-10 border-b border-slate-200'></div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default BmsDashboardSkeleton;