import React from "react";
import DeviceActive from "../../../../components/Shared/DeviceStatus/DeviceActive";
import DevicePending from "../../../../components/Shared/DeviceStatus/DevicePending";
import DeviceVerified from "../../../../components/Shared/DeviceStatus/DeviceVerified";
import LoadingContent from "../../../../components/Shared/LoadingContent";

const CurrentDeviceCard = ({ currentDevice }) => {
  if (!currentDevice) {
    return <LoadingContent />;
  }

  const { deviceId, deviceName, deviceStatus, deviceOwner } = currentDevice;

  return (
    <div className="card bg-slate-100 rounded-md cursor-pointer shadow-lg">
      <div className="card-body py-2 px-4">
        <h3 className="card-title text-primary">Current Device</h3>
        <h3 className="font-medium">DEVICE NAME: {deviceName}</h3>
        <p>
          <span className="font-medium">DEVICE ID:</span> {deviceId}
        </p>
        <p>
          <span className="font-medium">DEVICE OWNER:</span> {deviceOwner}
        </p>
        <div className="flex items-center justify-between gap-2">
          {deviceStatus === "pending" && <DevicePending />}
          {deviceStatus === "verified" && <DeviceVerified />}
          {deviceStatus === "active" && <DeviceActive />}
        </div>
      </div>
    </div>
  );
};

export default CurrentDeviceCard;
