import { createContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import BatteryBMS from "./pages/dashboard/BatteryBMS/BatteryBMS";
import ActiveBalance from "./pages/dashboard/BatteryBMS/BMSSettings/ActiveBalance";
import CurrentProtectiohn from "./pages/dashboard/BatteryBMS/BMSSettings/CurrentProtection";
import SettingModal from "./pages/dashboard/BatteryBMS/BMSSettings/SettingModal";
import Settings from "./pages/dashboard/BatteryBMS/BMSSettings/Settings";
import TempProtection from "./pages/dashboard/BatteryBMS/BMSSettings/TempProtection";
import ControllModal from "./pages/dashboard/BatteryBMS/ControllModal";
import DataMonitoring from "./pages/dashboard/BatteryBMS/DataMonitoring";
import ParameterSettings from "./pages/dashboard/BatteryBMS/ParameterSettings";
import Dashboard from "./pages/dashboard/Dashboard";
import ProfileModal from "./pages/dashboard/Profile/ProfileModal";
import { dashboardRoutes } from "./routes/dashboardRoutes";
import { publicRoutes } from "./routes/publicRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserDeleteModal from "./pages/dashboard/Users/UserDeleteModal";
import UserEditModal from "./pages/dashboard/Users/UserEditModal";
import RequireAuth from "./pages/auth/RequireAuth/RequireAuth";
import AddDeviceModal from "./pages/dashboard/Users/AddDeviceModal";
import DeviceEditModal from "./pages/dashboard/Users/DeviceEditModal";
import DeviceDeleteModal from "./pages/dashboard/Users/DeviceDeleteModal";
import RequireAdmin from "./pages/auth/RequireAdmin/RequireAdmin";
import AdminDashboard from "./pages/dashboard/Admin/AdminDashboard";
import { adminRoutes } from "./routes/adminRoutes";
import Profile from "./pages/dashboard/Profile/Profile";
import useUserProfile from "./hooks/useUserProfile";
import { io } from "socket.io-client";
import BatterySystem from "./pages/dashboard/BatteryBMS/BMSSettings/BatterySystem";
import SystemCalibration from "./pages/dashboard/BatteryBMS/BMSSettings/SystemCalibration";
import SystemSoftware from "./pages/dashboard/BatteryBMS/BMSSettings/SystemSoftware";

export const ControllContext = createContext();
export const BmsContext = createContext();
const socket = io(process.env.REACT_APP_SOCKET_URL);

function App() {
  // const { user, isAPILoading } = useUserDetail();
  // const [admin, adminLoading] = useAdmin(user);

  const { userInfo, isUserLoading } = useUserProfile();

  // Controll Stats
  const [showControllModal, setShowControllModal] = useState(false);
  const [controllName, setControllName] = useState("");
  const [charge, setCharge] = useState(false);
  const [disCharge, setDisCharge] = useState(false);
  const [balance, setBalance] = useState(false);
  const [emergency, setEmergency] = useState(false);

  // Sidebar state
  const [sidebarHide, setSidebarHide] = useState(false);

  // User Edit state
  const [editUser, setEditUser] = useState({});
  const [isEditUserModal, setIsEditUserModal] = useState(false);

  // User Delete State
  const [deleteUser, setDeleteUser] = useState(null);
  const [isDeleteUserModal, setIsDeleteUserModal] = useState(false);

  const [statusData, setStatusData] = useState({});
  const [infoData, setInfoData] = useState({});
  const [statusDataE3ToE6, setStatusDataE3ToE6] = useState({});

  useEffect(() => {
    const handleStatusData = (data) => {
      setStatusData(data?.statusData);
    };

    socket.on("status-data", handleStatusData);

    return () => {
      socket.off("status-data", handleStatusData);
    };
  }, []);

  useEffect(() => {
    const handleInfoData = (data) => {
      setInfoData(data?.infoData);
    };

    socket.on("info-data", handleInfoData);

    return () => {
      socket.off("info-data", handleInfoData);
    };
  }, []);

  useEffect(() => {
    const handleStatusDataE3ToE6 = (data) => {
      setStatusDataE3ToE6(data?.statusDataE3ToE6);
    };

    socket.on("status-data-E3TOE6", handleStatusDataE3ToE6);

    return () => {
      socket.off("status-data-E3TOE6", handleStatusDataE3ToE6);
    };
  }, []);

  // Onchanging controll button ----------------------------------------
  const handleCharge = () => {
    setShowControllModal(true);
    setControllName("charge");
  };
  const handleDisCharge = () => {
    setShowControllModal(true);
    setControllName("disCharge");
  };
  const handleBalance = () => {
    setShowControllModal(true);
    setControllName("balance");
  };
  const handleEmergency = () => {
    setShowControllModal(true);
    setControllName("emergency");
  };
  // --------------------------------------------------------------------

  // Handle Controll start ----------------------------------------------
  const handleControllSubmit = (e) => {
    e.preventDefault();
    const controllPass = e.target.password.value;

    // Checking Controll password and switch controlls buttons
    if (controllPass === "123") {
      // Hiding controll modal
      setShowControllModal(false);

      // switching controll button
      switch (controllName) {
        case "charge":
          setCharge(!charge);
          break;
        case "disCharge":
          setDisCharge(!disCharge);
          break;
        case "balance":
          setBalance(!balance);
          break;
        case "emergency":
          setEmergency(!emergency);
          break;
        default:
          break;
      }
    }
  };
  // --------------------------------------------------------------------

  // Context Area -------------------------------------------------------
  // Controll Context
  const controllContext = {
    setShowControllModal: setShowControllModal,
    charge: charge,
    disCharge: disCharge,
    balance: balance,
    emergency: emergency,
    handleCharge: handleCharge,
    handleBalance: handleBalance,
    handleDisCharge: handleDisCharge,
    handleEmergency: handleEmergency,
  };

  // Drawer context
  const sidebarContext = {
    sidebarHide: sidebarHide,
    setSidebarHide: setSidebarHide,
  };
  // ---------------------------------------------------------------------

  // console.log(userInfo);

  return (
    <BmsContext.Provider
      value={{
        sidebarContext,
        controllContext,
        setEditUser,
        setIsEditUserModal,
        setDeleteUser,
        setIsDeleteUserModal,
        userInfo,
        isUserLoading,
        infoData,
        statusData,
        statusDataE3ToE6,
      }}
    >
      {/* All Routes Start Here -------------------------------------------------- */}
      <Routes>
        {/* Public Routes  */}
        {publicRoutes.map(({ path, Component }, index) => (
          <Route
            key={index}
            path={path}
            element={<Component></Component>}
          ></Route>
        ))}

        {/* Dashboard Routes start  -------------------------------------*/}
        <Route
          path="/dashboard"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        >
          <Route
            element={
              userInfo?.role === "super-admin" ? (
                <AdminDashboard />
              ) : (
                <BatteryBMS />
              )
            }
          ></Route>

          {/* Admin Routes  */}
          {/* <Route path="bms-admin" element={<AdminDashboard />}></Route> */}
          {adminRoutes.map(({ path, Component }, index) => (
            <Route
              key={index}
              path={path}
              element={
                <RequireAdmin>
                  <Component />
                </RequireAdmin>
              }
            />
          ))}

          {/* Normal user dashboard Routes  */}
          {dashboardRoutes.map(({ path, Component }, index) => (
            <Route key={index} path={path} element={<Component />} />
          ))}

          <Route path="parameter-settings" element={<ParameterSettings />}>
            <Route path="active-balance" element={<ActiveBalance />}></Route>
            <Route
              path="current-protection"
              element={<CurrentProtectiohn />}
            ></Route>
            <Route path="temp-protection" element={<TempProtection />}></Route>
            <Route path="battery-system" element={<BatterySystem />}></Route>
            <Route path="system-software" element={<SystemSoftware />}></Route>
            <Route
              path="system-calibration"
              element={<SystemCalibration />}
            ></Route>
            <Route path="parameter-setting" element={<Settings />}></Route>
          </Route>

          {/* Profile Route  */}
          <Route path="profile" element={<Profile />}>
            <Route path="data-monitoring" element={<DataMonitoring />}></Route>
          </Route>
        </Route>
        {/* Dashboard Routes end ---------------------------------------------------- */}
      </Routes>
      {/* All Routes End Here -------------------------------------------------- */}

      {/* All Modals start -------------------------------------------------- */}
      <ProfileModal />
      <ControllModal
        showControllModal={showControllModal}
        setShowControllModal={setShowControllModal}
        handleControllSubmit={handleControllSubmit}
      />
      <SettingModal />
      {/* user delete modal  */}
      {isDeleteUserModal && (
        <UserDeleteModal
          deleteUser={deleteUser}
          setDeleteUser={setDeleteUser}
          setIsDeleteUserModal={setIsDeleteUserModal}
        />
      )}
      {/* user info delte modal  */}
      {isEditUserModal && (
        <UserEditModal
          user={editUser}
          setIsEditUserModal={setIsEditUserModal}
        />
      )}
      <AddDeviceModal />
      <DeviceEditModal />
      <DeviceDeleteModal />
      {/* All Modals end ----------------------------------------------------- */}

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </BmsContext.Provider>
  );
}

export default App;
