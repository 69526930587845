import React from "react";
import HistoryChart from "../../../components/HistoryChart/HistoryChart";

const AlarmHistory = () => {
  // Data Sources
  // const voltAndCurrent = [
  //   {
  //     time: "12: 00 PM",
  //     type: "Voltage",
  //     value: 3.1,
  //   },
  //   {
  //     time: "13: 00 AM",
  //     type: "Voltage",
  //     value: 3.1,
  //   },
  //   {
  //     time: "14: 00 AM",
  //     type: "Voltage",
  //     value: 3.1,
  //   },
  //   {
  //     time: "15: 00 AM",
  //     type: "Voltage",
  //     value: 3.1,
  //   },
  //   {
  //     time: "12: 00 PM",
  //     type: "Current",
  //     value: 2.1,
  //   },
  //   {
  //     time: "13: 00 AM",
  //     type: "Current",
  //     value: 2.2,
  //   },
  //   {
  //     time: "14: 00 AM",
  //     type: "Current",
  //     value: 2.3,
  //   },
  //   {
  //     time: "15: 00 AM",
  //     type: "Current",
  //     value: 2.0,
  //   },
  // ];

  return (
    <div className="m-4">
      {/* Chart Start  */}
      <div className="border rounded-lg shadow-lg my-3 pb-3 bg-white">
        {/* Heading  */}
        <div className="flex flex-col w-full border-opacity-50">
          <div className="divider px-3">
            <h2 className="text-xl font-bold text-secondary px-3 py-2">
              History In Graph
            </h2>
          </div>
        </div>

        <div className="w-full h-60 md:h-96 pt-3 pb-1">
          <HistoryChart />
        </div>
      </div>
      {/* Chart End  */}

      {/* Alarm History start */}
      {/* <div className='border rounded-lg shadow-lg my-3 pb-3'>

                <div className="flex flex-col w-full border-opacity-50">
                    <div className="divider px-3"><h2 className='text-xl font-bold text-secondary px-3 py-2'>Battey Quantity Detection Alarm</h2>
                    </div>
                </div>

                <div className='grid grid-cols-2 md:grid-cols-6 gap-3 px-3 py-2'>
                    <div className='border border-primary shadow-md px-1.5 pt-0.5 pb-1 rounded'>
                        <span className='text-neutral font-bold'>B1:</span> <span className='text-primary font-bold'>Success</span>
                    </div>
                    <div className='border border-primary shadow-md px-1.5 pt-0.5 pb-1 rounded'>
                        <span className='text-neutral font-bold'>B2:</span> <span className='text-primary font-bold'>Success</span>
                    </div>
                    <div className='border border-primary shadow-md px-1.5 pt-0.5 pb-1 rounded'>
                        <span className='text-neutral font-bold'>B3:</span> <span className='text-primary font-bold'>Success</span>
                    </div>
                    <div className='border border-primary shadow-md px-1.5 pt-0.5 pb-1 rounded'>
                        <span className='text-neutral font-bold'>B4:</span> <span className='text-primary font-bold'>Success</span>
                    </div>
                    <div className='border border-primary shadow-md px-1.5 pt-0.5 pb-1 rounded'>
                        <span className='text-neutral font-bold'>B5:</span> <span className='text-primary font-bold'>Success</span>
                    </div>
                    <div className='border border-primary shadow-md px-1.5 pt-0.5 pb-1 rounded'>
                        <span className='text-neutral font-bold'>B6:</span> <span className='text-primary font-bold'>Success</span>
                    </div>
                    <div className='border border-primary shadow-md px-1.5 pt-0.5 pb-1 rounded'>
                        <span className='text-neutral font-bold'>B7:</span> <span className='text-primary font-bold'>Success</span>
                    </div>
                    <div className='border border-primary shadow-md px-1.5 pt-0.5 pb-1 rounded'>
                        <span className='text-neutral font-bold'>B8:</span> <span className='text-primary font-bold'>Success</span>
                    </div>
                    <div className='border border-primary shadow-md px-1.5 pt-0.5 pb-1 rounded'>
                        <span className='text-neutral font-bold'>B9:</span> <span className='text-primary font-bold'>Success</span>
                    </div>
                    <div className='border border-primary shadow-md px-1.5 pt-0.5 pb-1 rounded'>
                        <span className='text-neutral font-bold'>B10:</span> <span className='text-primary font-bold'>Success</span>
                    </div>
                    <div className='border border-primary shadow-md px-1.5 pt-0.5 pb-1 rounded'>
                        <span className='text-neutral font-bold'>B11:</span> <span className='text-primary font-bold'>Success</span>
                    </div>
                    <div className='border border-primary shadow-md px-1.5 pt-0.5 pb-1 rounded'>
                        <span className='text-neutral font-bold'>B12:</span> <span className='text-primary font-bold'>Success</span>
                    </div>
                    <div className='border border-primary shadow-md px-1.5 pt-0.5 pb-1 rounded'>
                        <span className='text-neutral font-bold'>B13:</span> <span className='text-primary font-bold'>Success</span>
                    </div>
                    <div className='border border-accent shadow-md px-1.5 pt-0.5 pb-1 rounded'>
                        <span className='text-neutral font-bold'>B14:</span> <span className='text-accent font-bold'>Failed</span>
                    </div>
                    <div className='border border-primary shadow-md px-1.5 pt-0.5 pb-1 rounded'>
                        <span className='text-neutral font-bold'>B15:</span> <span className='text-primary font-bold'>Success</span>
                    </div>
                    <div className='border border-primary shadow-md px-1.5 pt-0.5 pb-1 rounded'>
                        <span className='text-neutral font-bold'>B16:</span> <span className='text-primary font-bold'>Success</span>
                    </div>
                </div>
            </div> */}
      {/* Alarm History end */}
    </div>
  );
};

export default AlarmHistory;
