import React, { useEffect, useState, useRef } from "react";
import { BsCalendar3 } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";
import { TbReportSearch } from "react-icons/tb";
import { Calendar } from "react-date-range";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import axios from "axios";
import MyReportDetails from "./MyReportDetails";
import { useDownloadExcel } from "react-export-table-to-excel";

const MyReport = () => {
  // date picker stat --------------------------
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [allBMSReportData, setAllBMSReportData] = useState({});

  const [isStartDate, setIsStartDate] = useState(false);
  const [isEndDate, setIsEndDate] = useState(false);

  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    setStartDate(format(new Date(), "yyyy-MM-dd"));
    setEndDate(format(new Date(), "yyyy-MM-dd"));
  }, []);

  const handleGetReport = () => {
    setIsStartDate(false);
    setIsEndDate(false);

    // getting bms report ------------------
    const bmsReportURL = `${process.env.REACT_APP_API_BASE_URL}api/v1/bms/date-wise-bms-data?startDate=${startDate}&endDate=${endDate}`;
    (async () => {
      try {
        const { data } = await axios.get(bmsReportURL);
        if (data?.success === true) {
          setAllBMSReportData(data);
        }
      } catch (error) {
        // console.log(error);
      }
    })();

    // --------------------------
    setCurrentDate(format(new Date(), "yyy-MM-dd"));
  };

  // print pdf ------------------
  const componentPDF = useRef();

  // const generatePdfPrint = useReactToPrint({
  //   content: () => componentPDF.current,
  //   documentTitle: "Report_" + currentDate,
  // });

  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `BMS_Report_${currentDate}`,
    sheet: "Users",
  });

  return (
    <div className="mt-4 relative px-2">
      <div className="bg-primary px-3 py-4 rounded-lg mb-4">
        <div className="flex flex-col gap-4 md:flex-row md:items-center">
          <div className="flex items-center gap-2">
            <label
              htmlFor="start-date"
              className="font-medium text-white text-lg flex items-center gap-1"
            >
              {" "}
              <BsCalendar3 /> <span>Start Date:</span>
            </label>
            <input
              onClick={() => {
                setIsStartDate(!isStartDate);
                setIsEndDate(false);
              }}
              type="text"
              defaultValue={startDate}
              // value={startDate}
              id="start-date"
              placeholder="Pick start date"
              className="rounded placeholder-white px-2 bg-white/30 border outline-white py-0.5 w-32"
            />
          </div>

          <div className="flex items-center gap-5">
            <label
              htmlFor="end-date"
              className="font-medium text-white text-lg flex items-center gap-1"
            >
              {" "}
              <BsCalendar3 /> <span>End Date:</span>
            </label>
            <input
              onClick={() => {
                setIsEndDate(!isEndDate);
                setIsStartDate(false);
              }}
              type="text"
              defaultValue={endDate}
              id="end-date"
              placeholder="Pick end date"
              className="rounded placeholder-white px-2 bg-white/30 border outline-white py-0.5 w-32"
            />
          </div>

          <div className="">
            <button
              onClick={handleGetReport}
              className="btn btn-sm btn-secondary"
            >
              <TbReportSearch size="20" />{" "}
              <span className="ml-2">Get Report</span>
            </button>
          </div>

          <div>
            {allBMSReportData?.data?.length > 0 ? (
              <button
                onClick={onDownload}
                className="btn btn-sm bg-green-700 border-white text-white hover:bg-green-900 hover:border-white"
              >
                <RiFileExcel2Line size="20" />{" "}
                <span className="ml-2">Excel</span>
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:items-center">
          <div className="w-96">
            {isStartDate && (
              <div>
                <Calendar
                  onChange={(date) => setStartDate(format(date, "yyyy-MM-dd"))}
                  editableDateInputs={true}
                  className="rounded-md"
                />
              </div>
            )}
          </div>

          <div>
            {isEndDate && (
              <div>
                <Calendar
                  onChange={(date) => setEndDate(format(date, "yyyy-MM-dd"))}
                  editableDateInputs={true}
                  className="rounded-md"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {allBMSReportData?.data?.length > 0 ? (
        <div ref={componentPDF} className="border-2 rounded-lg print all">
          <MyReportDetails
            currentDate={currentDate}
            startDate={startDate}
            endDate={endDate}
            allBMSReportData={allBMSReportData}
            tableRef={tableRef}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MyReport;
