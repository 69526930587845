import React from 'react';

const HistoryPDF = ({ gpsHistory, currentDate, startDate, endDate }) => {
    const locationsArray = gpsHistory?.deviceLocations;
    return (
        <div className="px-8">

            <div className='mb-8'>
                <h2 className='text-center text-xl font-medium'>Showing Data from {startDate} to {endDate}</h2>
            </div>

            <div>
                <table className="table table-xs">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Time</th>
                            <th>GPS Sattelite No</th>
                            <th>Latitute</th>
                            <th>Longitude</th>
                            <th>Altitude</th>
                            <th>Course</th>
                            <th>Speed</th>
                        </tr>
                    </thead>
                    <tbody>
                        {locationsArray?.map((location, index) => {
                            const gps = location?.gpsData?.gpsValues;
                            return (
                                <tr key={index}>
                                    <th>{index + 1}</th>
                                    <td>
                                        <span className='font-medium'>{gps?.day}/{gps?.month}/{gps?.year}, {gps?.hour}:{gps?.minute}:{gps?.second}</span>
                                    </td>
                                    <td><span className='font-medium'>{gps?.numberOfGPSSattelite}</span></td>
                                    <td><span className='font-medium'>{gps?.latitute}</span></td>
                                    <td><span className='font-medium'>{gps?.longitude}</span></td>
                                    <td><span className='font-medium'>{gps?.altitude}</span></td>
                                    <td><span className='font-medium'>{gps?.course}</span></td>
                                    <td><span className='font-medium'>{gps?.speed}</span></td>
                                </tr>
                            )
                        })
                        }


                    </tbody>
                </table>
            </div>

        </div>
    );
};

export default HistoryPDF;