import React, { useContext, useEffect, useState } from "react";
import BatteryStatus from "./BatteryStatus";
import DataMonitoring from "./DataMonitoring";
import io from "socket.io-client";
import axios from "axios";
import useDevicesByPhone from "../../../hooks/useDevicesByPhone";
import { BmsContext } from "../../../App";

const socket = io(process.env.REACT_APP_SOCKET_URL);

const BatteryBMS = () => {
  const { userInfo } = useContext(BmsContext);
  const [userDeviceList] = useDevicesByPhone(userInfo?.phone);
  const selectedDevice = userDeviceList?.filter(
    (device) => device?.deviceSelected === true
  );
  const currentDeviceID = selectedDevice && selectedDevice[0]?.deviceId;

  const [socketData, setSocketData] = useState({});
  const [lastUpdatedSocketData, setLastUpdatedSocketData] = useState({});
  const [lastUpdatedData, setLastUpdatedData] = useState(null); // or an appropriate initial value
  const [lastUpdatedTime, setLastUpdatedTime] = useState("");

  useEffect(() => {
    socket.on("mqtt-message", (data) => {
      setLastUpdatedTime(data.time);
      setLastUpdatedSocketData(data.bmsData);
    });
  }, []); // No dependencies to avoid unnecessary re-renders

  useEffect(() => {
    if (currentDeviceID) {
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}api/data?topic=update/${currentDeviceID}`
        )
        .then(
          (res) => {
            setSocketData(res.data);
          },
          (error) => {
            console.log(error);
          }
        );

      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}api/v1/bms/device-data?topic=update/${currentDeviceID}`
        )
        .then(
          (res) => {
            setLastUpdatedData(res?.data?.data);
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }, [currentDeviceID]);

  return (
    <div className="m-4">
      <BatteryStatus
        socketData={lastUpdatedSocketData}
        lastUpdatedData={lastUpdatedData}
        lastUpdatedTime={lastUpdatedTime}
      />
      <DataMonitoring
        socketData={lastUpdatedSocketData}
        lastUpdatedData={lastUpdatedData}
      />
    </div>
  );
};

export default BatteryBMS;
