import AdminDashboard from "../pages/dashboard/Admin/AdminDashboard";
import AllDevices from "../pages/dashboard/Admin/AllDevices";
import MyReport from "../pages/dashboard/Admin/MyReports/MyReport";
import DeviceInfo from "../pages/dashboard/Admin/SingleDevice/DeviceInfo";
import UserDetailForm from "../pages/dashboard/Users/UserDetailForm";
import Users from "../pages/dashboard/Users/Users";

export const adminRoutes = [
  {
    path: "admin-dashboard",
    name: "AdminDashboard",
    Component: AdminDashboard,
  },
  { path: "users", name: "Users", Component: Users },
  { path: "users/:email", name: "UserDetailForm", Component: UserDetailForm },
  { path: "all-devices", name: "UserDevice", Component: AllDevices },
  { path: "all-devices/:deviceId", name: "DeviceInfo", Component: DeviceInfo },
  { path: "my-report", name: "Report", Component: MyReport },
];
