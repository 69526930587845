import React, { useContext } from "react";
import DevicePending from "../../../../components/Shared/DeviceStatus/DevicePending";
import DeviceVerified from "../../../../components/Shared/DeviceStatus/DeviceVerified";
import { AiTwotoneEdit, AiTwotoneDelete } from "react-icons/ai";
import DeviceEditCard from "./DeviceEditCard";
import { useState } from "react";
import { AiFillWarning } from "react-icons/ai";
import axios from "axios";
import { toast } from "react-toastify";
import { BmsContext } from "../../../../App";

const DeviceCard = ({ device, refetch }) => {
  const { _id, deviceId, deviceName, deviceStatus, deviceOwner } = device;
  const [deviceEditInfo, setDeviceEditInfo] = useState({});
  const [isEditCardVisible, setIsEditCardVisible] = useState(false);
  const [isDeleteScreen, setDeleteScreen] = useState(false);

  const { userInfo } = useContext(BmsContext);

  // Handling device edit button ----------------------------------------
  const handleEditDeviceButton = (device) => {
    setIsEditCardVisible(true);
    setDeviceEditInfo(device);
  };

  const authToken = localStorage.getItem('accessToken');

  // Handling device delete ------------------------------------------------
  const handleDeleteConfirm = (id) => {
    (async () => {
      const url = `${process.env.REACT_APP_API_BASE_URL}api/v1/device/user-devices/${id}?phone=${userInfo?.phone}`;

      const { data } = await axios.delete(
        url,
        {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (data.success) {
        toast.success("Device Deleted Successfully.", { position: "top-left" });
        refetch();
        setDeleteScreen(false);
      }
    })();
  };

  return (
    <>
      {isEditCardVisible && deviceEditInfo._id === _id ? (
        <DeviceEditCard
          setIsEditCardVisible={setIsEditCardVisible}
          deviceEditInfo={deviceEditInfo}
          refetch={refetch}
        />
      ) : (
        <div className="card bg-slate-100 rounded-md cursor-pointer shadow-lg border relative">
          {/* Device Delete screen -------------------------------------- */}
          {isDeleteScreen && (
            <div className="h-full w-full absolute bg-red-50 flex flex-col items-center justify-center gap-2 p-2">
              <button
                onClick={() => {
                  setDeleteScreen(false);
                }}
                className=" bg-blue-100 p-1 rounded-full w-8 h-8 text-primary absolute right-2 top-2"
              >
                ✕
              </button>
              <AiFillWarning size="40" className="text-error" />
              <h3 className="text-lg font-medium text-error text-center">
                Do you want to delete this device ?
              </h3>
              <button
                onClick={() => {
                  handleDeleteConfirm(_id);
                }}
                className="btn btn-sm btn-error text-white"
              >
                Yes
              </button>
            </div>
          )}
          {/* -------------------------------------------------------------- */}

          <div className="card-body py-3 px-4">
            <h3 className="font-medium">DEVICE NAME: {deviceName}</h3>
            <p>
              <span className="font-medium">DEVICE ID:</span> {deviceId}
            </p>
            <p>
              <span className="font-medium">DEVICE OWNER:</span> {deviceOwner}
            </p>
            <div className="flex items-center justify-between gap-2">
              {deviceStatus === "Pending" && <DevicePending />}
              {deviceStatus === "Verified" && <DeviceVerified />}
              <div className="flex gap-2">
                {/* Edit button  */}
                <button
                  onClick={() => {
                    handleEditDeviceButton(device);
                  }}
                  className="text-primary bg-primary/20 border border-primary p-1 rounded-full text-md hover:bg-primary hover:text-white"
                >
                  <AiTwotoneEdit />
                </button>
                {/* Delete Button */}
                <button
                  onClick={(e) => setDeleteScreen(true)}
                  className="text-accent bg-accent/20 border border-accent p-1 rounded-full text-md hover:bg-accent hover:text-white"
                >
                  <AiTwotoneDelete />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeviceCard;
