import axios from "axios";
import React, { useContext } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import LoadingButton from "../../../../components/Shared/LoadingButton";
import { BmsContext } from "../../../../App";

const DeviceEditCard = ({ setIsEditCardVisible, deviceEditInfo, refetch }) => {
  const { _id, deviceId, deviceName } = deviceEditInfo;
  const [editDeviceLoading, setEditDeviceLoading] = useState(false);

  const { userInfo } = useContext(BmsContext);

  const closeDeviceEdit = () => {
    setIsEditCardVisible(false);
  };

  const authToken = localStorage.getItem('accessToken');

  const handleDeviceEditSubmit = (e) => {
    e.preventDefault();
    setEditDeviceLoading(true);
    const deviceName = e.target.deviceName.value;
    const deviceId = e.target.deviceId.value;

    (async () => {
      const payload = { deviceName, deviceId };
      const url = `${process.env.REACT_APP_API_BASE_URL}api/v1/device/user-devices/${_id}?phone=${userInfo?.phone}`;
      
      const res = await axios.patch(
        url,
        payload,
        {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (res.data.success) {
        toast.success("Device Updated Successfully", { position: "top-left" });
        refetch();
        closeDeviceEdit();
      }
      setEditDeviceLoading(false);
    })();
  };

  return (
    <>
      <div className="card bg-slate-100 rounded-md cursor-pointer shadow-lg border">
        <div className="card-body py-3 px-4">
          <form
            onSubmit={(e) => {
              handleDeviceEditSubmit(e);
            }}
          >
            <div className="flex items-center justify-between mb-2 gap-1">
              <h3 className="font-medium">NAME:</h3>
              <input
                type="text"
                name="deviceName"
                defaultValue={deviceName}
                placeholder="Device Name"
                className="input input-sm"
              />
            </div>
            <div className="flex items-center justify-between mb-2 gap-1">
              <h3 className="font-medium">Device ID:</h3>
              <input
                type="text"
                name="deviceId"
                defaultValue={deviceId}
                placeholder="Device ID"
                className="input input-sm"
              />
            </div>
            <div className="flex justify-center gap-2 mt-5">
              <button
                onClick={() => {
                  closeDeviceEdit();
                }}
                className="btn btn-error text-white btn-sm"
              >
                Cancel
              </button>
              {!editDeviceLoading ? (
                <button type="submit" className="btn btn-primary btn-sm">
                  Edit Device
                </button>
              ) : (
                <LoadingButton
                  name={"Updating Device"}
                  customclassName={"btn-sm"}
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default DeviceEditCard;
