import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { BmsContext } from "../../../App";
import { FaCar, FaCarSide } from "react-icons/fa";

const Notifications = () => {
  const { userInfo } = useContext(BmsContext);
  const authToken = localStorage.getItem('accessToken');

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    (async () => {
      const notifyApiUrl = `${process.env.REACT_APP_API_BASE_URL}api/v1/device/notification?phone=${userInfo?.phone}`;

      const { data } = await axios.get(notifyApiUrl,
        {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (data?.data?.length > 0) {
        setNotifications(data?.data);
      }

    })();
  }, [authToken, userInfo?.phone]);

  return (
    <>
      <div className="p-4">
        {
          notifications?.map((item, index) => {
            return <div key={index} className="flex gap-2 items-center justify-between flex-wrap md:flex-nowrap border mb-2 rounded-md p-2 bg-primary/10 text-primary">
              <div className="w-full md:w-60 flex gap-2 items-center">
                <div className="bg-white p-1 rounded-full shadow-md">
                  {item?.insideGeofence && <FaCar size={25} />}
                  {!item?.insideGeofence && <FaCarSide size={25} />}
                </div>
                <h3 className="font-medium">{item?.insideGeofence ? 'Vehicle Entered Into Geofence' : 'Vehicle Exited Geofence'}</h3>
              </div>
              <div>
                <p className="font-medium">Device ID</p>
                <p>{item?.deviceId}</p>
              </div>
              <div>
                <p className="font-medium">Time</p>
                <p>{item?.time}</p>
              </div>
              <div>
                <p className="font-medium">Date</p>
                <p>{item?.date}</p>
              </div>
            </div>
          })
        }
      </div>
    </>
  );
};

export default Notifications;
