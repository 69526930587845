import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBeh0jer20tjAE8mkcNRY0aKAnUvshPhh4",
  authDomain: "bms-apps.firebaseapp.com",
  projectId: "bms-apps",
  storageBucket: "bms-apps.appspot.com",
  messagingSenderId: "54421780877",
  appId: "1:54421780877:web:97e8cd2874867c5e7525c4",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export default auth;
