import axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import useUsers from "../../../hooks/useUsers";

const UserDeleteModal = ({
  deleteUser,
  setDeleteUser,
  setIsDeleteUserModal,
}) => {
  const { refetch } = useUsers();

  const handleUserDelete = (id) => {
    (async () => {
      const url = `${process.env.REACT_APP_API_BASE_URL}api/v1/bms/users/${id}`;
      const { data } = await axios.delete(url);
      if (data) {
        toast.success("User Deleted Successfully.");
        setDeleteUser(null);
        refetch();
        setIsDeleteUserModal(false);
      }
    })();
  };

  return (
    <div>
      <input type="checkbox" id="user-delete-modal" className="modal-toggle" />
      <div className="backdrop-blur-sm modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">
            Are you sure? You want to delete the user:{" "}
            <span className="text-error">{deleteUser?.email}</span> ?
          </h3>
          <div className="modal-action">
            <label htmlFor="user-delete-modal" className="btn btn-primary">
              No
            </label>
            <label
              onClick={() => {
                handleUserDelete(deleteUser._id);
              }}
              htmlFor="user-delete-modal"
              className="btn btn-error text-white"
            >
              Yes
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(UserDeleteModal);
