import React, { useContext, useState } from "react";
import { BmsContext } from "../../../../App";

const SystemSoftware = () => {
  const { statusDataE3ToE6 } = useContext(BmsContext);

  const [softwareSettings, setSoftwareSettings] = useState({
    deviceIdCode: statusDataE3ToE6?.E5?.deviceIdCode || "",
    systemWorkingTime: statusDataE3ToE6?.E5?.systemWorkingTime || "",
    softwareVersion: statusDataE3ToE6?.E5?.softwareVersion || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSoftwareSettings({
      ...softwareSettings,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle submit logic here
    console.log("Submitted software settings: ", softwareSettings);
  };

  return (
    <form onSubmit={handleSubmit} className="overflow-x-auto mt-3 mb-5 px-1">
      <table className="table table-compact w-full">
        <thead className="text-white">
          <tr>
            <th className="bg-primary text-xs md:text-base">Project</th>
            <th className="bg-primary text-xs md:text-base">Machine</th>
            <th className="bg-primary text-xs md:text-base">Setting</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="w-48 md:w-80">
              <h3 className="md:text-base font-medium whitespace-normal">
                Device ID Code
              </h3>
            </td>
            <td>
              <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                {statusDataE3ToE6?.E5?.deviceIdCode}
              </div>
            </td>
            <td className="w-48 md:w-64">
              <input
                type="text"
                name="deviceIdCode"
                value={softwareSettings.deviceIdCode}
                onChange={handleChange}
                placeholder="Type here"
                className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
              />
            </td>
          </tr>
          <tr>
            <td className="w-48 md:w-80">
              <h3 className="md:text-base font-medium whitespace-normal">
                System Working Time
              </h3>
            </td>
            <td>
              <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                {statusDataE3ToE6?.E5?.systemWorkingTime}
              </div>
            </td>
            <td className="w-48 md:w-64">
              <input
                type="number"
                name="systemWorkingTime"
                value={softwareSettings.systemWorkingTime}
                onChange={handleChange}
                placeholder="Type here"
                className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
              />
            </td>
          </tr>
          <tr>
            <td className="w-48 md:w-80">
              <h3 className="md:text-base font-medium whitespace-normal">
                Software Version Number
              </h3>
            </td>
            <td>
              <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                {statusDataE3ToE6?.E5?.softwareVersion}
              </div>
            </td>
            <td className="w-48 md:w-64">
              <input
                type="text"
                name="softwareVersion"
                value={softwareSettings.softwareVersion}
                onChange={handleChange}
                placeholder="Type here"
                className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="flex justify-end pr-2">
        <div className="w-40 mt-4">
          <button
            type="submit"
            className="btn btn-primary btn-xs md:btn-sm w-40"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default SystemSoftware;
