import React, { useContext } from "react";
import { CgProfile } from "react-icons/cg";
import { HiOutlineMail } from "react-icons/hi";
import { FiSmartphone } from "react-icons/fi";
import { AiOutlineArrowRight, AiOutlineDatabase } from "react-icons/ai";
import { TbPillOff } from "react-icons/tb";
import { RiBattery2ChargeLine } from "react-icons/ri";
import { GiInfo } from "react-icons/gi";
import { MdExitToApp } from "react-icons/md";
import { Link, Outlet } from "react-router-dom";
import { BmsContext } from "../../../App";

const ProfileModal = () => {
  const { userInfo } = useContext(BmsContext);

  return (
    <>
      <input type="checkbox" id="profile-modal" className="modal-toggle" />
      <div className="modal backdrop-blur-sm">
        <div className="modal-box w-11/12 h-auto max-w-5xl relative">
          {/* modal close button  */}
          <label
            htmlFor="profile-modal"
            className="btn btn-sm btn-circle absolute btn-accent right-2 top-2"
          >
            ✕
          </label>

          <h3 className="font-bold text-lg">Hello! {userInfo?.fullName}</h3>

          <div className="grid grid-cols-1 md:grid-cols-3 my-5 gap-4">
            {/* Profile left start  */}
            <div className="border rounded-md shadow p-3 flex flex-col lg:flex-row gap-2">
              <div className="flex flex-col gap-3 justify-center">
                <div className="avatar flex justify-center">
                  <div className="w-24 rounded-full border-4">
                    <p className="text-center">Rasel</p>
                  </div>
                </div>
                <div className="flex flex-col">
                  <Link to={""}>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <CgProfile
                          size={25}
                          className="text-primary border p-0.5 rounded-full shadow"
                        />
                        <h3 className="ml-2 text-sm md:text-lg">
                          Perfect data
                        </h3>
                      </div>
                      <AiOutlineArrowRight
                        size={25}
                        className="text-primary border p-0.5 rounded-full shadow"
                      />
                    </div>
                  </Link>
                </div>
                <div className="flex flex-col">
                  <Link to={""}>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <TbPillOff
                          size={25}
                          className="text-primary border p-0.5 rounded-full shadow"
                        />
                        <h3 className="ml-2 text-sm md:text-lg">
                          Unbind device
                        </h3>
                      </div>
                      <AiOutlineArrowRight
                        size={25}
                        className="text-primary border p-0.5 rounded-full shadow"
                      />
                    </div>
                  </Link>
                </div>
                <div className="flex flex-col">
                  <Link to={""}>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <RiBattery2ChargeLine
                          size={25}
                          className="text-primary border p-0.5 rounded-full shadow"
                        />
                        <h3 className="ml-2 text-sm md:text-lg">
                          Lithium battery instructions
                        </h3>
                      </div>
                      <AiOutlineArrowRight
                        size={25}
                        className="text-primary border p-0.5 rounded-full shadow"
                      />
                    </div>
                  </Link>
                </div>
                <div className="flex flex-col">
                  <Link to={""}>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <AiOutlineDatabase
                          size={25}
                          className="text-primary border p-0.5 rounded-full shadow"
                        />
                        <h3 className="ml-2 text-sm md:text-lg">
                          BMS instructions
                        </h3>
                      </div>
                      <AiOutlineArrowRight
                        size={25}
                        className="text-primary border p-0.5 rounded-full shadow"
                      />
                    </div>
                  </Link>
                </div>
                <div className="flex flex-col">
                  <Link to={""}>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <GiInfo
                          size={25}
                          className="text-primary border p-0.5 rounded-full shadow"
                        />
                        <h3 className="ml-2 text-sm md:text-lg">About us</h3>
                      </div>
                      <AiOutlineArrowRight
                        size={25}
                        className="text-primary border p-0.5 rounded-full shadow"
                      />
                    </div>
                  </Link>
                </div>
                <div className="flex flex-col">
                  <Link to={""}>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <MdExitToApp
                          size={25}
                          className="text-primary border p-0.5 rounded-full shadow"
                        />
                        <h3 className="ml-2 text-sm md:text-lg">
                          Exit account
                        </h3>
                      </div>
                      <AiOutlineArrowRight
                        size={25}
                        className="text-primary border p-0.5 rounded-full shadow"
                      />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            {/* Profile left end  */}

            {/* Profile right start  */}
            <div className="md:col-span-2 border rounded-md p-3 shadow">
              {/* Oulet top section --------------------------------------- */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                <div className="flex items-center gap-1 border-b pb-1">
                  <CgProfile
                    size="25"
                    className="text-primary border p-0.5 rounded-full shadow"
                  />{" "}
                  <p className="text-sm md:text-lg">
                    {userInfo?.fullName ? userInfo?.fullName : "null!"}
                  </p>
                </div>
                <div className="flex items-center gap-1 border-b pb-1">
                  <HiOutlineMail
                    size="25"
                    className="text-primary border p-0.5 rounded-full shadow"
                  />{" "}
                  <p className="text-sm md:text-lg">{userInfo?.email}</p>
                </div>
                <div className="flex items-center gap-1 border-b pb-1">
                  <FiSmartphone
                    size="25"
                    className="text-primary border p-0.5 rounded-full shadow"
                  />{" "}
                  <p className="text-sm md:text-lg">
                    {userInfo?.phone ? userInfo?.phone : "null!"}
                  </p>
                </div>
              </div>
              {/* ------------------------------------------------------------- */}

              {/* Device selection section -------------------------------------- */}
              <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Device ------------------------------ */}
                <div className="card bg-slate-100 rounded-md cursor-pointer shadow-lg">
                  <div className="card-body py-2 px-4">
                    <h3 className="card-title text-primary">Current Device</h3>
                    <h3 className="font-medium">DEVICE NAME: Omar Khan</h3>
                    <p>
                      <span className="font-medium">DEVICE ID:</span>{" "}
                      343243454545
                    </p>
                    <p>
                      <span className="font-medium">DEVICE OWNER:</span>{" "}
                      omar@gmail.com
                    </p>
                  </div>
                </div>
                {/* ------------------------------------------ */}

                <div className="card bg-slate-100 rounded-md cursor-pointer shadow-lg">
                  <div className="card-body py-2 px-4">
                    <h3 className="card-title text-primary">Switch Device</h3>
                    <select className="select select-bordered w-full max-w-xs mb-1">
                      <option defaultChecked>Han Solo</option>
                      <option>Greedo</option>
                    </select>
                  </div>
                </div>
              </div>
              {/* --------------------------------------------------------------- */}

              <Outlet />
            </div>
            {/* Profile right end  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileModal;
