import React, { useRef } from "react";
import { BiRename } from "react-icons/bi";
import { GiBattery50 } from "react-icons/gi";

const DeviceEditModal = () => {
  const deviceNameRef = useRef("");
  const deviceIDRef = useRef("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const deviceName = deviceNameRef.current.value;
    const deviceId = deviceIDRef.current.value;
    // console.log(deviceName, deviceId);
  };
  return (
    <div>
      <input type="checkbox" id="device-edit-modal" className="modal-toggle" />
      <div className="backdrop-blur-sm modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Edit Device</h3>
          <div className="card px-4 py-2 flex items-center">
            <form onSubmit={handleSubmit}>
              <div className="w-full">
                <div className="form-control">
                  <label className="input-group">
                    <span className="my-2 bg-primary text-white text-xl">
                      <BiRename />
                    </span>
                    <input
                      ref={deviceNameRef}
                      type="text"
                      required
                      placeholder="Device Name"
                      className="input input-primary input-bordered w-full my-2"
                    />
                  </label>
                </div>
                <div className="form-control">
                  <label className="input-group">
                    <span className="my-2 bg-primary text-white text-xl">
                      <GiBattery50 />
                    </span>
                    <input
                      ref={deviceIDRef}
                      type="number"
                      required
                      placeholder="Device ID"
                      className="input input-primary input-bordered w-full my-2"
                    />
                  </label>
                </div>
              </div>
              <div className="modal-action">
                <label htmlFor="device-edit-modal" className="btn btn-sm">
                  No
                </label>
                <button className="btn btn-info text-white btn-sm">Add</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceEditModal;
