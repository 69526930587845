import React, { useState } from "react";
import { BiUserPlus } from "react-icons/bi";
import useUsers from "../../../hooks/useUsers";
import AddUserForm from "./AddUserForm";
import UserTable from "./UserTable";

const Users = () => {
  const [visible, setVisible] = useState(false);
  const { userList, refetch } = useUsers();

  const handler = () => {
    setVisible(!visible);
  };

  return (
    <main className="p-3">
      <div className="container mx-auto flex justify-between mb-2 border-b">
        <div className="left flex gap-3">
          <button
            onClick={handler}
            className="flex btn-primary text-white px-4 py-2 border rounded-md hover:bg-grary-50 hover:border-indigo-500 hover:text-gray-200"
          >
            <span className="px-1">
              <BiUserPlus size={23}></BiUserPlus>
            </span>
          </button>
        </div>
      </div>

      {/* collapsable form */}
      {visible ? <AddUserForm refetch={refetch}></AddUserForm> : <></>}

      {/* table */}
      <div className="container mx-auto mt-3">
        <UserTable userList={userList}></UserTable>
      </div>
    </main>
  );
};

export default Users;
