import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

const useUsers = () => {
    const [userList, setUserList] = useState([]);

    const url = `${process.env.REACT_APP_AUTH_API_BASE_URL}/users/user-list`;
    const { data: usersObject, isLoading, refetch } = useQuery('users', () => fetch(url)
        .then(res => res.json()));

    useEffect(() => {
        if (usersObject) {
            setUserList(usersObject?.data?.users);
        }
    }, [usersObject]);

    return { userList, isLoading, refetch }
};

export default useUsers;