import React from "react";
import { Marker, Polyline } from "@react-google-maps/api";

const PolyLineDetails = ({ coordinates, markerRef, renderArrowMarker }) => {
  // console.log(coordinates);

  const isValidCoordinate = (coordinate) => {
    return (
      coordinate &&
      typeof coordinate === "object" &&
      typeof coordinate.lat === "number" &&
      typeof coordinate.lng === "number" &&
      !isNaN(coordinate.lat) &&
      !isNaN(coordinate.lng)
    );
  };

  // Filter out invalid coordinates
  const validCoordinates = coordinates.filter(isValidCoordinate);

  const arrowSymbol = {
    path: "M 0,-2 L -2,0 L 0,2 L 2,0 z",
    strokeColor: "#333",
    fillColor: "#333",
    fillOpacity: 1,
    scale: 3,
  };

  return (
    <div>
      <Polyline
        path={validCoordinates}
        options={{
          strokeColor: "#F4751D",
          strokeOpacity: 0.6,
          strokeWeight: 10,
        }}
      />
      <Marker
        onLoad={(marker) => (markerRef.current = marker)}
        position={validCoordinates[0]}
        icon={{
          url: "/google-map-marker-green.png",
          scaledSize: new window.google.maps.Size(55, 55),
        }}
        title="Start"
      />
      <Marker
        position={validCoordinates[validCoordinates.length - 1]}
        icon={{
          url: "/google-map-maker-red.png",
          scaledSize: new window.google.maps.Size(40, 40),
        }}
        title="End"
      />
      {validCoordinates.map((coordinate, index) => (
        <Marker
          key={index}
          position={coordinate}
          icon={{
            path: arrowSymbol.path,
            strokeColor: arrowSymbol.strokeColor,
            fillColor: arrowSymbol.fillColor,
            fillOpacity: arrowSymbol.fillOpacity,
            scale: arrowSymbol.scale,
          }}
        />
      ))}
    </div>
  );
};

export default PolyLineDetails;
