import React, { useRef } from "react";
import { BsEnvelope, BsKey, BsPerson } from "react-icons/bs";
import registerVehicle from "../../assets/register-vehicle.png";

const Register = () => {
  const nameRef = useRef("");
  const emailRef = useRef("");
  const passwordRef = useRef("");
  const confirmPassRef = useRef("");

  //  firebase register page fuctionality setup

  const handleRegisterSubmit = (e) => {
    e.preventDefault();
    // const name = nameRef.current.value;
    // const email = emailRef.current.value;
    // const password = passwordRef.current.value;
    // const confirmPass = confirmPassRef.current.value;
  };

  return (
    <div
      className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 mt-8"
      style={{ minHeight: "calc(100vh - 67px)" }}
    >
      <div className="bg-green-50 flex flex-col justify-center items-center">
        <h2 className="text-center font-bold text-4xl mb-8">Register</h2>

        {/* Register Form  */}
        <form onSubmit={handleRegisterSubmit} className="w-full md:w-96">
          <div className="w-full md:w-96">
            <div className="form-control">
              <label className="input-group">
                <span className="my-2 bg-primary text-white text-xl">
                  <BsPerson />
                </span>
                <input
                  ref={nameRef}
                  type="text"
                  required
                  placeholder="Full Name"
                  className="input input-primary input-bordered w-full my-2"
                />
              </label>
            </div>
            <div className="form-control">
              <label className="input-group">
                <span className="my-2 bg-primary text-white text-xl">
                  <BsEnvelope />
                </span>
                <input
                  ref={emailRef}
                  type="text"
                  required
                  placeholder="Email"
                  className="input input-primary input-bordered w-full my-2"
                />
              </label>
            </div>
            <div className="form-control">
              <label className="input-group">
                <span className="my-2 bg-primary text-white text-xl">
                  <BsKey />
                </span>
                <input
                  ref={passwordRef}
                  type="password"
                  required
                  placeholder="Password"
                  className="input input-primary input-bordered w-full my-2"
                />
              </label>
            </div>
            <div className="form-control">
              <label className="input-group">
                <span className="my-2 bg-primary text-white text-xl">
                  <BsKey />
                </span>
                <input
                  ref={confirmPassRef}
                  type="password"
                  required
                  placeholder="Confirm Password"
                  className="input input-primary input-bordered w-full my-2"
                />
              </label>
            </div>
            <button
              type="submit"
              className="btn btn-primary w-full my-2 font-bold text-white text-lg"
            >
              Register
            </button>
          </div>
        </form>
      </div>

      <div className="bg-primary flex justify-center items-center">
        <div className="hidden md:block">
          <img src={registerVehicle} alt="vehicle-img" />
        </div>
      </div>
    </div>
  );
};

export default Register;
