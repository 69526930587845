import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Loading from "../../../components/Shared/Loading";
import { BmsContext } from "../../../App";


const RequireAuth = ({ children }) => {
  const { userInfo, isUserLoading, } = useContext(BmsContext);
  const location = useLocation();
  // const [sendEmailVerification] = useSendEmailVerification(auth);

  if (isUserLoading) {
    return <Loading />;
  }

  if (userInfo?.role?.length <= 0) {
    return <Navigate to="/login" state={{ from: location }} replace></Navigate>;
  }

  // if (user.providerData[0]?.providerId === "password" && !user.emailVerified) {
  //     return <div className='flex items-center my-10 p-8 mx-auto w-1/2 justify-center bg-slate-100 rounded-lg'>
  //         <div>
  //             <div className='flex justify-center'>
  //                 <img className='w-24 mb-6' src={sytiqhub} alt="" />
  //             </div>
  //             <h3 className='text-3xl mb-6 text-center'>Verify your email address</h3>
  //             <p className='mb-6 text-center'>Please confirm that you want to use this as Sytihub account email address. Once it's done you will able to see the Dashboard!</p>
  //             <div className='flex justify-center'>
  //                 <button
  //                     className='btn btn-wide btn-success text-white'
  //                     onClick={async () => {
  //                         await sendEmailVerification();
  //                         toast('Sent email');
  //                     }}
  //                 >
  //                     Click to verify

  //                 </button>
  //             </div>
  //         </div>
  //     </div>
  // }
  return children;
};

export default RequireAuth;
