import React, { useContext } from "react";
import { AiTwotoneDelete, AiTwotoneEdit } from "react-icons/ai";
import { GrView } from "react-icons/gr";
import { Link } from "react-router-dom";
import { BmsContext } from "../../../App";

const UserTable = ({ userList }) => {
  const {
    setEditUser,
    setIsEditUserModal,
    setDeleteUser,
    setIsDeleteUserModal,
  } = useContext(BmsContext);

  const handleUserEditModal = (user) => {
    setEditUser(user);
    setIsEditUserModal(true);
  };

  const handleUserDeleteModal = (user) => {
    setDeleteUser(user);
    setIsDeleteUserModal(true);
  };

  return (
    <div className="overflow-x-auto w-full shadow-md">
      <table className="table w-full">
        {/* <!-- head --> */}
        <thead className="text-white">
          <tr>
            <th className="bg-primary py-2.5"></th>
            <th className="bg-primary py-2.5">Name</th>
            <th className="bg-primary py-2.5">Email</th>
            <th className="bg-primary py-2.5">Phone</th>
            <th className="bg-primary py-2.5">Address</th>
            <th className="bg-primary py-2.5">Actions</th>
          </tr>
        </thead>
        <tbody>
          {userList ? (
            userList.map((user, index) => {
              const { name, email, phone, address, role, _id } = user;
              return (
                <tr key={_id}>
                  <th>{index + 1}</th>
                  <td>
                    <div>
                      <div className="font-bold">{name}</div>
                      <div className="badge bg-green-100 border-primary text-primary badge-sm">
                        <span className="uppercase">{role}</span>
                      </div>
                    </div>
                  </td>
                  <td>{email}</td>
                  <td>{phone}</td>
                  <td>{address}</td>
                  <th>
                    <div className="flex gap-2">
                      <Link
                        to={`/dashboard/users/${_id}`}
                        htmlFor="user-edit-modal"
                        className="modal-button"
                      >
                        <p className="text-indigo-700 bg-indigo-200 border p-1 rounded-full text-xl hover:border-indigo-900">
                          <GrView />
                        </p>
                      </Link>
                      <label
                        onClick={() => handleUserEditModal(user)}
                        htmlFor="user-edit-modal"
                        className="modal-button"
                      >
                        <p className="btn btn-sm text-primary bg-blue-100 border p-1 rounded-full text-xl hover:border-blue-900">
                          <AiTwotoneEdit />
                        </p>
                      </label>
                      <label
                        onClick={() => handleUserDeleteModal(user)}
                        htmlFor="user-delete-modal"
                        className="modal-button"
                      >
                        <p className="btn btn-sm text-red-500 bg-red-100 border p-1 rounded-full text-xl hover:border-red-900">
                          <AiTwotoneDelete />
                        </p>
                      </label>
                    </div>
                  </th>
                </tr>
              );
            })
          ) : (
            <p className="text-2xl">Ami kono user pai nai</p>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default UserTable;
