import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import LoadingContent from "../../../components/Shared/LoadingContent";
import DevicesTable from "./DevicesTable/DevicesTable";
import DeviceEditForAdmin from "./SingleDevice/DeviceEditForAdmin";

const AllDevices = () => {
  const [allDevices, setAllDevices] = useState([]);
  const [isDeviceEdit, setIsDeviceEdit] = useState(false);
  const [editDevice, setEditDevice] = useState({});
  const [searchInput, setSearchInput] = useState("");

  const authToken = localStorage.getItem('accessToken');

  const url = `${process.env.REACT_APP_API_BASE_URL}api/v1/device/user-devices?search=${searchInput}`;

  const { data, isLoading, refetch } = useQuery(
    ["all-devices", searchInput],
    () => fetch(url, {
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    }).then((res) => res.json())
  );

  useEffect(() => {
    if (data) {
      setAllDevices(data.data.allDevices);
    }
  }, [data]);

  return (
    <div className="m-3">
      {isDeviceEdit ? (
        <DeviceEditForAdmin
          setIsDeviceEdit={setIsDeviceEdit}
          editDevice={editDevice}
          refetch={refetch}
        />
      ) : (
        <div>
          {/* Search Input ---------------------------------------------- */}
          <div className="mb-4">
            <h3 className="text-center text-2xl font-medium text-slate-700 mb-3">
              Search Devices
            </h3>
            <div className="form-control w-full px-4 md:px-[10rem]">
              <div className="input-group">
                <input
                  onChange={(e) => setSearchInput(e.target.value)}
                  type="text"
                  placeholder="Search Devices by Email or Device ID …"
                  className="input input-bordered w-full"
                />
                <button className="btn btn-primary btn-square">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          {/* ----------------------------------------------------------- */}

          {/* Search Result --------------------------------------------- */}
          <div></div>
          {/* ----------------------------------------------------------- */}

          {/* Device Table ---------------------------------------------- */}
          <div className="container mx-auto mt-3">
            {!isLoading || !allDevices.length === 0 ? (
              <DevicesTable
                allDevices={allDevices}
                isLoading={isLoading}
                refetch={refetch}
                setIsDeviceEdit={setIsDeviceEdit}
                setEditDevice={setEditDevice}
              />
            ) : (
              <LoadingContent />
            )}
          </div>
          {/* --------------------------------------------------------- */}
        </div>
      )}
    </div>
  );
};

export default AllDevices;
