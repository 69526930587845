import React, { useContext, useState } from "react";
import { BmsContext } from "../../../../App";

const SystemCalibration = () => {
  const { statusDataE3ToE6 } = useContext(BmsContext);

  const [calibrationSettings, setCalibrationSettings] = useState({
    currentCalibration: statusDataE3ToE6?.E4?.currentCalibration || "",
    guardPlateAddress: statusDataE3ToE6?.E4?.guardPlateAddress || "",
    lowCapacityAlarmValue: statusDataE3ToE6?.E4?.lowCapacityAlarmValue || "",
    specialChargeSwitch: statusDataE3ToE6?.E4?.specialChargeSwitch || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCalibrationSettings({
      ...calibrationSettings,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle submit logic here
    console.log("Submitted calibration settings: ", calibrationSettings);
  };

  return (
    <form onSubmit={handleSubmit} className="overflow-x-auto mt-3 mb-5 px-1">
      <table className="table table-compact w-full">
        <thead className="text-white">
          <tr>
            <th className="bg-primary text-xs md:text-base">Project</th>
            <th className="bg-primary text-xs md:text-base">Machine</th>
            <th className="bg-primary text-xs md:text-base">Setting</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="w-48 md:w-80">
              <h3 className="md:text-base font-medium whitespace-normal">
                Current Calibration
              </h3>
            </td>
            <td>
              <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                {statusDataE3ToE6?.E4?.currentCalibration}
              </div>
            </td>
            <td className="w-48 md:w-64">
              <input
                type="number"
                name="currentCalibration"
                value={calibrationSettings.currentCalibration}
                onChange={handleChange}
                placeholder="Type here"
                className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
              />
            </td>
          </tr>
          <tr>
            <td className="w-48 md:w-80">
              <h3 className="md:text-base font-medium whitespace-normal">
                Guard Plate Address
              </h3>
            </td>
            <td>
              <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                {statusDataE3ToE6?.E4?.guardPlateAddress}
              </div>
            </td>
            <td className="w-48 md:w-64">
              <input
                type="number"
                name="guardPlateAddress"
                value={calibrationSettings.guardPlateAddress}
                onChange={handleChange}
                placeholder="Type here"
                className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
              />
            </td>
          </tr>
          <tr>
            <td className="w-48 md:w-80">
              <h3 className="md:text-base font-medium whitespace-normal">
                Low Capacity Alarm Value
              </h3>
            </td>
            <td>
              <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                {statusDataE3ToE6?.E4?.lowCapacityAlarmValue}
              </div>
            </td>
            <td className="w-48 md:w-64">
              <input
                type="number"
                name="lowCapacityAlarmValue"
                value={calibrationSettings.lowCapacityAlarmValue}
                onChange={handleChange}
                placeholder="Type here"
                className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
              />
            </td>
          </tr>
          <tr>
            <td className="w-48 md:w-80">
              <h3 className="md:text-base font-medium whitespace-normal">
                Special Charge Switch
              </h3>
            </td>
            <td>
              <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                {statusDataE3ToE6?.E4?.specialChargeSwitch}
              </div>
            </td>
            <td className="w-48 md:w-64">
              <input
                type="number"
                name="specialChargeSwitch"
                value={calibrationSettings.specialChargeSwitch}
                onChange={handleChange}
                placeholder="Type here"
                className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="flex justify-end pr-2">
        <div className="w-40 mt-4">
          <button
            type="submit"
            className="btn btn-primary btn-xs md:btn-sm w-40"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default SystemCalibration;
