import { async } from "@firebase/util";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { AiOutlinePhone, AiOutlineUsergroupAdd } from "react-icons/ai";
import { BsEnvelope, BsPerson } from "react-icons/bs";
import { TbAddressBook } from "react-icons/tb";
import { toast } from "react-toastify";
import useUsers from "../../../hooks/useUsers";

const UserEditModal = ({ user, setIsEditUserModal }) => {
  const { _id } = user;
  const [userDetail, setUserDetail] = useState({});
  const { name, email, phone, address } = userDetail;

  // getting users refetch function using useUsers hook
  const { refetch: usersRefetch } = useUsers();

  // Getting Clicked user data
  useEffect(() => {
    if (_id) {
      (async () => {
        const url = `${process.env.REACT_APP_API_BASE_URL}api/v1/bms/users/${_id}`;
        const { data } = await axios.get(url);
        setUserDetail(data.data);
      })();
    }
  }, [_id]);

  // Updating user data
  const handleUserUpdate = async () => {
    const url = `${process.env.REACT_APP_API_BASE_URL}api/v1/bms/users/${_id}`;
    const payload = { name, email, phone, address };
    const res = await axios.patch(url, payload);
    if (res) {
      setIsEditUserModal(false);
      usersRefetch();
      toast.success("User Updated Successfully.");
    }
  };

  // clossing edit modal ... if click on close button
  const handleCloseUserModal = () => {
    setIsEditUserModal(false);
  };

  return (
    <div>
      <input type="checkbox" id="user-edit-modal" className="modal-toggle" />
      <div className="backdrop-blur-sm modal">
        <div className="modal-box w-11/12 max-w-5xl bg-slate-300 py-4">
          <h3 className="font-bold text-lg md:text-xl text-center">
            Edit user info for{" "}
            <span className="text-primary">{userDetail?.email}</span>
          </h3>
          <label
            onClick={handleCloseUserModal}
            className="btn btn-sm btn-circle border-red-500 bg-red-200 text-red-500 hover:bg-red-100 hover:text-red-600 hover:border-red-500 absolute right-2 top-2"
          >
            ✕
          </label>

          {/* Update form start here ---------------------- */}
          <div>
            <div className="card px-4 pt-2 flex items-center w-full">
              <form>
                <div className="grid lg:grid-cols-2 gap-x-4 w-full justify-center items-center">
                  <div className="form-control">
                    <label className="input-group ">
                      <span className="my-2 bg-primary text-white text-xl">
                        <BsPerson />
                      </span>
                      <input
                        value={userDetail.name}
                        onChange={(e) => {
                          setUserDetail({
                            ...userDetail,
                            name: e.target.value,
                          });
                        }}
                        type="text"
                        required
                        placeholder="Full Name"
                        className="input input-primary input-bordered w-full my-2"
                      />
                    </label>
                  </div>
                  <div className="form-control">
                    <label className="input-group">
                      <span className="my-2 bg-primary text-white text-xl">
                        <BsEnvelope />
                      </span>
                      <input
                        value={userDetail.email}
                        onChange={(e) => {
                          setUserDetail({
                            ...userDetail,
                            email: e.target.value,
                          });
                        }}
                        type="email"
                        required
                        placeholder="Email"
                        className="input input-primary input-bordered w-full my-2"
                      />
                    </label>
                  </div>
                  <div className="form-control">
                    <label className="input-group">
                      <span className="my-2 bg-primary text-white text-xl">
                        <AiOutlinePhone />
                      </span>
                      <input
                        value={userDetail.phone}
                        onChange={(e) => {
                          setUserDetail({
                            ...userDetail,
                            phone: e.target.value,
                          });
                        }}
                        type="number"
                        required
                        placeholder="Phone"
                        className="input input-primary input-bordered w-full my-2"
                      />
                    </label>
                  </div>
                  <div className="form-control">
                    <label className="input-group">
                      <span className="my-2 bg-primary text-white text-xl">
                        <TbAddressBook />
                      </span>
                      <input
                        value={userDetail.address}
                        onChange={(e) => {
                          setUserDetail({
                            ...userDetail,
                            address: e.target.value,
                          });
                        }}
                        type="text"
                        required
                        placeholder="Address"
                        className="input input-primary input-bordered w-full my-2"
                      />
                    </label>
                  </div>
                  <div className="form-control">
                    <label className="input-group ">
                      <span className="my-2 bg-primary text-white text-xl">
                        <AiOutlineUsergroupAdd />
                      </span>
                      <select className="select select-primary w-[22rem] my-2 ">
                        <option disabled>What type of user?</option>
                        <option defaultValue={userDetail.role === "Admin"}>
                          Admin
                        </option>
                        <option defaultValue={userDetail.role === "User"}>
                          User
                        </option>
                      </select>
                    </label>
                  </div>
                </div>
              </form>
              <div className="modal-action flex justify-center my-2 w-full">
                <label
                  onClick={() => {
                    handleUserUpdate();
                  }}
                  htmlFor="user-edit-modal"
                  className="btn w-2/6 btn-primary text-white"
                >
                  Update
                </label>
              </div>
            </div>
          </div>
          {/* Update form end here ---------------------- */}
        </div>
      </div>
    </div>
  );
};

export default React.memo(UserEditModal);
