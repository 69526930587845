import React, { useContext, useState } from "react";
import { BmsContext } from "../../../../App";

const CurrentProtection = () => {
  const { statusData } = useContext(BmsContext);

  const initialFormData = {
    chargeOCP: statusData?.E1?.chargeOCP || "",
    chargeOCRT: statusData?.E1?.chargeOCRT || "",
    dischargeOCP: statusData?.E1?.dischargeOCP || "",
    dischargeOCRT: statusData?.E1?.dischargeOCRT || "",
    peakDischargeCurrent: statusData?.E1?.peakDischargeCurrent || "",
    shortCircuitCurrent: statusData?.E1?.shortCircuitCurrent || "",
    shortCircuitTime: statusData?.E1?.shortCircuitTime || "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
  };

  return (
    <div className="overflow-x-auto mt-3 mb-5 px-1">
      <form onSubmit={handleSubmit}>
        <table className="table table-compact w-full">
          <thead className="text-white">
            <tr>
              <th className="bg-primary text-xs md:text-base">Project</th>
              <th className="bg-primary text-xs md:text-base">Machine</th>
              <th className="bg-primary text-xs md:text-base">Setting</th>
            </tr>
          </thead>
          <tbody>
            {/* row 1 */}
            <tr>
              <td className="w-48 md:w-80">
                <h3 className="md:text-base font-medium whitespace-normal">
                  Charge OCP - (A)
                </h3>
              </td>
              <td>
                <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                  {statusData?.E1?.chargeOCP}
                </div>
              </td>
              <td className="w-48 md:w-64">
                <input
                  type="number"
                  defaultValue={statusData?.E1?.chargeOCP}
                  name="chargeOCP"
                  placeholder="Type here"
                  className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
                  onChange={handleChange}
                />
              </td>
            </tr>
            {/* row 2 */}
            <tr>
              <td className="w-48 md:w-80">
                <h3 className="md:text-base font-medium whitespace-normal">
                  Charge OCRT - (sec)
                </h3>
              </td>
              <td>
                <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                  {statusData?.E1?.chargeOCRT}
                </div>
              </td>
              <td className="w-48 md:w-64">
                <input
                  type="number"
                  defaultValue={statusData?.E1?.chargeOCRT}
                  name="chargeOCRT"
                  placeholder="Type here"
                  className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
                  onChange={handleChange}
                />
              </td>
            </tr>
            {/* row 3 */}
            <tr>
              <td className="w-48 md:w-80">
                <h3 className="md:text-base font-medium whitespace-normal">
                  Discharge OCP - (A)
                </h3>
              </td>
              <td>
                <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                  {statusData?.E1?.dischargeOCP}
                </div>
              </td>
              <td className="w-48 md:w-64">
                <input
                  type="number"
                  defaultValue={statusData?.E1?.dischargeOCP}
                  name="dischargeOCP"
                  placeholder="Type here"
                  className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
                  onChange={handleChange}
                />
              </td>
            </tr>
            {/* row 4 */}
            <tr>
              <td className="w-48 md:w-80">
                <h3 className="md:text-base font-medium whitespace-normal">
                  Discharge OCRT -(sec)
                </h3>
              </td>
              <td>
                <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                  {statusData?.E1?.dischargeOCRT}
                </div>
              </td>
              <td className="w-48 md:w-64">
                <input
                  type="number"
                  defaultValue={statusData?.E1?.dischargeOCRT}
                  name="dischargeOCRT"
                  placeholder="Type here"
                  className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
                  onChange={handleChange}
                />
              </td>
            </tr>
            {/* row 5 */}
            <tr>
              <td className="w-48 md:w-80">
                <h3 className="md:text-base font-medium whitespace-normal">
                  Peak Discharge current - (A)
                </h3>
              </td>
              <td>
                <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                  {statusData?.E1?.peakDischargeCurrent}
                </div>
              </td>
              <td className="w-48 md:w-64">
                <input
                  type="number"
                  defaultValue={statusData?.E1?.peakDischargeCurrent}
                  name="peakDischargeCurrent"
                  placeholder="Type here"
                  className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
                  onChange={handleChange}
                />
              </td>
            </tr>
            {/* row 6 */}
            <tr>
              <td className="w-48 md:w-80">
                <h3 className="md:text-base font-medium whitespace-normal">
                  Short circuit current - (A)
                </h3>
              </td>
              <td>
                <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                  {statusData?.E1?.shortCircuitCurrent}
                </div>
              </td>
              <td className="w-48 md:w-64">
                <input
                  type="number"
                  defaultValue={statusData?.E1?.shortCircuitCurrent}
                  name="shortCircuitCurrent"
                  placeholder="Type here"
                  className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
                  onChange={handleChange}
                />
              </td>
            </tr>
            {/* row 7 */}
            <tr>
              <td className="w-48 md:w-80">
                <h3 className="md:text-base font-medium whitespace-normal">
                  Short circuit time - (microsec)
                </h3>
              </td>
              <td>
                <div className="badge bg-green-50 text-black border-green-400 pb-1 px-2 gap-2 md:text-base font-medium">
                  {statusData?.E1?.shortCircuitTime}
                </div>
              </td>
              <td className="w-48 md:w-64">
                <input
                  type="number"
                  defaultValue={statusData?.E1?.shortCircuitTime}
                  name="shortCircuitTime"
                  placeholder="Type here"
                  className="input input-xs md:input-sm input-bordered input-primary w-full mr-3"
                  onChange={handleChange}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="flex justify-end pr-2">
          <div className="w-40 mt-4">
            <button
              type="submit"
              className="btn btn-primary btn-xs md:btn-sm w-40"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CurrentProtection;
