import AlarmHistory from "../pages/dashboard/BatteryBMS/AlarmHistory";
import BatteryBMS from "../pages/dashboard/BatteryBMS/BatteryBMS";
import Controlls from "../pages/dashboard/BatteryBMS/Controlls";
import DataMonitoring from "../pages/dashboard/BatteryBMS/DataMonitoring";
import Devices from "../pages/dashboard/Devices/Devices";
import LiveLocation from "../pages/dashboard/LiveLocation/LiveLocation";
import Notifications from "../pages/dashboard/Notifications/Notifications";

export const dashboardRoutes = [
  { path: "battery-bms", name: "BatteryBMS", Component: BatteryBMS },
  { path: "live-location", name: "LiveLocation", Component: LiveLocation },
  { path: "notifications", name: "Notifications", Component: Notifications },
  {
    path: "data-monitoring",
    name: "DataMonitoring",
    Component: DataMonitoring,
  },

  { path: "alarm-history", name: "AlarmHistory", Component: AlarmHistory },
  { path: "controlls", name: "Controlls", Component: Controlls },

  { path: "devices", name: "Devices", Component: Devices },
];
