import React from "react";

const Settings = () => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mt-5 mb-5 w-full">
      <div>
        <label htmlFor="setting-modal" className="modal-button cursor-pointer">
          <p className="btn btn-primary w-full">System Reset</p>
        </label>
      </div>
      <div>
        <label htmlFor="setting-modal" className="modal-button cursor-pointer">
          <p className="btn bg-orange-500 border-orange-500 hover:bg-orange-600 hover:border-orange-600 w-full text-white shadow-lg">
            Factory Data Reset
          </p>
        </label>
      </div>
      <div>
        <label htmlFor="setting-modal" className="modal-button cursor-pointer">
          <p className="btn btn-accent w-full">Reset Password</p>
        </label>
      </div>
      <div>
        <label htmlFor="setting-modal" className="modal-button cursor-pointer">
          <p className="btn btn-primary w-full">Firewall</p>
        </label>
      </div>
    </div>
  );
};

export default Settings;
