import React from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const HistoryChart = () => {
  const data = [
    {
      time: "16:20",
      voltage: 3.8,
      current: 2.4,
      temperature: 46,
    },
    {
      time: "16:20",
      voltage: 3.7,
      current: 2.3,
      temperature: 43,
    },
    {
      time: "16:20",
      voltage: 3.4,
      current: 2.4,
      temperature: 41,
    },
    {
      time: "16:20",
      voltage: 3.2,
      current: 2.1,
      temperature: 38,
    },
    {
      time: "16:20",
      voltage: 3.9,
      current: 2.4,
      temperature: 33,
    },
    {
      time: "16:20",
      voltage: 3.4,
      current: 2.5,
      temperature: 48,
    },
    {
      time: "16:20",
      voltage: 3.8,
      current: 0,
      temperature: 44,
    },
    {
      time: "16:20",
      voltage: 3.2,
      current: -1,
      temperature: 45,
    },
    {
      time: "16:20",
      voltage: 3.8,
      current: 2.4,
      temperature: 40,
    },
    {
      time: "16:20",
      voltage: 3.7,
      current: 2.4,
      temperature: 42,
    },
    {
      time: "16:20",
      voltage: 3.8,
      current: 2.2,
      temperature: 44,
    },
  ];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 10,
          right: 0,
          left: 0,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time" />
        <YAxis yAxisId="left" />
        <YAxis yAxisId="right" orientation="right" />
        <Tooltip />
        <Legend />
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="voltage"
          stroke="#008f7b"
          activeDot={{ r: 8 }}
        />
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="current"
          stroke="#cea91d"
        />
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="temperature"
          stroke="#ad4738"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default HistoryChart;
