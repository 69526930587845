import React, { useEffect, useRef, useState } from "react";
// import { useSendPasswordResetEmail, useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { BsKey, BsTelephoneForward } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import registerVehicle from "../../assets/register-vehicle.png";
import Loading from "../../components/Shared/Loading";
// import auth from '../../firebase.init';
// import useToken from '../../hooks/useToken';
import axios from "axios";
import { toast } from "react-toastify";

const Login = () => {
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [user, setUser] = useState("");

  // const { admin, adminLoading } = useContext(BmsContext);
  // const { singleUser, isAPILoading, refetch } = useUserDetail(userId);

  // take user data
  const phoneRef = useRef("");
  const passwordRef = useRef("");
  const navigate = useNavigate();
  const location = useLocation();

  // firebase function
  // const [
  //     signInWithEmailAndPassword,
  //     user,
  //     loading,
  //     error,
  // ] = useSignInWithEmailAndPassword(auth);

  // email Varification
  // const [sendPasswordResetEmail, sending] = useSendPasswordResetEmail(auth);

  // Token hook
  // const [token] = useToken(user);
  let from = location.state?.from?.pathname || "/dashboard/admin-dashboard";
  let from2 = location.state?.from?.pathname || "/dashboard/battery-bms";

  useEffect(() => {
    if (user.role === "super-admin") {
      navigate(from, { replace: true });
    }
    if (user.role === "user") {
      navigate(from2, { replace: true });
    }
  }, [user, from, from2, navigate]);

  // loading here
  if (loadingLogin) {
    return <Loading />;
  }

  // Login Form Submit
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setLoadingLogin(true);

    const phone = phoneRef.current.value;
    const password = passwordRef.current.value;

    const payload = {
      phone,
      password: password,
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_API_BASE_URL}/users/login-user`,
      payload
    );

    const user = data?.data?.userInfo;
    const token = data?.data?.token;

    // Setting error stat based on server response
    // if (data.status === "Failed") {
    //   setLoginError(data.error);
    // }

    if (user?.role) {
      localStorage.setItem("accessToken", token);
      localStorage.setItem("currentUid", user?.userId);
      setUser(user);

      e.target.reset();
      toast.success("Login Success");
      setLoadingLogin(false);
      //   navigate("/dashboard");
    }
  };

  // error showing
  // let errorElement;
  // if (loginError) {
  //     errorElement = <p className='text-danger'>Error: {loginError}</p>
  // }

  // forget password function
  // const hanleForgetPassword = async () => {
  //     const email = emailRef.current.value;
  //     if (email) {
  //         await sendPasswordResetEmail(email);
  //         console.log("Sent email");
  //     }
  //     else {
  //         console.log("Please enter your email address");
  //     }
  // }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 h-screen">
      <div className="bg-green-50 flex flex-col justify-center items-center">
        <h2 className="text-center font-bold text-4xl mb-8">Login</h2>

        {/* Register Form  */}
        <form onSubmit={handleLoginSubmit} className="w-full p-4 md:w-96">
          <div className="w-full md:w-96 ">
            <div className="form-control">
              <label className="input-group">
                <span className="my-2 bg-primary text-white text-xl">
                  <BsTelephoneForward />
                </span>
                <input
                  ref={phoneRef}
                  type="text"
                  required
                  placeholder="Phone"
                  className="input input-primary input-bordered w-full my-2"
                />
              </label>
            </div>
            <div className="form-control">
              <label className="input-group">
                <span className="my-2 bg-primary text-white text-xl">
                  <BsKey />
                </span>
                <input
                  ref={passwordRef}
                  type="password"
                  required
                  placeholder="Password"
                  className="input input-primary input-bordered w-full my-2"
                />
              </label>
            </div>
            {/* <div className="flex justify-end">
              <p className="text-blue-500 cursor-pointer my-2">
                Forget Sytihub password?
              </p>
            </div> */}
            <button
              type="submit"
              className="btn btn-primary w-full my-2 font-bold text-white text-lg"
            >
              Login
            </button>
          </div>
        </form>

        {/* divider and google login */}
        {/* <div className='w-full md:w-96'>
                    <div className="flex justify-center items-center my-5">
                        <div className='h-1 w-20 border-t-[2px] border-primary'></div>
                        <div className='mx-3 text-xl font-bold'>OR</div>
                        <div className='h-1 w-20 border-t-[2px] border-primary'></div>
                    </div>
                </div> */}
      </div>

      <div className="md:bg-primary flex justify-center items-center">
        <div className="hidden md:block">
          <img src={registerVehicle} alt="vehicle-img" />
        </div>
      </div>
    </div>
  );
};
export default Login;
