import React, { useContext, useEffect, useState } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import axios from 'axios';
import { BmsContext } from '../../../App';
import useDevicesByPhone from '../../../hooks/useDevicesByPhone';
import { toast } from 'react-toastify';

const Geofence = ({ isLoaded, setGeofenceMarker, geofenceCoords, currentCoordinate }) => {
    const { userInfo } = useContext(BmsContext);
    const [autocomplete, setAutocomplete] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [geofences, setGeofences] = useState([]);

    const [userDeviceList, isLoading, refetch] = useDevicesByPhone(
        userInfo?.phone
    );

    const authToken = localStorage.getItem('accessToken');

    const createNotification = async (payload) => {
        const notifyApiUrl = `${process.env.REACT_APP_API_BASE_URL}api/v1/device/notification?phone=${userInfo?.phone}`;

        const { data } = await axios.post(notifyApiUrl,
            payload,
            {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        return data;
    }

    // getting geofences by device id -----------------------
    useEffect(() => {
        (async () => {
            const geofenceUrl = `${process.env.REACT_APP_API_BASE_URL}api/v1/device/geofence/${selectedDevice?.deviceId}?phone=${userInfo?.phone}`;

            const { data } = await axios.get(geofenceUrl, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                },
            });
            setGeofences(data?.data);
        })();

    }, [selectedDevice, userInfo, authToken]);
    // -------------------------------------------------


    useEffect(() => {
        const checkLocationInsideGeofence = (location, geofence) => {
            const geofenceCoords = geofence?.geofenceCoords;
            let insideGeofence;

            if (!geofenceCoords) return; // If geofenceCoords is not set yet, do nothing

            if (geofenceCoords.type === "circle") {
                const { center, radius } = geofenceCoords;
                const distance = window.google.maps.geometry?.spherical.computeDistanceBetween(
                    new window.google.maps.LatLng(location),
                    new window.google.maps.LatLng(center)
                );
                const isInside = distance <= radius;
                insideGeofence = isInside;

            } else if (geofenceCoords.type === "polygon") {
                const polygon = new window.google.maps.Polygon({
                    paths: geofenceCoords.paths.map((coords) => ({
                        lat: coords.lat,
                        lng: coords.lng,
                    })),
                });
                const isInside = window.google.maps.geometry?.poly.containsLocation(
                    new window.google.maps.LatLng(location),
                    polygon
                );
                insideGeofence = isInside;

            } else if (geofenceCoords.type === "rectangle") {
                const { bounds } = geofenceCoords;
                const sw = new window.google.maps.LatLng(bounds.sw.lat, bounds.sw.lng);
                const ne = new window.google.maps.LatLng(bounds.ne.lat, bounds.ne.lng);
                const rectangleBounds = new window.google.maps.LatLngBounds(sw, ne);
                const isInside = rectangleBounds.contains(new window.google.maps.LatLng(location));
                insideGeofence = isInside;

            }

            return {
                insideGeofence: insideGeofence,
                deviceId: geofence?.deviceId,
                geofenceId: geofence?._id,
                geofence
            };
        };


        if (isLoaded) {
            // const locationToCheck = { lat: 23.8177277, lng: 90.4149659 };
            const locationToCheck = currentCoordinate;
            for (const geofence of geofences) {
                const geofenceStatus = checkLocationInsideGeofence(locationToCheck, geofence);
                const { geofenceEnterAlert, geofenceExitAlert } = geofence;
                const { insideGeofence } = geofenceStatus;

                const payload = {
                    deviceId: selectedDevice?.deviceId,
                    phone: userInfo?.phone,
                    geofenceId: geofence?._id,
                    insideGeofence: insideGeofence
                }

                if (insideGeofence === true && geofenceEnterAlert === true) {
                    (async () => {
                        const res = await createNotification(payload);
                    })();
                } else if (insideGeofence === false && geofenceExitAlert === true) {
                    (async () => {
                        const res = await createNotification(payload);
                    })();
                }
            }
        }


    }, [geofences, isLoaded, selectedDevice?.deviceId, userInfo?.phone]);


    // finding selected device  ---------------------------
    useEffect(() => {
        const currentDevice = userDeviceList?.find(device => device.deviceSelected);
        setSelectedDevice(currentDevice);
    }, [userDeviceList]);
    // ---------------------------------------------

    // console.log(selectedDevice);

    const handleLoad = (autocomplete) => {
        setAutocomplete(autocomplete);
    };

    const handlePlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            if (place.geometry) {
                const location = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                };
                setSearchText(place.formatted_address);
                setGeofenceMarker([{ position: location }]);
            } else {
                console.error('No geometry found for input:', place.name);
            }
        } else {
            console.error('Autocomplete is not loaded yet!');
        }
    };



    // handle geofence create --------------------------
    const handleGeofenceCreate = (e) => {
        e.preventDefault();
        const address = e.target.address.value;
        const geofenceType = e.target.geofenceType.value;
        const geofenceName = e.target.geofenceName.value;
        const isHaltAlert = e.target.isHaltAlert.checked;
        const haltAlertValue = e.target.haltAlertValue.value;
        const geofenceEnterAlert = e.target.isGeofenceEnter.checked;
        const geofenceExitAlert = e.target.isGeofenceExit.checked;
        const deviceId = selectedDevice?.deviceId;

        const payload = { address, geofenceType, geofenceName, isHaltAlert, haltAlertValue, geofenceEnterAlert, geofenceExitAlert, geofenceCoords, deviceId };

        (async () => {
            const res = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}api/v1/device/geofence/${deviceId}?phone=${userInfo?.phone}`,
                payload,
                {
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (res.data.success) {
                toast.success("Geofence Added Successfully", { position: "top-left" });
                e.target.reset();
            }

        })();

    }
    // -----------------------------------------------


    return (
        <div>

            <form onSubmit={(e) => handleGeofenceCreate(e)}>
                <div className='flex gap-8 items-center justify-between mb-4'>
                    <label htmlFor="address">Address: </label>
                    <div className='w-full flex justify-end'>
                        {isLoaded &&
                            <Autocomplete onLoad={handleLoad} onPlaceChanged={handlePlaceChanged}>
                                <input
                                    type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} id="address" placeholder="address" className="input input-bordered input-sm w-full md:w-60"
                                />
                            </Autocomplete>}
                    </div>
                </div>
                <div className='flex gap-8 items-center justify-between mb-4'>
                    <label htmlFor="geofenceType">Geofence Type: </label>
                    <select className="select select-bordered select-sm w-full md:w-60 font-normal" id='geofenceType'>
                        <option disabled selected>Select</option>
                        <option>Loading</option>
                        <option>Unloading</option>
                        <option>Parking</option>
                    </select>
                </div>
                <div className='flex gap-8 items-center justify-between mb-4'>
                    <label htmlFor="geofenceName">Geofence Name: </label>
                    <div className='w-full flex justify-end'>
                        <input
                            type="text" id="geofenceName" placeholder="geofence name" className="input input-bordered input-sm w-full md:w-60"
                        />
                    </div>
                </div>
                <div className='flex gap-8 items-center justify-between mb-4'>
                    <label htmlFor="isHaltAlert">Halt Alert: </label>
                    <div className='flex gap-4'>
                        <input className='w-4' type="checkbox" name="" id="isHaltAlert" />
                        <input className='w-12 border rounded-md px-2' type="text" name="" defaultValue={60} disabled id="haltAlertValue" />
                        <label>Min.</label>
                    </div>
                </div>
                <div className='flex gap-8 items-center justify-between mb-4'>
                    <label htmlFor="isGeofenceAlert">Geofence Alert: </label>
                    <div className='flex gap-4'>
                        <div className='flex gap-2'>
                            <input className='w-4' type="checkbox" name="" id="isGeofenceEnter" />
                            <label>Entering</label>
                        </div>
                        <div className='flex gap-2'>
                            <input className='w-4' type="checkbox" name="" id="isGeofenceExit" />
                            <label>Exiting</label>
                        </div>
                    </div>
                </div>
                <button className='btn btn-primary btn-sm bg-gradient-to-r from-[#040327] to-[#0D7FBF] text-white'>Create Geofence</button>
            </form>





            <div>

                {/* <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={2}>
                    {markers.map((marker, index) => (
                        <Marker key={index} position={marker.position} />
                    ))}
                </GoogleMap> */}
            </div>


















        </div>
    );
};

export default Geofence;