import React from 'react';
import vehicleHeroImg from '../../assets/vehicle-gps-hero.webp';

const HomeHero = () => {
    return (
        <div name='home' className='bg-gradient-to-r from-primary to-slate-800'>
            <div className='container mx-auto flex flex-wrap justify-between items-center px-4 min-h-screen pt-[72px]'>
                <div className='w-full md:w-[600px] order-2 md:order-1 text-center md:text-left'>
                    <h1 className='text-3xl md:text-6xl font-bold text-white'>Reliable Vehicle
                        <span className='text-white'> Tracking System</span>
                    </h1>
                    <p className='my-8 text-lg text-white'>Understanding costs related to vehicle maintenance, fuel consumption, driver performance, insurance and service is an important function for any business. Smart BMS make it possible to enhance safety, increase efficiency, achieve compliance and promote sustainability.</p>
                </div>
                <div className='order-1 md:order-2'>
                    <img src={vehicleHeroImg} alt="hero-img" className='w-68 py-10 md:w-[500px]' />
                </div>
            </div>
        </div>
    );
};

export default HomeHero;