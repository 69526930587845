import React from 'react';
import { RiRadioButtonLine } from 'react-icons/ri';

const DeviceActive = () => {
    return (
        <div className='flex items-center gap-1 text-sm rounded-full px-1 bg-green-50 text-green-500'>
            <RiRadioButtonLine />
            <span> Active</span>
        </div>
    );
};

export default DeviceActive;