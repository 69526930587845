import { useEffect, useState } from "react";
import { useQuery } from "react-query";

const useDevicesByPhone = (phone) => {
  const [userDeviceList, setUserDeviceList] = useState([]);

  const authToken = localStorage.getItem('accessToken');

  const url = `${process.env.REACT_APP_API_BASE_URL}api/v1/device/my-devices?phone=${phone}`;
  const {
    data: userDevices,
    isLoading,
    refetch,
  } = useQuery(["userDevices", phone], () => fetch(url, {
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json()));

  useEffect(() => {
    if (userDevices) {
      setUserDeviceList(userDevices?.data?.myDevices);
    }
  }, [userDevices, phone, refetch]);

  return [userDeviceList, isLoading, refetch];
};

export default useDevicesByPhone;
