import React from "react";

const DeviceDeleteModal = () => {
  return (
    <div>
      <input
        type="checkbox"
        id="device-delete-modal"
        className="modal-toggle"
      />
      <div className="backdrop-blur-sm modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">
            Are you sure? You want to delete the device?
          </h3>
          <div className="modal-action">
            <label htmlFor="device-delete-modal" className="btn">
              No
            </label>
            <label
              htmlFor="device-delete-modal"
              className="btn btn-error text-white"
            >
              Yes
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceDeleteModal;
