import React, { useState } from "react";
import { FaCarBattery, FaMapMarkedAlt } from "react-icons/fa";
import {
  MdOutlineElectricalServices,
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { RiAlarmWarningLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { HiServer } from "react-icons/hi";
import { FiSettings } from "react-icons/fi";

const UserMenuItems = () => {

  const [toggleBatteryBMS, setToggleBatteryBMS] = useState(false);

  // handling battery bms toggle

  const handleToggleBatteryBMS = () => {
    setToggleBatteryBMS(!toggleBatteryBMS);
  };

  // Active route styles
  let activeStyle = {
    color: "#2C4095",
    backgroundColor: "white",
  };


  return (
    <>
      <li
        className={`border-b border-slate-600 text-white font-bold ${toggleBatteryBMS ? "bg-primary/30 rounded-lg" : ""
          }`}
      >
        <NavLink
          to="battery-bms"
          onClick={() => handleToggleBatteryBMS()}
          className="pt-1.5 pb-2 my-1.5 hover:text-primary hover:border-secondary flex items-center justify-between gap-2 hover:bg-white rounded px-2 hover:shadow"
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          <div className="flex items-center gap-2">
            <FaCarBattery /> Battery BMS{" "}
          </div>
          <div>
            {toggleBatteryBMS ? (
              <MdOutlineKeyboardArrowDown size={25} />
            ) : (
              <MdOutlineKeyboardArrowRight size={25} />
            )}{" "}
          </div>
        </NavLink>

        <ul
          className={`pb-2 ${toggleBatteryBMS
            ? "block transition ease-in-out delay-150"
            : "hidden"
            }`}
        >
          <li className="mx-3 text-sm font-medium my-1">
            <NavLink
              to={"parameter-settings"}
              className="flex items-center gap-1 hover:text-accent pt-0.5 pb-1 px-1.5 rounded"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <FiSettings className="text-md" />
              <span>Settings</span>
            </NavLink>
          </li>

          <li className="mx-3 text-sm font-medium my-1">
            <NavLink
              to={"alarm-history"}
              className="flex items-center gap-1 hover:text-accent pt-0.5 pb-1 px-1.5 rounded"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <RiAlarmWarningLine className="text-md" />{" "}
              <span>History</span>
            </NavLink>
          </li>

          <li className="mx-3 text-sm font-medium my-1">
            <NavLink
              to={"controlls"}
              className="flex items-center gap-1 hover:text-accent pt-0.5 pb-1 px-1.5 rounded"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              <MdOutlineElectricalServices className="text-md" />{" "}
              <span>Controlls</span>
            </NavLink>
          </li>
        </ul>
      </li>

      <li className="border-b border-slate-600 text-white font-bold">
        <NavLink
          to="live-location"
          className="pt-1.5 pb-2 my-1.5 hover:text-primary hover:border-secondary flex items-center gap-2 hover:bg-white rounded px-2 hover:shadow"
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          {" "}
          <FaMapMarkedAlt /> Live Location
        </NavLink>
      </li>

      <li className="border-b border-slate-600 text-white font-bold">
        <NavLink
          to="devices"
          className="pt-1.5 pb-2 my-1.5 hover:text-primary hover:border-secondary flex items-center gap-2 hover:bg-white rounded px-2 hover:shadow"
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          {" "}
          <HiServer /> My Devices
        </NavLink>
      </li>

      <li className="border-b border-slate-600 text-white font-bold">
        <NavLink
          to="profile"
          className="pt-1.5 pb-2 my-1.5 hover:text-primary hover:border-secondary flex items-center gap-2 hover:bg-white rounded px-2 hover:shadow"
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          {" "}
          <CgProfile /> My Profile
        </NavLink>
      </li>
    </>
  );
};

export default UserMenuItems;
