import React from 'react';
import { CgProfile } from 'react-icons/cg';
import { MdLogout } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ProfileAvatar = () => {
    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('currentUid');
        navigate("/");

        toast.success('Log out successfully.', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    };

    return (
        <div className="avatar dropdown dropdown-end">

            <div className="w-10 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2 cursor-pointer">
                <label tabIndex="0" className='cursor-pointer'>
                    {/* <img src="https://placeimg.com/192/192/people" alt='image' /> */}
                    <p className='text-center text-xs pt-3'>Profile</p>
                </label>
            </div>


            <ul tabIndex="0" className="dropdown-content p-2 shadow-lg border bg-base-100 rounded-box w-52">
                <li className='hover:border-r-2 hover:text-secondary border-secondary rounded-none'>
                    <label htmlFor="profile-modal" className="modal-button cursor-pointer">
                        <p className='flex items-center py-2 px-2 rounded-l-full mb-1 hover:shadow-md hover:bg-yellow-50'> <CgProfile className='mr-1' size="20" /> <span>My Profile</span></p>
                    </label>
                </li>
                <li className='hover:border-r-2 hover:text-secondary border-secondary rounded-none'>
                    <a className='flex items-center py-2 px-2 rounded-l-full mb-1 hover:shadow-md hover:bg-yellow-50 cursor-pointer'> <MdLogout className='mr-1' size="20" /> <span onClick={logout}>Signout</span></a>
                </li>
            </ul>

        </div>
    );
};

export default ProfileAvatar;