import React, { useRef } from "react";
import { BiPlus } from "react-icons/bi";
import { TbAddressBook } from "react-icons/tb";
import { AiOutlineUsergroupAdd, AiOutlinePhone } from "react-icons/ai";
import { BsEnvelope, BsKey, BsPerson } from "react-icons/bs";
import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";
import auth from "../../../firebase.init";
import Loading from "../../../components/Shared/Loading";
import axios from "axios";

const AddUserForm = ({ refetch }) => {
  const nameRef = useRef("");
  const emailRef = useRef("");
  const phoneRef = useRef("");
  const addressRef = useRef("");
  const passwordRef = useRef("");
  const userRef = useRef("");

  const [createUserWithEmailAndPassword, user, loading, error] =
    useCreateUserWithEmailAndPassword(auth);

  if (loading) {
    return <Loading />;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = nameRef.current.value;
    const email = emailRef.current.value;
    const phone = phoneRef.current.value;
    const address = addressRef.current.value;
    const password = passwordRef.current.value;
    const role = userRef.current.value;

    if (!password.length > 6) {
      console.log(
        " Password must be six digit long or  Password didn't match."
      );
    } else {
      createUserWithEmailAndPassword(email, password);
      console.log("Registation Successful!");
    }

    const payload = { name, email, phone, address, password, role };

    const res = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}api/v1/bms/users`,
      payload
    );

    let data = res.data;

    refetch();
  };

  return (
    <div className="card mb-2 flex items-center">
      <form onSubmit={handleSubmit}>
        <div className="p-2 rounded-md shadow-lg grid lg:grid-cols-2 w-full gap-x-5 justify-center items-center bg-slate-100">
          <div className="form-control">
            <label className="input-group ">
              <span className="my-2 bg-primary text-white text-xl">
                <BsPerson />
              </span>
              <input
                ref={nameRef}
                type="text"
                required
                placeholder="Full Name"
                className="input input-primary input-bordered w-full my-2"
              />
            </label>
          </div>
          <div className="form-control">
            <label className="input-group">
              <span className="my-2 bg-primary text-white text-xl">
                <BsEnvelope />
              </span>
              <input
                ref={emailRef}
                type="email"
                required
                placeholder="Email"
                className="input input-primary input-bordered w-full my-2"
              />
            </label>
          </div>
          <div className="form-control">
            <label className="input-group">
              <span className="my-2 bg-primary text-white text-xl">
                <AiOutlinePhone />
              </span>
              <input
                ref={phoneRef}
                type="number"
                required
                placeholder="Phone"
                className="input input-primary input-bordered w-full my-2"
              />
            </label>
          </div>
          <div className="form-control">
            <label className="input-group">
              <span className="my-2 bg-primary text-white text-xl">
                <TbAddressBook />
              </span>
              <input
                ref={addressRef}
                type="text"
                required
                placeholder="Address"
                className="input input-primary input-bordered w-full my-2"
              />
            </label>
          </div>
          <div className="form-control">
            <label className="input-group">
              <span className="my-2 bg-primary text-white text-xl">
                <BsKey />
              </span>
              <input
                ref={passwordRef}
                type="password"
                required
                placeholder="Password"
                className="input input-primary input-bordered w-full my-2"
              />
            </label>
          </div>
          <div className="form-control">
            <label className="input-group ">
              <span className="my-2 bg-primary text-white text-xl">
                <AiOutlineUsergroupAdd />
              </span>
              <select
                ref={userRef}
                className="select select-primary w-[18.6rem] my-2 "
              >
                <option disabled selected>
                  What type of user?
                </option>
                <option>Admin</option>
                <option>User</option>
              </select>
            </label>
          </div>
        </div>
        <div className="flex justify-center mt-2">
          <button
            type="submit"
            className="flex justify-center text-md w-2/6 btn-primary text-white px-2 py-3 border rounded-md hover:bg-blue-800 hover:text-green-500"
          >
            Add
            <span className="px-1">
              <BiPlus size={24}></BiPlus>
            </span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddUserForm;
