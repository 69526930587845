import React from 'react';
import { MdVerified } from 'react-icons/md';

const DeviceVerified = () => {
    return (
        <div className='flex items-center gap-1 text-sm rounded-full px-1 bg-blue-100 text-primary'>
            <MdVerified />
            <span> Verified</span>
        </div>
    );
};

export default DeviceVerified;