import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Loading from "../../../components/Shared/Loading";
import { BmsContext } from "../../../App";

const RequireAdmin = ({ children }) => {
  const { userInfo, isUserLoading, } = useContext(BmsContext);

  const location = useLocation();

  if (isUserLoading) {
    return <Loading />;
  }

  if (userInfo?.role !== "super-admin") {
    // sign out code

    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAdmin;
