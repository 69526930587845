import React from "react";
import { FiBatteryCharging } from "react-icons/fi";

const DataMonitoring = ({ socketData, lastUpdatedData }) => {
  // from Socket
  const F3 = socketData?.F3;
  const F4 = socketData?.F4;

  //  from API
  const F3API = lastUpdatedData?.bmsData?.F3;
  const F4API = lastUpdatedData?.bmsData?.F4

  if (!socketData) {
    return "";
  }

  return (
    <>
      {/* Battery Cell Voltage start */}
      <div className="border my-3 rounded-lg shadow-lg pb-3 bg-white">
        {/* Heading  */}
        <div className="flex flex-col w-full border-opacity-50">
          <div className="divider px-3">
            <h2 className="text-xl font-bold text-primary px-3 py-2">
              Cell Voltage
            </h2>
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-5 px-3 py-2 gap-3 borderStyle">
          {F3 ? F3?.map((voltage, index) => (
            <div
              key={index}
              voltage={voltage}
              index={index}
              className="grid grid-cols-2 items-center h-10"
            >
              <div className="flex justify-center text-white items-center gap-3 bg-[#0773AF] w-full h-full rounded-l-md shadow-lg border border-primary border-r-0">
                <FiBatteryCharging className="text-lg text-white -rotate-90" />
                <p className="">{voltage ? index + 1 : ""} </p>
              </div>
              <div className="flex justify-center items-center h-full shadow-lg rounded-r-md border border-primary border-l-0">
                <p className="text-slate-500">{voltage ? voltage : ""} V</p>
              </div>
            </div>
          )) : F3API?.map((voltage, index) => (
            <div
              key={index}
              voltage={voltage}
              index={index}
              className="grid grid-cols-2 items-center h-10"
            >
              <div className="flex justify-center text-white items-center gap-3 bg-[#0773AF] w-full h-full rounded-l-md shadow-lg border border-primary border-r-0">
                <FiBatteryCharging className="text-lg text-white -rotate-90" />
                <p className="">{voltage ? index + 1 : ""} </p>
              </div>
              <div className="flex justify-center items-center h-full shadow-lg rounded-r-md border border-primary border-l-0">
                <p className="text-slate-500">{voltage ? voltage : ""} V</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Battery temperature start */}
      <div className="border my-3 rounded-lg shadow-lg pb-3 bg-white">
        {/* Heading  */}
        <div className="flex flex-col w-full border-opacity-50">
          <div className="divider px-3">
            <h2 className="text-xl font-bold text-primary px-3 py-2">
              Cell Temperature
            </h2>
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-5 px-3 py-2 gap-3 text-center borderStyle">
          {F4 ? F4?.map((temperature, index) => (
            <div
              key={index}
              index={index}
              className="grid grid-cols-2 items-center h-10"
            >
              <div className="flex justify-center text-white items-center gap-3 bg-[#0773AF] w-full h-full rounded-l-md shadow-lg border border-primary border-r-0">
                <FiBatteryCharging className="text-lg text-white -rotate-90" />
                <p className="">{index + 1} </p>
              </div>
              <div className="flex justify-center items-center h-full shadow-lg rounded-r-md border border-primary border-l-0">
                <p className="text-slate-500">{temperature} °C</p>
              </div>
            </div>
          )) : F4API?.map((temperature, index) => (
            <div
              key={index}
              index={index}
              className="grid grid-cols-2 items-center h-10"
            >
              <div className="flex justify-center text-white items-center gap-3 bg-[#0773AF] w-full h-full rounded-l-md shadow-lg border border-primary border-r-0">
                <FiBatteryCharging className="text-lg text-white -rotate-90" />
                <p className="">{index + 1} </p>
              </div>
              <div className="flex justify-center items-center h-full shadow-lg rounded-r-md border border-primary border-l-0">
                <p className="text-slate-500">{temperature} °C</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DataMonitoring;
