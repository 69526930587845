import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

import React, { useEffect, useRef, useState } from "react";
import { Calendar } from "react-date-range";
import { format } from "date-fns";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { BsCalendar3 } from "react-icons/bs";
import { BiTime } from "react-icons/bi";
import useUserProfile from "../../../hooks/useUserProfile";
import useDevicesByPhone from "../../../hooks/useDevicesByPhone";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import HistoryPDF from "./HistoryPDF";
import LoadingButton from "../../../components/Shared/LoadingButton";
import { useQuery } from "react-query";
import TimePicker from "react-time-picker";

const GetVehicleHistory = ({
  coordinates,
  calculateTotalDistance,
  setGpsHistory,
  gpsHistory,
}) => {
  const [deviceIMEI, setDeviceIMEI] = useState("");

  const { userInfo } = useUserProfile();
  const [userDeviceList, isLoading, refetch] = useDevicesByPhone(
    userInfo?.phone
  );

  // date picker stat --------------------------
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // time picker stat --------------------------
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");

  const [isStartDate, setIsStartDate] = useState(false);
  const [isEndDate, setIsEndDate] = useState(false);
  const [isStartDateTime, setIsStartDateTime] = useState(false);
  const [isEndDateTime, setIsEndDateTime] = useState(false);

  const [currentDate, setCurrentDate] = useState("");

  const [pdfLoading, setPdfLoading] = useState(false);
  const [trackLoading, setTrackLoading] = useState(false);

  useEffect(() => {
    setStartDate(format(new Date(), "yyyy-MM-dd"));
    setEndDate(format(new Date(), "yyyy-MM-dd"));
  }, []);

  const handleShowTrack = async () => {
    setTrackLoading(true);
    setIsStartDate(false);
    setIsEndDate(false);

    const url = `${process.env.REACT_APP_API_BASE_URL}api/v1/device/locations/${deviceIMEI}?startDate=${startDate}&endDate=${endDate}`;
    const { data } = await axios.get(url);
    setGpsHistory(data?.data);

    setCurrentDate(format(new Date(), "yyy-MM-dd"));
    setTrackLoading(false);
  };

  // print pdf ------------------
  const componentPDF = useRef();
  const generatePdfPrint = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "GPS_History_" + currentDate,
  });

  const handleExportPDF = async () => {
    setPdfLoading(true);
    setIsStartDate(false);
    setIsEndDate(false);

    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}api/v1/device/locations/${deviceIMEI}?startDate=${startDate}&endDate=${endDate}&startTime=${startDateTime}&endTime=${endDateTime}`;
      const { data } = await axios.get(url);

      // Set the GPS history after fetching the data
      setGpsHistory(data?.data);

      setCurrentDate(format(new Date(), "yyy-MM-dd"));

      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Now generate the PDF
      generatePdfPrint();
    } catch (error) {
      // Handle any errors here
      console.error("Error while generating PDF:", error);
    } finally {
      setPdfLoading(false);
    }
  };

  return (
    <div className="w-full relative">
      <div className="w-full">
        <div className="flex gap-8 items-center justify-between mb-4 bg-primary/10 p-2 rounded-md w-full">
          <label htmlFor="">IMEI</label>
          <select
            onChange={(e) => setDeviceIMEI(e.target.value)}
            required
            className="select select-bordered select-sm w-60 font-normal"
          >
            <option disabled selected>
              Select
            </option>
            {userDeviceList?.map((device, index) => (
              <option key={index} className="font-normal">
                {device?.deviceId}
              </option>
            ))}
          </select>
        </div>

        <div className="flex gap-8 items-center justify-between mb-4 bg-primary/10 p-2 rounded-md">

          <div>
            <label htmlFor="">From Date:</label>
            <div className="relative flex justify-end">
              <input
                onClick={() => {
                  setIsStartDate(!isStartDate);
                  setIsEndDate(false);
                }}
                type="text"
                defaultValue={startDate}
                id="start-date"
                placeholder="Pick start date"
                className="input input-bordered input-sm w-28"
              />
              <BsCalendar3 className="absolute top-2 right-1" />
            </div>
          </div>

          <div>
            <label htmlFor="">From Time:</label>
            <div className="">
              <TimePicker
                onChange={(time) => setStartDateTime(time)}
                value={startDateTime}
                editableDateInputs={true}
                clearIcon={null}
                hourPlaceholder={"--"}
                minutePlaceholder={"--"}
                isOpen={false}
                className="bg-white rounded-md flex"
              />
            </div>
          </div>

        </div>

        <div className="flex gap-8 items-center justify-between mb-4 bg-primary/10 p-2 rounded-md">

          <div>
            <label htmlFor="">To Date:</label>
            <div className="relative w-full flex justify-end">
              <input
                onClick={() => {
                  setIsEndDate(!isEndDate);
                  setIsStartDate(false);
                }}
                type="text"
                defaultValue={endDate}
                id="end-date"
                placeholder="Pick end date"
                className="input input-bordered input-sm w-28"
              />
              <BsCalendar3 className="absolute top-2 right-1" />
            </div>
          </div>

          <div>
            <label htmlFor="">To Time:</label>
            <div className="relative flex justify-end">

              <TimePicker
                onChange={(time) => setEndDateTime(time)}
                value={endDateTime}
                editableDateInputs={true}
                clearIcon={null}
                hourPlaceholder={"--"}
                minutePlaceholder={"--"}
                isOpen={false}
                className="bg-white rounded-md flex"
              />

            </div>
          </div>

        </div>

        <div className="flex justify-between gap-4 py-4">
          {pdfLoading ? (
            <LoadingButton name="Loading..." customClass="btn-sm w-auto" />
          ) : (
            <button
              onClick={handleExportPDF}
              disabled={deviceIMEI === ""}
              className="btn btn-sm bg-gradient-to-r from-[#040327] to-[#0D7FBF] text-white disabled:text-white/30"
            >
              Export To PDF
            </button>
          )}

          {trackLoading ? (
            <LoadingButton name="Loading..." customClass="btn-sm w-auto" />
          ) : (
            <button
              onClick={handleShowTrack}
              disabled={deviceIMEI === ""}
              className="btn btn-sm bg-gradient-to-r from-[#040327] to-[#0D7FBF] text-white disabled:text-white/30"
            >
              Show Track
            </button>
          )}
        </div>
      </div>

      {/* date picker --------------------------------- */}
      <div className="absolute top-0 left-[380px] z-50">
        <div className="w-96">
          {isStartDate && (
            <div className="mt-8">
              <Calendar
                onChange={(date) => setStartDate(format(date, "yyyy-MM-dd"))}
                editableDateInputs={true}
                className="rounded-md"
              />
            </div>
          )}
        </div>

        <div>
          {isEndDate && (
            <div className="mt-16">
              <Calendar
                onChange={(date) => setEndDate(format(date, "yyyy-MM-dd"))}
                editableDateInputs={true}
                className="rounded-md"
              />
            </div>
          )}
        </div>
      </div>
      {/* --------------------------------------------- */}

      {/* Time picker --------------------------------- */}
      <div className="absolute top-0 left-[380px] z-50">
        <div className="w-96">
          {isStartDateTime && (
            <div className="mt-8">
              <TimePicker
                onChange={(time) => setStartDateTime(time)}
                editableDateInputs={true}
                className="rounded-md"
              />
            </div>
          )}
        </div>

        <div>
          {isEndDateTime && (
            <div className="mt-16">
              <TimePicker
                onChange={(time) => setStartDateTime(time)}
                editableDateInputs={true}
                className="rounded-md"
              />
            </div>
          )}
        </div>
      </div>
      {/* --------------------------------------------- */}

      <div>
        {coordinates && coordinates.length >= 2 && (
          <div className="border shadow-md py-2 px-4 mt-4 rounded-md bg-gradient-to-r from-[#040327] to-[#0D7FBF] text-white text-center">
            <h3 className="font-bold">
              Total Distance Traveled:
              <span className="bg-white/30 mx-2 rounded-full px-2 pb-0.5"> {calculateTotalDistance()} km</span>
            </h3>
          </div>
        )}
      </div>

      <div className="hidden">
        {gpsHistory?.deviceLocations.length > 0 ? (
          <div ref={componentPDF}>
            <HistoryPDF
              gpsHistory={gpsHistory}
              currentDate={currentDate}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default GetVehicleHistory;
