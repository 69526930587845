import { useEffect, useState } from "react";
import { useQuery } from "react-query";

const useUserProfile = () => {
  const [userInfo, setUserInfo] = useState({});

  const token = localStorage.getItem("accessToken");
  const url = `${process.env.REACT_APP_AUTH_API_BASE_URL}/users/my-profile`;

  const { data: userData, isLoading: isUserLoading } = useQuery(
    ["user", token],
    () =>
      fetch(url, {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
        },
      }).then((res) => res.json())
  );

  useEffect(() => {
    if (userData) {
      // console.log(userData);
      setUserInfo(userData?.data);
    }
  }, [userData]);

  return { userInfo, isUserLoading };
};

export default useUserProfile;
