import React from 'react';
import loader from '../../assets/loader.gif'

const LoadingContent = () => {
    return (
        <div className='flex justify-center items-center w-full'>
            <img src={loader} alt="loading" className='h-5' />
        </div>
    );
};

export default LoadingContent;