import React from "react";
import BatteryStatus from "../../BatteryBMS/BatteryStatus";
import DataMonitoring from "../../BatteryBMS/DataMonitoring";

const DeviceInfo = () => {
  return (
    <div className="m-4">
      <BatteryStatus />
      <DataMonitoring />
    </div>
  );
};

export default DeviceInfo;
