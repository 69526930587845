import { DrawingManager, GoogleMap, Marker } from '@react-google-maps/api';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';

const GeoFenceMap = ({ geofenceMarker, isLoaded, gpsData, geofenceCoords, setGeofenceCoords }) => {

    const [showDrawingManager, setShowDrawingManager] = useState(false);

    // Extract latitude and longitude values from gpsData
    const lat = parseFloat(gpsData?.gpsValues?.latitute);
    const lng = parseFloat(gpsData?.gpsValues?.longitude);

    const position = geofenceMarker[0]?.position;

    const containerStyle = {
        width: "100%",
        height: "500px",
    };

    const defaultPosition = {
        lat: 15.8414659,
        lng: 75.458253
    }

    const drawingManagerRef = useRef(null);

    // on draw circle -------------------------------
    const onDrawnCircle = (circle) => {
        const center = circle.getCenter().toJSON();
        const radius = circle.getRadius();
        setGeofenceCoords({ type: "circle", center, radius });
    };
    // -------------------------------------

    // on draw polygon -------------------------
    const onDrawnPolygon = (polygon) => {
        const paths = polygon
            .getPath()
            .getArray()
            .map((latLng) => latLng.toJSON());
        setGeofenceCoords({ type: "polygon", paths });
    };
    // ----------------------------------------


    // on draw rectangle -------------------
    const onDrawnRectangle = (rectangle) => {
        const bounds = rectangle.getBounds();
        const ne = bounds.getNorthEast().toJSON();
        const sw = bounds.getSouthWest().toJSON();
        setGeofenceCoords({ type: "rectangle", bounds: { ne, sw } });
    };
    // ---------------------------------------



    // geofenc coords and drawing manager effect----------------------
    useEffect(() => {
        // const checkLocationInsideGeofence = (location) => {
        //     if (!geofenceCoords) return; // If geofenceCoords is not set yet, do nothing

        //     if (geofenceCoords.type === "circle") {
        //         const { center, radius } = geofenceCoords;
        //         const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
        //             new window.google.maps.LatLng(location),
        //             new window.google.maps.LatLng(center)
        //         );
        //         const isInside = distance <= radius;
        //         console.log("Is inside geofence:", isInside);
        //     } else if (geofenceCoords.type === "polygon") {
        //         const polygon = new window.google.maps.Polygon({
        //             paths: geofenceCoords.paths.map((coords) => ({
        //                 lat: coords.lat,
        //                 lng: coords.lng,
        //             })),
        //         });
        //         const isInside = window.google.maps.geometry.poly.containsLocation(
        //             new window.google.maps.LatLng(location),
        //             polygon
        //         );
        //         console.log("Is inside geofence:", isInside);
        //     } else if (geofenceCoords.type === "rectangle") {
        //         const { bounds } = geofenceCoords;
        //         const sw = new window.google.maps.LatLng(bounds.sw.lat, bounds.sw.lng);
        //         const ne = new window.google.maps.LatLng(bounds.ne.lat, bounds.ne.lng);
        //         const rectangleBounds = new window.google.maps.LatLngBounds(sw, ne);
        //         const isInside = rectangleBounds.contains(new window.google.maps.LatLng(location));
        //         console.log("Is inside geofence:", isInside);
        //     }
        // };

        if (isLoaded && geofenceCoords) {
            setShowDrawingManager(true);

            // For initial check, replace the following location with your desired location
            // const locationToCheck = { lat: lat, lng: lng }; 
            // const locationToCheck = { lat: 23.8177277, lng: 90.4149659 };
            // checkLocationInsideGeofence(locationToCheck);
        }
    }, [isLoaded, geofenceCoords]);
    // ------------------------------------------------------------

    return (
        <>
            {
                isLoaded &&
                <div>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={position ? position : defaultPosition}
                        zoom={8}
                    >
                        {/* Child components, such as markers, info windows, etc. */}

                        {geofenceMarker.length > 0 &&
                            <Marker position={geofenceMarker[0]?.position} />
                        }

                        {showDrawingManager &&
                            <DrawingManager
                                onPolygonComplete={onDrawnPolygon}
                                onCircleComplete={onDrawnCircle}
                                onRectangleComplete={onDrawnRectangle}
                                options={
                                    {
                                        drawingControl: true,
                                        drawingControlOptions: {
                                            position: window.google.maps.ControlPosition.TOP_CENTER,
                                            drawingModes: ['circle', 'polygon', 'rectangle'],
                                        },
                                    }
                                }
                                onLoad={(drawingManager) => (drawingManagerRef.current = drawingManager)}
                            />}

                    </GoogleMap>
                </div>
            }
        </>
    );
};

export default GeoFenceMap;