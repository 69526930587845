import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { BiUserPlus } from "react-icons/bi";
import Loading from "../../../components/Shared/Loading";
import { useQuery } from "react-query";
import useDevicesByPhone from "../../../hooks/useDevicesByPhone";
import DevicePending from "../../../components/Shared/DeviceStatus/DevicePending";
import DeviceVerified from "../../../components/Shared/DeviceStatus/DeviceVerified";
import AddUserForm from "./AddUserForm";
import { AiTwotoneDelete } from "react-icons/ai";
import axios from "axios";
import { toast } from "react-toastify";

const UserDetailForm = () => {
  const [visible, setVisible] = useState(false);
  const id = useParams();

  const url = `${process.env.REACT_APP_API_BASE_URL}api/v1/bms/users/${id?.email}`;

  const { data: user, isLoading: isAPILoading } = useQuery(
    ["users", id?.email],
    () =>
      fetch(url, {
        method: "GET",
      }).then((res) => res.json())
  );

  const [userDeviceList, isLoading, refetch] = useDevicesByPhone(
    user?.data?.email
  );

  if (isLoading || isAPILoading) {
    return <Loading />;
  }

  const { email, role, name, permissions } = user?.data;

  const handler = () => {
    setVisible(!visible);
  };

  const handleDevicePermision = (e) => {
    const isTrue = e.target.checked;
    // Device permission object
    const permissions = {
      isDeviceAdd: isTrue,
    };

    // API Request
    (async () => {
      const res = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/bms/users/${id?.email}`,
        { permissions }
      );
      if (res.data.success) {
        toast.success("Permission Updated!", { position: "top-left" });
        refetch();
      }
    })();
  };

  return (
    <div className="p-3 gap-2 grid md:grid-cols-4">
      <div className="card w-full">
        <div className="shadow-md bg-base-100 rounded-md">
          <div className="card-body">
            <div className="avatar flex justify-center">
              <div className="w-20 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2 ">
                <img src="https://placeimg.com/192/192/people" alt="image" />
              </div>
            </div>
            <p className="text-2xl text-center mb-0 font-medium">{name}</p>
            <p className="text-center font-medium">{role}</p>
            <div>
              <p className="border-t p-1 flex justify-between">
                <span className="font-medium">Email</span>{" "}
                <span className="font-medium">{email}</span>
              </p>
              <p className="border-t border-b p-1 flex justify-between">
                <span className="font-medium text-wh">Is Active?</span>{" "}
                <span className="font-medium bg-blue-400 rounded-md px-1 text-white">
                  Active
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-between p-4">
          <p className="font-bold text-sm text-teal-800 mb-0.5">
            Add Device Permission:
          </p>
          <input
            onChange={(e) => handleDevicePermision(e)}
            name="addDevice"
            defaultChecked={permissions?.isDeviceAdd}
            type="checkbox"
            className="toggle toggle-primary toggle-sm md:toggle-md"
          />
        </div>
      </div>
      <div className="card w-full bg-base-100 shadow-md sm:col-span-3">
        <div className="m-2.5">
          <button
            onClick={handler}
            className="flex btn-primary btn-sm text-white px-4 py-2 border rounded-md hover:bg-grary-50 hover:border-indigo-500 hover:text-gray-200"
          >
            <span className="px-1">
              <BiUserPlus size={15}></BiUserPlus>
            </span>
          </button>
        </div>

        {/* collapsable form */}
        {visible ? <AddUserForm refetch={refetch}></AddUserForm> : <></>}

        <div className="px-2.5">
          <div className="overflow-x-auto">
            <table className="table w-full">
              <thead className="text-white">
                <tr>
                  <th className="bg-primary py-2.5">SL</th>
                  <th className="bg-primary py-2.5">Device Name</th>
                  <th className="bg-primary py-2.5">Device Id</th>
                  <th className="bg-primary py-2.5">Status</th>
                  <th className="bg-primary py-2.5">Actions</th>
                </tr>
              </thead>

              <tbody>
                {userDeviceList?.map((device, index) => (
                  <tr>
                    <th>{index + 1}</th>
                    <td>{device?.deviceName}</td>
                    <td>{device?.deviceId}</td>
                    <td>
                      {device?.deviceStatus === "Pending" ? (
                        <DevicePending />
                      ) : (
                        <DeviceVerified />
                      )}
                    </td>
                    <td className="">
                      <label
                        htmlFor="device-delete-modal"
                        className="modal-button cursor-pointer"
                      >
                        <p className="btn btn-sm text-red-500 bg-red-100 border rounded-full text-xl hover:border-red-900">
                          <AiTwotoneDelete />
                        </p>
                      </label>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetailForm;
