import React, { useState } from "react";

const ControllModal = (props) => {
  const { showControllModal, setShowControllModal, handleControllSubmit } =
    props;
  const [pass, setPass] = useState("");

  if (!showControllModal) return null;

  return (
    <>
      {/* controll modal start  */}
      <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm z-50 flex justify-center items-center">
        <div className="modal-box bg-white">
          <button
            onClick={() => setShowControllModal(false)}
            htmlFor="battery-controll-modal"
            className="btn btn-accent btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </button>
          <h3 className="text-lg font-bold">
            Please Enter Your Controll Password
          </h3>
          <div className="mt-5">
            <form onSubmit={handleControllSubmit} className="w-full">
              <input
                onChange={(e) => setPass(e.target.value)}
                type="password"
                name="password"
                placeholder="Enter Controll Password"
                className="input input-bordered input-primary w-full"
              />
              <div className="flex justify-center mt-5">
                <button
                  type="submit"
                  disabled={pass === 123 ? "" : pass}
                  className="btn btn-primary btn-wide"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* controll modal end */}
    </>
  );
};

export default ControllModal;
