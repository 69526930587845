import DeviceRow from "./DeviceRow";

const DevicesTable = ({
  allDevices,
  refetch,
  setIsDeviceEdit,
  setEditDevice,
}) => {
  return (
    <div className="overflow-x-auto w-full shadow-md">
      <table className="table w-full">
        {/* <!-- head --> */}
        <thead className="text-white">
          <tr>
            <th className="bg-primary py-2.5">SL</th>
            <th className="bg-primary py-2.5">Device Name</th>
            <th className="bg-primary py-2.5">Device Id</th>
            <th className="bg-primary py-2.5">Device Owner</th>
            <th className="bg-primary py-2.5">Status</th>
            <th className="bg-primary py-2.5">Actions</th>
          </tr>
        </thead>
        {allDevices?.length > 0 &&
          <tbody tbody className="w-full">
            {allDevices?.map((device, index) => (
              <DeviceRow
                device={device}
                index={index}
                setIsDeviceEdit={setIsDeviceEdit}
                setEditDevice={setEditDevice}
                refetch={refetch}
                key={index}
              />
            ))}
          </tbody>}

        <tfoot className="text-white">
          <tr>
            <th className="bg-primary py-2.5">SL</th>
            <th className="bg-primary py-2.5">Name</th>
            <th className="bg-primary py-2.5">Email</th>
            <th className="bg-primary py-2.5">Phone</th>
            <th className="bg-primary py-2.5">Address</th>
            <th className="bg-primary py-2.5">Actions</th>
          </tr>
        </tfoot>
      </table>
    </div >
  );
};

export default DevicesTable;
